import { default as ChartLegend } from "../chart/legend/legend";
import { SankeyElement } from "./element";
import { setDefaultOptions } from '../common';
import { nodeColor } from "./node";
import { BOTTOM, CENTER, POINTER } from "../common/constants";
import { AREA } from "../chart/constants";
export class Legend extends SankeyElement {
  getElement() {
    const options = this.options;
    const {
      drawingRect,
      nodes = [],
      colors = []
    } = options;
    if (options.visible === false || !nodes.length) {
      return null;
    }
    const data = nodes.map((node, index) => ({
      text: node.label && node.label.text || '',
      area: {
        background: nodeColor(node, colors, index),
        opacity: node.opacity
      },
      node: node
    }));
    const legend = new ChartLegend(Object.assign({}, options, {
      data
    }));
    legend.reflow(drawingRect);
    legend.renderVisual();
    return legend.visual;
  }
  createElement() {
    return this.getElement();
  }
}
setDefaultOptions(Legend, {
  markers: {
    visible: false
  },
  item: {
    type: AREA,
    cursor: POINTER,
    opacity: 1
  },
  position: BOTTOM,
  align: CENTER,
  border: {
    width: 0
  }
});