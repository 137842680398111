import { Injectable } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root'
})
export class GridFilterServiceService {
  private filterState!: CompositeFilterDescriptor;

  constructor() { }

  setFilter(filter: CompositeFilterDescriptor) {
    console.log(filter);
    this.filterState = filter;
    console.log(this.filterState);
  }

  getFilter(): CompositeFilterDescriptor {
    return this.filterState;
  }
}
