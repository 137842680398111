import { Component, ViewChild } from '@angular/core';
import { FilterableSettings, DataBindingDirective } from "@progress/kendo-angular-grid";
import { SVGIcon, filePdfIcon, fileExcelIcon } from "@progress/kendo-svg-icons";
import { process } from "@progress/kendo-data-query";
import { onOffData } from './onoff-data';
import { MeterData } from './meter-data';

@Component({
  selector: 'app-rp-meter-control',
  templateUrl: './rp-meter-control.component.html',
  styleUrl: './rp-meter-control.component.scss'
})
export class RpMeterControlComponent {

  public filterMode: FilterableSettings = "row";
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;

  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;

  selectValue!: string[];

  public gridData: unknown[] = MeterData;
  public gridView!: unknown[];

  public gridDataWallet : unknown[] = onOffData;
  public gridWalletView!: unknown[];


  constructor( ) {
  
  }
  
  config:any = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  public onFilter(value: Event): void {
    const inputValue = value;

    this.gridView = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [

          {
            field: "ProjectName",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "Utility",
            operator: "contains",
            value: inputValue,
          }
        ],
      },
    }).data;

    this.dataBinding.skip = 0;

  }

  public onFilterWallet(value: Event): void {
    const inputValue = value;

    this.gridWalletView = process(this.gridDataWallet, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "ProjectCode",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "Utility",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "ProjectName",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "Consumption",
            operator: "contains",
            value: inputValue,
          }
        ],
      },
    }).data;

    this.dataBinding.skip = 0;

  }



  ngOnInit(): void {
    this.gridView = this.gridData;
    this.gridWalletView = this. gridDataWallet;
 
  
  }
}



