import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UtilitiesRoutingModule } from './utilities-routing.module';
import { RpUtilitiesComponent } from './rp-utilities/rp-utilities.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { RpCreateUMDComponent } from './rp-create-umd/rp-create-umd.component';
import { ComponentsModule } from '../../shared/components/components.module';
import { SharedModule } from '../../shared/shared.module';
import { RpUnitRateHistoryComponent } from './rp-unit_rate_history/rp-unit_rate_history.component';
@NgModule({
  declarations: [
    RpUtilitiesComponent,
    RpCreateUMDComponent,
    RpUnitRateHistoryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    UtilitiesRoutingModule,
    BsDatepickerModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    ModalModule,
    NgStepperModule,
    CdkStepperModule,
    AccordionModule,
    UploadsModule,
    ComponentsModule,
    SharedModule,
  ],
})
export class UtilitiesModule {}
