import { geometry, drawing } from '@progress/kendo-drawing';
import { SankeyElement } from './element';
import { deepExtend } from '../common';
export class Node extends SankeyElement {
  getElement() {
    const options = this.options;
    const node = options.node;
    const rect = new geometry.Rect([node.x0, node.y0], [node.x1 - node.x0, node.y1 - node.y0]);
    return drawing.Path.fromRect(rect, this.visualOptions());
  }
  visualOptions() {
    const options = deepExtend({}, this.options, this.options.node);
    return {
      fill: {
        color: options.color,
        opacity: options.opacity
      },
      stroke: {
        width: 0
      }
    };
  }
}
export const nodeColor = (node, nodesColors, index) => node.color || nodesColors[index % nodesColors.length];
export const resolveNodeOptions = (node, options, nodesColors, index) => {
  const nodeOptions = deepExtend({}, options, options.node);
  return deepExtend({}, {
    color: nodeColor(node, nodesColors, index)
  }, nodeOptions, {
    node
  }, {
    visual: node.visual,
    opacity: node.opacity,
    offset: node.offset,
    color: node.color
  });
};