<div class="container-fluid">

  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-0 font-size-18">Dashboard</h4>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card mini-stats-wid py-2">
        <div class="">
          <div class="d-flex flex-wrap px-3 align-items-center">
            <div class="mr-3 d-flex ">
              <h4 class="mb-0 mb-2">My Home Bhooja</h4>
            </div>
            <div class="ms-auto bg-primary text-white px-3 py-2 text-center border-radius-sm">
              <h5>Current wallet balance</h5>
              <p class="mb-0"><span>₹</span> 1500.25</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end mb-2">
    <div class="col-xl-3 me-2 input-container" *ngIf="dateRange">
      <input type="text" class="form-control date dateRange" placeholder="Select Date" name="daterange"
        autocomplete="off" bsDaterangepicker />
    </div>
    <div class="col-xl-3">
      <kendo-dropdownlist class="bg-primary text-white" [data]="months"
        (selectionChange)="selectionChange($event)"></kendo-dropdownlist>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6">
      <div class="card">
        <div class="card-body row">
          <div class="col-md-6">
            <h4 class="card-title mb-1 font-size-18"><span>₹</span> 1,50,525.25</h4>
            <p class="">Total wallet recharge</p>

            <kendo-chart style="height: 200px;">
              <kendo-chart-series>
                <kendo-chart-axis-defaults [majorGridLines]="{ visible: false }" [visible]="false">
                </kendo-chart-axis-defaults>
                <kendo-chart-series-item *ngFor="let series of TotalWalletData" type="area" [color]="series.color"
                  [data]="series.data" [markers]="{ visible: false }">
                  <kendo-chart-series-item-tooltip format="Date: {0}">
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>
            </kendo-chart>
          </div>

          <div class="col-md-6">
            <h4 class="card-title mb-1 font-size-18"><span>₹</span> 1,50,525.25</h4>
            <p class="">Deducted wallet balance</p>

            <kendo-chart style="height: 200px;">
              <kendo-chart-series>
                <kendo-chart-axis-defaults [majorGridLines]="{ visible: false }" [visible]="false">

                </kendo-chart-axis-defaults>
                <kendo-chart-series-item *ngFor="let series of deductedWalletData" type="area" [data]="series.data"
                  [color]="series.color" [markers]="{ visible: false }" [highlight]="{ inactiveOpacity: 0.3 }">
                  <kendo-chart-series-item-tooltip format="Series 1 value: {0}">
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>
            </kendo-chart>

          </div>

        </div>
      </div>
    </div>

    <div class="col-xl-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-1 font-size-18">Bill Summery</h4>
          <div class="table-responsive">
            <table class="table mt-2 mb-5">
              <thead>
                <tr>
                  <th scope="col">
                    <div class="">
                      Type
                    </div>
                  </th>
                  <th scope="col">
                    <div class="">
                      Bills
                    </div>

                  <th scope="col">
                    <div class="text-center">
                      Bill Amount
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let project of projects; let i = index">
                  <td>{{ project.name }}</td>
                  <td>{{ project.bill }}</td>
                  <td class="font-weight-bolder text-center"><span>₹</span> {{ getFormattedNumber(project.revenue) }}
                  </td>

                </tr>
              </tbody>
              <tfoot>
                <tr class="font-size-16">
                  <th>Total</th>
                  <th>{{ totalBill }}</th>
                  <th class="text-center"><span>₹</span> {{ getFormattedNumber(totalPrice) }}</th>
                </tr>
              </tfoot>
            </table>
          </div>

        </div>
      </div>
    </div>



  </div>

  <div class="row">
    <div class="col-xl-6">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="card-title mb-0"> Consumption</h4>
            <kendo-dropdownlist class="w-25 bg-white" [data]="meter"
              (selectionChange)="selectionChange($event)"></kendo-dropdownlist>

          </div>
          <kendo-chart [categoryAxis]="{ categories: categories }" style="height: 250px;">
            <kendo-chart-series>
              <kendo-chart-series-item *ngFor="let series of consumptionData" [data]="series.data"
                [color]="series.color" type="area" [line]="{ style: line }" [visible]="seriesVisible" [spacing]="1">

              </kendo-chart-series-item>
            </kendo-chart-series>

          </kendo-chart>

          <div class="d-flex justify-content-end text-danger">
            <span>* Water units in liters </span>
          </div>

        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="card-title mb-0"> Top 5 consumption</h4>
            <kendo-dropdownlist class="w-25 bg-white" [data]="meter"
              (selectionChange)="selectionChange($event)"></kendo-dropdownlist>

          </div>

          <kendo-chart style="height: 250px;">
            <kendo-chart-category-axis>
              <kendo-chart-category-axis-item
                [categories]="['Flat #2406', 'Flat #2407', 'Flat #2408', 'Flat #2409', 'Flat #2410']">
              </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
            <kendo-chart-series>
              <kendo-chart-series-item *ngFor="let series of topConsumption" type="bar" [gap]="1" [color]="series.color"
                [data]="series.data">
              </kendo-chart-series-item>
            </kendo-chart-series>
          </kendo-chart>
          <div class="d-flex justify-content-end text-danger">
            <span>* Water units in liters </span>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <h4 class="card-title mb-3">Meter Communication Status</h4>
            <div class="col-xl-8">
              <kendo-chart style="height: 250px;">
                <kendo-chart-series>
                  <kendo-chart-series-item *ngFor="let series of revenueData" [data]="series.data"
                    [color]="series.color" [visible]="seriesVisible" [spacing]="1">

                  </kendo-chart-series-item>
                </kendo-chart-series>

              </kendo-chart>

              <div class="mt-4">
                <div *ngFor="let series of revenueData">
                  <span class=" "><i class="bx bxs-circle" [ngStyle]="{'color': series.color}"></i><span
                      class="font-size-12 font-weight-bolder"> {{series.name}} </span></span>
                </div>
              </div>
            </div>

            <div class="col-xl-4 g-0">

              <kendo-chart class="mt-5" style="height: 100px;">
                <kendo-chart-series>
                  <kendo-chart-series-item type="donut" [data]="consumerData" [size]="35">
                    <kendo-chart-series-item-labels [content]="labelContent" color="#fff" [visible]="true"
                      position="center" background="transparent">
                    </kendo-chart-series-item-labels>
                  </kendo-chart-series-item>
                </kendo-chart-series>
              </kendo-chart>
              <h6 class="text-center mt-2">Meter Status</h6>
              <div class=" mt-4">

                <div *ngFor="let series of meterData">
                  <span class=""><i class="bx bxs-circle" [ngStyle]="{'color': series.color}"></i><span
                      class="font-size-10 font-weight-bolder ms-1"> {{series.name}}</span></span>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <h4 class="card-title mb-0">Residential Units</h4>
            <div>
              <p>Total <br> <span class="font-weight-bolder"> 400 </span></p>
            </div>
          </div>
          <kendo-chart style="height: 250px;">
            <kendo-chart-series>
              <kendo-chart-series-item type="pie" [data]="residentialUnitsData">
                <kendo-chart-series-item-labels [content]="labelContent" color="#fff" [visible]="true" position="center"
                  background="transparent">
                </kendo-chart-series-item-labels>
              </kendo-chart-series-item>

            </kendo-chart-series>
          </kendo-chart>
          <div class="">
            <div *ngFor="let series of pieData">
              <span class=""><i class="bx bxs-circle" [ngStyle]="{'color': series.color}"></i><span
                  class="font-size-12 font-weight-bolder ms-1"> {{series.name}} - {{series.value}} </span></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <h4 class="card-title mb-0">Consumers</h4>
            <div>
              <p>Total <br> <span class="font-weight-bolder"> 200 </span></p>
            </div>
          </div>
          <kendo-chart style="height: 250px;">
            <kendo-chart-series>
              <kendo-chart-series-item type="pie" [data]="consumerData">
                <kendo-chart-series-item-labels [content]="labelContent" color="#fff" [visible]="true" position="center"
                  background="transparent">
                </kendo-chart-series-item-labels>
              </kendo-chart-series-item>

            </kendo-chart-series>
          </kendo-chart>
          <div class="">
            <div *ngFor="let series of consumerData">
              <span class=""><i class="bx bxs-circle" [ngStyle]="{'color': series.color}"></i><span
                  class="font-size-12 font-weight-bolder ms-1"> {{series.name}} - {{series.value}} </span></span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>