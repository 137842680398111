import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { ConsumptionHistoryRoutingModule } from './consumption-history-routing.module';
import { ConsumptionHistoryComponent } from './consumption-history/consumption-history.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { RpConsumptionViewComponent } from './rp-consumption-view/rp-consumption-view.component';
import { ComponentsModule } from '../../shared/components/components.module';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';

@NgModule({
  declarations: [ConsumptionHistoryComponent, RpConsumptionViewComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    ConsumptionHistoryRoutingModule,
    ComponentsModule,
    CdkStepperModule,
    BsDatepickerModule,
    NgStepperModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    ModalModule,
    AccordionModule,
    SharedModule,
  ],
})
export class ConsumptionHistoryModule {}
