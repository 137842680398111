import { isFunction, isArray, hasOwnProperty } from '../common';

/* eslint-disable arrow-body-style, no-useless-escape */

const defineProperty = Object.defineProperty;
export const extend = Object.assign;
export let convertToHtml = html => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.firstChild;
};
export let appendHtml = (html, element) => {
  const div = document.createElement('div');
  div.innerHTML = html;

  // use childNodes instead of children
  // to cover text nodes as well
  while (div.childNodes.length > 0) {
    element.appendChild(div.childNodes[0]);
  }
};
export let removeChildren = element => {
  while (element.firstChild) {
    element.removeChild(element.firstChild);
  }
};
export let prepend = (element, originElement) => {
  originElement.insertBefore(element, originElement.firstChild);
};
export let wrapInner = (parent, wrapper) => {
  parent.appendChild(wrapper);
  while (parent.firstChild !== wrapper) {
    wrapper.appendChild(parent.firstChild);
  }
};
export let toHyphens = str => {
  const result = str.replace(/([a-z][A-Z])/g, function (g) {
    return g.charAt(0) + '-' + g.charAt(1).toLowerCase();
  });
  return result;
};
export let toPixels = value => {
  let result;
  if (value && String(value).endsWith("px")) {
    result = value;
  } else {
    result = String(value) + "px";
  }
  return result;
};
const detectOS = ua => {
  let os = false,
    minorVersion,
    match = [],
    // notAndroidPhone = !/mobile safari/i.test(ua),
    agentRxs = {
      wp: /(Windows Phone(?: OS)?)\s(\d+)\.(\d+(\.\d+)?)/,
      fire: /(Silk)\/(\d+)\.(\d+(\.\d+)?)/,
      android: /(Android|Android.*(?:Opera|Firefox).*?\/)\s*(\d+)\.?(\d+(\.\d+)?)?/,
      iphone: /(iPhone|iPod).*OS\s+(\d+)[\._]([\d\._]+)/,
      ipad: /(iPad).*OS\s+(\d+)[\._]([\d_]+)/,
      meego: /(MeeGo).+NokiaBrowser\/(\d+)\.([\d\._]+)/,
      webos: /(webOS)\/(\d+)\.(\d+(\.\d+)?)/,
      blackberry: /(BlackBerry|BB10).*?Version\/(\d+)\.(\d+(\.\d+)?)/,
      playbook: /(PlayBook).*?Tablet\s*OS\s*(\d+)\.(\d+(\.\d+)?)/,
      windows: /(MSIE)\s+(\d+)\.(\d+(\.\d+)?)/,
      tizen: /(tizen).*?Version\/(\d+)\.(\d+(\.\d+)?)/i,
      sailfish: /(sailfish).*rv:(\d+)\.(\d+(\.\d+)?).*firefox/i,
      ffos: /(Mobile).*rv:(\d+)\.(\d+(\.\d+)?).*Firefox/
    },
    osRxs = {
      ios: /^i(phone|pad|pod)$/i,
      android: /^android|fire$/i,
      blackberry: /^blackberry|playbook/i,
      windows: /windows/,
      wp: /wp/,
      flat: /sailfish|ffos|tizen/i,
      meego: /meego/
    },
    formFactorRxs = {
      tablet: /playbook|ipad|fire/i
    },
    browserRxs = {
      omini: /Opera\sMini/i,
      omobile: /Opera\sMobi/i,
      firefox: /Firefox|Fennec/i,
      mobilesafari: /version\/.*safari/i,
      ie: /MSIE|Windows\sPhone/i,
      chrome: /chrome|crios/i,
      webkit: /webkit/i
    };
  for (let agent in agentRxs) {
    if (hasOwnProperty(agentRxs, agent)) {
      match = ua.match(agentRxs[agent]);
      if (match) {
        if (agent === "windows" && "plugins" in navigator) {
          return false;
        } // Break if not Metro/Mobile Windows

        os = {};
        os.device = agent;
        os.tablet = testRegex(agent, formFactorRxs, false);
        os.browser = testRegex(ua, browserRxs, "default");
        os.name = testRegex(agent, osRxs);
        os[os.name] = true;
        os.majorVersion = match[2];
        os.minorVersion = (match[3] || "0").replace("_", ".");
        minorVersion = os.minorVersion.replace(".", "").substr(0, 2);
        os.flatVersion = os.majorVersion + minorVersion + new Array(3 - (minorVersion.length < 3 ? minorVersion.length : 2)).join("0");
        break;
      }
    }
  }
  return os;
};
function testRegex(agent, regexes, dflt) {
  for (let regex in regexes) {
    if (hasOwnProperty(regexes, regex) && regexes[regex].test(agent)) {
      return regex;
    }
  }
  return dflt !== undefined ? dflt : agent;
}
export let hasNativeScrolling = userAgent => {
  const os = detectOS(userAgent);
  return os.ios || os.android;
};
const detectBrowser = userAgent => {
  let browser = false,
    match = [],
    browserRxs = {
      edge: /(edge)[ \/]([\w.]+)/i,
      webkit: /(chrome|crios)[ \/]([\w.]+)/i,
      safari: /(webkit)[ \/]([\w.]+)/i,
      opera: /(opera)(?:.*version|)[ \/]([\w.]+)/i,
      msie: /(msie\s|trident.*? rv:)([\w.]+)/i,
      mozilla: /(mozilla)(?:.*? rv:([\w.]+)|)/i
    };
  for (let agent in browserRxs) {
    if (hasOwnProperty(browserRxs, agent)) {
      match = userAgent.match(browserRxs[agent]);
      if (match) {
        browser = {};
        browser[agent] = true;
        browser[match[1].toLowerCase().split(" ")[0].split("/")[0]] = true;
        browser.version = parseInt(document.documentMode || match[2], 10);
        break;
      }
    }
  }
  return browser;
};
export let getEventMap = () => {
  let eventMap = {
    down: "touchstart mousedown",
    move: "mousemove touchmove",
    up: "mouseup touchend touchcancel",
    cancel: "mouseleave touchcancel"
  };
  const support = getSupportedFeatures();
  if (support.touch && (support.mobileOS.ios || support.mobileOS.android)) {
    eventMap = {
      down: "touchstart",
      move: "touchmove",
      up: "touchend touchcancel",
      cancel: "touchcancel"
    };
  } else if (support.pointers) {
    eventMap = {
      down: "pointerdown",
      move: "pointermove",
      up: "pointerup",
      cancel: "pointercancel pointerleave"
    };
  } else if (support.msPointers) {
    eventMap = {
      down: "MSPointerDown",
      move: "MSPointerMove",
      up: "MSPointerUp",
      cancel: "MSPointerCancel MSPointerLeave"
    };
  }
  return eventMap;
};
export const getSupportedFeatures = () => {
  const os = detectOS(navigator.userAgent);
  const browser = detectBrowser(navigator.userAgent);
  let chrome = browser.chrome,
    mobileChrome = browser.crios,
    mozilla = browser.mozilla,
    safari = browser.safari;
  const support = {};
  support.mobileOS = os;
  support.touch = "ontouchstart" in window;
  support.pointers = !chrome && !mobileChrome && !mozilla && !safari && window.PointerEvent;
  support.msPointers = !chrome && window.MSPointerEvent;
  support.mouseAndTouchPresent = support.touch && !(support.mobileOS.ios || support.mobileOS.android);
  support.eventCapture = document.documentElement.addEventListener;
  let table = document.createElement("table");
  let transitions = support.transitions = false,
    transforms = support.transforms = false;
  const STRING = "string";
  ["Moz", "webkit", "O", "ms"].forEach(function (prefix) {
    let hasTransitions = typeof table.style[prefix + "Transition"] === STRING;
    if (hasTransitions || typeof table.style[prefix + "Transform"] === STRING) {
      let lowPrefix = prefix.toLowerCase();
      transforms = {
        css: lowPrefix !== "ms" ? "-" + lowPrefix + "-" : "",
        prefix: prefix,
        event: lowPrefix === "o" || lowPrefix === "webkit" ? lowPrefix : ""
      };
      if (hasTransitions) {
        transitions = transforms;
        transitions.event = transitions.event ? transitions.event + "TransitionEnd" : "transitionend";
      }
      return false;
    }
  });
  table = null;
  support.transforms = transforms;
  support.transitions = transitions;
  support.delayedClick = function () {
    // only the mobile devices with touch events do this.
    if (support.touch) {
      // All iOS devices so far (by the time I am writing this, iOS 9.0.2 is the latest),
      // delay their click events.
      if (support.mobileOS.ios) {
        return true;
      }
      if (support.mobileOS.android) {
        if (!support.browser.chrome) {
          // older webkits and webviews delay the click
          return true;
        }

        // from here on, we deal with Chrome on Android.
        if (support.browser.version < 32) {
          return false;
        }

        // Chrome 32+ does conditional fast clicks if the view port is not user scalable.
        const meta = document.querySelector("meta[name=viewport]");
        const contentAttr = meta ? meta.getAttribute("content") : "";
        return !contentAttr.match(/user-scalable=no/i);
      }
    }
    return false;
  };
  return support;
};
export const ownsElement = (parent, element) => {
  if (!element) {
    return false;
  }
  let node = element.parentNode;
  while (node !== null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};
export const contains = (parent, element) => {
  return parent === element || ownsElement(parent, element);
};
export const proxy = (method, context) => {
  return method.bind(context);
};
function isString(value) {
  return typeof value === "string";
}
export const on = (element, events, filter, handler, useCapture) => {
  addEventListeners(element, events, filter, handler, useCapture);
};
export const addEventListeners = (element, events, filter, handler, useCapture) => {
  const eventNames = isArray(events) ? events : (events || "").split(" ");
  eventNames.forEach(function (eventName) {
    addEventListener(element, eventName, filter, handler, useCapture);
  });
};
export const addEventListener = (element, event, filter, handler, useCapture) => {
  let eventHandler = handler;
  let eventFilter;
  if (filter && isFunction(filter) && !handler) {
    eventHandler = filter;
  } else if (filter && isString(filter) && isFunction(eventHandler)) {
    eventFilter = filter;
  }
  element.addEventListener(event, function (e) {
    const closestMatchingTarget = e.target ? e.target.closest(eventFilter) : null;
    if (!eventFilter || eventFilter && e.target && closestMatchingTarget) {
      const currentTarget = eventFilter ? closestMatchingTarget : e.currentTarget;

      // reassign the property as it is a getters only
      defineProperty(e, "currentTarget", {
        value: currentTarget
      });
      // keep a reference to the top-level target
      defineProperty(e, "delegateTarget", {
        value: element
      });
      eventHandler(e);
    }
  }, Boolean(useCapture));
};
export const off = (element, events, filter, handler, useCapture) => {
  removeEventListeners(element, events, filter, handler, useCapture);
};
export const removeEventListeners = (element, events, handler, useCapture) => {
  const eventNames = isArray(events) ? events : (events || "").split(" ");
  eventNames.forEach(function (eventName) {
    removeEventListener(element, eventName, handler, useCapture);
  });
};
export const removeEventListener = (element, event, handler, useCapture) => {
  element.removeEventListener(event, handler, Boolean(useCapture));
};
export const applyEventMap = events => {
  const eventMap = getEventMap(navigator.userAgent);
  function queryEventMap(e) {
    return eventMap[e] || e;
  }
  const eventRegEx = /([^ ]+)/g;
  const appliedEvents = events.replace(eventRegEx, queryEventMap);
  return appliedEvents;
};
export const setDefaultEvents = (type, events) => {
  const proto = type.prototype;
  if (proto.events) {
    events.forEach(event => {
      if (proto.events.indexOf(event) < 0) {
        proto.events.push(event);
      }
    });
  } else {
    proto.events = events;
  }
};
export const wheelDeltaY = jQueryEvent => {
  const e = jQueryEvent.originalEvent || jQueryEvent;
  const deltaY = e.wheelDeltaY;
  let delta;
  if (e.wheelDelta) {
    // Webkit and IE
    if (deltaY === undefined || deltaY) {
      // IE does not have deltaY, thus always scroll (horizontal scrolling is treated as vertical)
      delta = e.wheelDelta;
    }
  } else if (e.detail && e.axis === e.VERTICAL_AXIS) {
    // Firefox and Opera
    delta = -e.detail * 10;
  }
  return delta;
};
export const now = () => {
  return Number(new Date());
};
export const noop = () => {};
export const renderPos = pos => {
  let result = [];
  if (pos) {
    let parts = toHyphens(pos).split("-");
    for (let i = 0; i < parts.length; i++) {
      result.push("k-pos-" + parts[i]);
    }
  }
  return result.join(" ");
};

/* eslint-enable arrow-body-style, no-useless-escape */