
import { MeterDetailsRoutingModule } from './consumers-meter-details-routing.module';
import { RpMeterDetailsComponent } from './rp-meter-details/rp-meter-details.component';

import { RpMeterControllsComponent } from './rp-meter-controlls/rp-meter-controlls.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule, PDFModule, ExcelModule, } from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SharedModule } from '../../shared/shared.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { ComponentsModule } from '../../shared/components/components.module';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { RpMetersComponent } from './rp-meters/rp-meters.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    RpMeterDetailsComponent, RpMeterControllsComponent, RpMetersComponent,
  ],
  imports: [
    CommonModule,
    MeterDetailsRoutingModule,
    AccordionModule, GridModule, BsDatepickerModule,
    PDFModule, ComponentsModule, SortableModule,
    ExcelModule, NgStepperModule, CdkStepperModule, TextBoxModule, SharedModule, UiSwitchModule,

  ],

})
export class ConsumersMeterDetailModule { }
