import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagerModule } from '@progress/kendo-angular-pager';
import { FeedbackHistoryRoutingModule } from './feedback-history-routing.module';
import { RpFeedbackHistoryComponent } from './rp-feedback-history/rp-feedback-history.component';
import { ConsumerFeedbackComponent } from './rp-feedback/rp-feedback.component';
import { ComponentsModule } from '../../shared/components/components.module';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
@NgModule({
  declarations: [RpFeedbackHistoryComponent, ConsumerFeedbackComponent],
  imports: [
    SharedModule, TextBoxModule,
    CommonModule,
    FeedbackHistoryRoutingModule,
    ComponentsModule,
    PagerModule,    
    BsDatepickerModule.forRoot(),
  ],
})
export class FeedbackHistoryModule { }
