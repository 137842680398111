import { ResidentialUnitModule } from '../../features/residential-units/residentialunit.module';
import { createAction, props } from '@ngrx/store';
import { residentialUnitModel } from './residentialUnit.model';

// fetch
export const getResidentialUnitlist = createAction(
  '[Residential Unit] getResidentialUnit',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    projectId: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getResidentialUnitSuccess = createAction(
  '[Residential Unit] fetch ResidentialUnit success',
  props<{ getResidentialUnitResponse: any }>()
);
export const getResidentialUnitFailure = createAction(
  '[Residential Unit fetch ResidentialUnit failed]',
  props<{ getResidentialUnitError: any }>()
);

export const getAllResidentialUnitlist = createAction(
  '[Residential Unit] getAllResidentialUnit',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    projectId: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllResidentialUnitSuccess = createAction(
  '[Residential Unit] fetch AllResidentialUnit success',
  props<{ getAllResidentialUnit_Response: any }>()
);
export const getAllResidentialUnitFailure = createAction(
  '[Residential Unit fetchAll ResidentialUnit failed]',
  props<{ getAllResidentialUnitError: any }>()
);

// fetch  by id
export const getResidentialUnitByIdlist = createAction(
  '[Residential Unit] getResidentialUnit ById',
  props<{ id: string }>()
);
export const getResidentialUnitByIdSuccess = createAction(
  '[Residential Unit] fetch ResidentialUnit  By id success',
  props<{ getResidentialUnitByIdResponse: any }>()
);
export const getResidentialUnitByIdFailure = createAction(
  '[Residential Unit fetch ResidentialUnit  By Id failed]',
  props<{ getResidentialUnitByIdError: any }>()
);

// reset store data
export const resetResidentialUnitState = createAction(
  '[Residential Unit] Reset State'
);

//export const getOccupationHistorylist = createAction('[Occupation History] OccupationHistory', props<{ pageIndex: number; pageSize: number; searchTerm: string, id: string }>());

//Occupation History
export const getOccupationHistorylist = createAction(
  '[Occupation History] OccupationHistory',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    id: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getOccupationHistorySuccess = createAction(
  '[Occupation History] fetch OccupationHistory success',
  props<{ getOccupationHistoryResponse: any }>()
);
export const getOccupationHistoryFailure = createAction(
  '[Occupation History fetch OccupationHistory failed]',
  props<{ getOccupationHistoryUnitError: any }>()
);

//Residential Unit meters History
export const getResidentialHistorylist = createAction(
  '[Residential History] Residential History',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    id: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getResidentialHistorySuccess = createAction(
  '[Residential History] fetch ResidentialHistory success',
  props<{ getResidentialHistoryResponse: any }>()
);
export const getResidentialHistoryFailure = createAction(
  '[Residential History fetch ResidentialHistory failed]',
  props<{ getResidentialHistoryUnitError: any }>()
);

//Residential Unit meters History
export const getExportResidentialHistory = createAction(
  '[ Export Residential History] Residential 323232',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    id: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getExportResidentialHistorySuccess = createAction(
  '[Export Residential History] fetch ResidentialHistory success',
  props<{ getExportResidentialHistoryResponse: any }>()
);
export const getExportResidentialHistoryFailure = createAction(
  '[Export Residential History fetch ResidentialHistory failed]',
  props<{ getExportResidentialHistoryError: any }>()
);

export const reseExportResidentialHistorytState = createAction(
  '[Export Residential History state] Reset Residential State'
);

//Export to Excel Occupaint list
export const getOccupationHistoryExpotyExcellist = createAction(
  '[Occupation History ExpotyExcel] OccupationHistory ExpotyExcel',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    id: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getOccupationHistoryExpotyExcelSuccess = createAction(
  '[Occupation History ExpotyExcel] fetch OccupationHistory  ExpotyExcel success',
  props<{ getOccupationHistoryExpotyExcelResponse: any }>()
);
export const getOccupationHistoryExpotyExcelFailure = createAction(
  '[Occupation HistoryExpotyExcel] fetch OccupationHistory  ExpotyExcel failed]',
  props<{ getOccupationHistoryExpotyExcelUnitError: any }>()
);

// Create User List
export const createResidentialUnit = createAction(
  '[Residential Unit] ResidentialUnit',
  props<{ ResidentialUnit: residentialUnitModel }>()
);
export const addResidentialUnitSuccess = createAction(
  '[Residential Unit] ResidentialUnit Success',
  props<{ createResidentialUnitResponse: any }>()
);
export const addResidentialUnitFailure = createAction(
  '[Residential Unit] ResidentialUnit Failure',
  props<{ createResidentialUnitError: any }>()
);

// Update Data
export const updateResidentialUnit = createAction(
  '[Residential Unit] Update ResidentialUnit',
  props<{ ResidentialUnit: residentialUnitModel; id: string }>()
);
export const updateResidentialUnitSuccess = createAction(
  '[Residential Unit] Update ResidentialUnit Success',
  props<{ updateResidentialUnitResponse: any }>()
);
export const updateResidentialUnitFailure = createAction(
  '[Residential Unit] Update ResidentialUnit Failure',
  props<{ updateResidentialUnitError: any }>()
);

export const resetState = createAction('[App] Reset State');

export const resetOccupaintHistoryState = createAction(
  '[App] Reset  HistoryState'
);
