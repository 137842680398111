import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../shared/shared.service';
import { Roles, SweetAlertIcon } from '../../../shared/constants/enum';
import { FeedBackService } from '../../../core/services/feedback.service';
import { LoaderService } from '../../../core/services/loader.service';
import { table_pageSize } from '../../../shared/constants/templatesNames';
import { PageChangeEvent } from '@progress/kendo-angular-grid';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { formatDate } from '@angular/common';  // <-- Import formatDate from common
import { NotificationService } from '../../../shared/notification.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-rp-feedback-history',
  templateUrl: './rp-feedback-history.component.html',
  styleUrls: ['./rp-feedback-history.component.scss'],
})
export class RpFeedbackHistoryComponent implements OnInit {
  searchText = '';
  selectedDate:any = [];

  listOfFeedBacks: any[] = [];
  filteredFeedbacks: any[] = [];
  pagedFeedbacks: any[] = []; // Store paged data
  currentUser: any;
  projectId: any;
  userRole: any;
  listOfRoles = Roles;
  breadCrumbItems: Array<{}> | undefined;
  filterProjectBillDates: any = [];
  datePickerConfig: Partial<BsDatepickerConfig>;

  public pageSize = 5;
  public skip = 0;
  public contentId = 'content-1';
  public total = 1;
  public pageSizes = table_pageSize.pageSizes;
  notificationFeedBack: any;
  private feedbackSubscription: Subscription;
  constructor(
    private router: Router,
    private feedbackService: FeedBackService,
    private sharedService: SharedService,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private notificationService: NotificationService
  ) {
    this.breadCrumbItems = [{ label: 'Feedback History' }];
    this.notificationFeedBack = history.state.info;
    if(this.notificationFeedBack){
      this.searchText = this.notificationFeedBack.feedbackName
    }
    this.feedbackSubscription = this.notificationService.feedbackUpdate$.subscribe(data => {
      this.updateFeedbackPage(data);
    });

    this.datePickerConfig = Object.assign({}, {
      dateInputFormat: 'DD/MM/YYYY', // Set to dd/MM/yyyy format
      showWeekNumbers: false,
      isAnimated: true,
      containerClass: 'theme-dark-blue'
    });
  }

  updateFeedbackPage(data: any) {
    this.notificationFeedBack = data;
    this.searchText = this.notificationFeedBack.feedbackName;
    this.updateFilteredFeedbacks('filter');
    // Trigger any additional logic needed to refresh the page
  }

  ngOnDestroy() {
    if (this.feedbackSubscription) {
      this.feedbackSubscription.unsubscribe();
    }
  }


  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }

  ngOnInit() {
    this.loaderService.setLoading(true);
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectId = this.currentUser?.project?.id;
    this.userRole = this.currentUser?.currentUserRoleDetails?.role;

    if (this.userRole === this.listOfRoles.ADMIN) {
      this.feedbackService.getFeedBackServie(this.projectId).subscribe({
        next: (response) => {
          this.loaderService.setLoading(false);
          if (response.statusCode === 200) {
            this.listOfFeedBacks = response.data;
            this.total = this.listOfFeedBacks.length;
            this.updateFilteredFeedbacks('filter');
          }
        },
        error: (err) => {
          this.loaderService.setLoading(false);
          this.sharedService.showMessageDialog(
            'Failure',
            err,
            SweetAlertIcon.ERROR
          );
        },
      });
    } else {
      this.feedbackService.getSuperAdminFeedBackServie().subscribe({
        next: (response) => {
          this.loaderService.setLoading(false);
          if (response.statusCode === 200) {
            this.listOfFeedBacks = response.data;
            this.total = this.listOfFeedBacks.length;
            this.updateFilteredFeedbacks('filter');
          }
        },
        error: (err) => {
          this.loaderService.setLoading(false);
          this.sharedService.showMessageDialog(
            'Failure',
            err,
            SweetAlertIcon.ERROR
          );
        },
      });
    }
  }

  public onPageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    this.updateFilteredFeedbacks('fiter');
  }

  
  clearDate() {
    this.selectedDate = []; // Clear the selected date
  }

  
  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }

  updateFilteredFeedbacks(type:string) {
    console.log(this.selectedDate,new Date(this.selectedDate[0]),new Date(this.selectedDate[1]));
    
    if(type=='filter'){

    }else{
      this.selectedDate=[];
      this.searchText=''
    }
    const filtered = this.listOfFeedBacks.filter((item) => {
      const matchesText = this.searchText
        ? item.name.toLowerCase().includes(this.searchText.toLowerCase()) ||item.residentialUnit.toLowerCase().includes(this.searchText.toLowerCase()) ||
          item.description.toLowerCase().includes(this.searchText.toLowerCase())
        : true;
        
      // const matchesDate = this.selectedDate
      //   ? new Date(item.dateTime).toDateString() ===
      //     new Date(this.selectedDate).toDateString()
      //   : true;
      // const matchesDate = this.selectedDate && Array.isArray(this.selectedDate) && this.selectedDate.length === 2
      // ? new Date(item.dateTime) >= new Date(this.selectedDate[0]) && // Greater than or equal to start date
      //   new Date(item.dateTime) <= new Date(this.selectedDate[1])   // Less than or equal to end date
      // : true;

      const startDate = new Date(this.selectedDate[0]);
      const endDate = new Date(this.selectedDate[1]);

      // Adjust for one day before the start date and one day after the end date
      const adjustedStartDate = new Date(startDate);
      adjustedStartDate.setDate(startDate.getDate() - 1);

      const adjustedEndDate = new Date(endDate);
      adjustedEndDate.setDate(endDate.getDate() + 1);

      const matchesDate = this.selectedDate && Array.isArray(this.selectedDate) && this.selectedDate.length === 2
          ? new Date(item.dateTime) >= adjustedStartDate && // Greater than or equal to one day before start date
            new Date(item.dateTime) <= adjustedEndDate    // Less than or equal to one day after end date
          : true;



        if(this.notificationFeedBack){
          const matchesText =  this.notificationFeedBack.feedbackId
          ? item.id.toLowerCase().includes( this.notificationFeedBack.feedbackId.toLowerCase()) ||
            item.description.toLowerCase().includes(this.searchText.toLowerCase())
          : true;
        }
      return matchesText && matchesDate;
    });


    this.total = filtered.length;

    // Slice data based on the current page and pageSize
    this.pagedFeedbacks = filtered.slice(this.skip, this.skip + this.pageSize);
  }
}
