import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConsumersService } from '../../../core/services/consumers.service';
import { SharedService } from '../../../shared/shared.service';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Validations } from '../../../shared/constants/validations';
import { FeedBackService } from '../../../core/services/feedback.service';
import { LoaderService } from '../../../core/services/loader.service';

@Component({
  selector: 'app-consumer-feedback',
  templateUrl: './rp-feedback.component.html',
  styleUrl: './rp-feedback.component.scss',
})
export class ConsumerFeedbackComponent {
  consumerFeedback: any;
  consumerFeedbackForm!: UntypedFormGroup;
  prifileValidations = Validations;
  submitted: boolean = false;
  userRole: any;
  constructor(
    private router: Router,
    private feedbackService: FeedBackService,
    private sharedService: SharedService,
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder,
    private loaderService:LoaderService
  ) {
    this.consumerFeedback = this.formBuilder.group({
      feedback: ['', [Validators.required, Validators.minLength(2)]],
    });
    this.sharedService.getCurrentUser().subscribe((user) => {
      this.userRole = user?.currentUserRoleDetails?.role;
    });
  }

  get consumersFeedbackControls() {
    return this.consumerFeedback.controls;
  }
  closeModal() {
    this.modalService.hide();
  }

  ngOnInit() {}

  onSubmit() {
    this.loaderService.setLoading(true)
    this.submitted = true;
    //saveFeedback
    if (this.consumerFeedback.valid) {
      let payload = {
        description: this.consumerFeedback.get('feedback')?.value,
      };
      this.feedbackService.saveFeedback(payload, this.userRole).subscribe({
        next: (response) => {
          this.loaderService.setLoading(false)
          if (response.statusCode === 200) {
            this.sharedService.showMessageDialog(
              'Success',
              'Thanks For Feedback!',
              SweetAlertIcon.SUCCESS
            );
            this.modalService.hide();
          }
        },
        error: (err) => {
          this.loaderService.setLoading(false)
          this.sharedService.showMessageDialog(
            'Failure',
            err,
            SweetAlertIcon.ERROR
          );
        },
      });
    }
  }
}
