import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConsumptionHistoryComponent } from './consumption-history/consumption-history.component';
import { RpConsumptionViewComponent } from './rp-consumption-view/rp-consumption-view.component';
import { RpMetersViewComponent } from '../meters/rp-meters-view/rp-meters-view.component';
RpConsumptionViewComponent;
const routes: Routes = [
  // { path: 'consumptionHistory', component: ConsumptionHistoryComponent },
  { path: 'consumptionHistory', component: RpMetersViewComponent },
  {
    path: 'consumptionHistory/consumptionView',
    component: RpConsumptionViewComponent,
  },
  {
    path: 'walletHistory/consumptionView',
    component: RpConsumptionViewComponent,
  },
  {
    path: 'meters/consumptionView',
    component: RpConsumptionViewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConsumptionHistoryRoutingModule {}
