import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import { LoaderService } from '../../../core/services/loader.service';
import { Roles } from '../../../shared/constants/enum';

@Component({
  selector: 'app-rp-consumption-view',
  templateUrl: './rp-consumption-view.component.html',
  styleUrl: './rp-consumption-view.component.scss',
})
export class RpConsumptionViewComponent {
  isAllOpen: boolean = true;
  isotherChargesOpen: boolean = true;
  consumption: any;
  undefinde: any;
  breadCrumbItems: Array<{}> | undefined;
  currentUser: any;
  roles = Roles;
  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private store: Store,
    private sharedService: SharedService
  ) {
    if (
      history.state.consumption != undefined &&
      history.state.consumption !== null
    ) {
      this.consumption = history.state.consumption;
      this.isotherChargesOpen =
        this.consumption?.otherCharges?.length > 0 ? true : false;
    }
  }
  ngAfterViewInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');

    if (this.currentUser?.currentUserRoleDetails?.role == this.roles.ADMIN && !this.consumption?.isWallet) {
      this.breadCrumbItems = [
        { label: 'Meters', url: 'meters', active: true },
        { label: 'Meter Details', url: 'meters/metersView', active: true },
        { label: 'View Consumption History' },
      ];
    } else if (history.state.consumption! && history.state.fromPage == 'WalletHistory') {
      this.breadCrumbItems = [
        {
          label: 'Wallet History',
          url: 'walletHistory',
          active: true,
        },
        { label: ' View Consumption Details' },
      ];

    } else {
      if (this.currentUser?.currentUserRoleDetails?.role == this.roles.CONSUMER && this.consumption.isWallet == true) {
        this.breadCrumbItems = [
          {
            label: 'Wallet History',
            url: 'walletHistory',
            active: true,
          },
          { label: ' View Consumption History' },
        ];
      } else if (this.currentUser?.currentUserRoleDetails?.role == this.roles.ADMIN && this.consumption?.isWallet) {
        this.breadCrumbItems = [
          { label: 'Wallet', url: 'wallet', active: true },
          { label: 'walletDetails', url: 'wallet/walletDetails', active: true },
          { label: 'View Consumption History' },
        ];
      }
      else {
        this.breadCrumbItems = [
          {
            label: 'Consumption History',
            url: 'consumptionHistory',
            active: true,
          },
          { label: ' View Consumption History' },
        ];
      }
    }
  }
}
