import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpMeterDetailsComponent } from './rp-meter-details/rp-meter-details.component';
import { RpMeterControllsComponent } from './rp-meter-controlls/rp-meter-controlls.component';
import { RpMetersComponent } from './rp-meters/rp-meters.component';

const routes: Routes = [

  { path: 'meterDetails', component: RpMeterDetailsComponent },
  { path: 'Meters', component: RpMetersComponent },

  { path: 'meterContolls', component: RpMeterControllsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MeterDetailsRoutingModule { }
