import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RpCreateResidentialUnitComponent } from './rp-create-residential-unit/rp-create-residential-unit.component';
import { RpResidentialUnitComponent } from './rp-residential-unit/rp-residential-unit.component';
import { ResidentialUnitRoutingModule } from './residentialunit-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UiSwitchModule } from 'ngx-ui-switch';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { SharedModule } from '../../shared/shared.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../../shared/components/components.module';

@NgModule({
  declarations: [RpCreateResidentialUnitComponent, RpResidentialUnitComponent],
  imports: [
    CommonModule,
    ResidentialUnitRoutingModule,
    NgSelectModule,
    BsDatepickerModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    ModalModule,
    UiSwitchModule,
    UploadsModule,
    NgStepperModule,
    CdkStepperModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    SortableModule,
    SharedModule,
    DropDownsModule,
    ComponentsModule,
  ],
})
export class ResidentialUnitModule {}
