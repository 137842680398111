import { createAction, props } from '@ngrx/store';
import { Utilites } from './utilities.models';

export const getutilities = createAction(
  '[utilities] getutilities',
  props<{ projectId: any }>()
);

export const getutilitiesuccess = createAction(
  '[utilities] getutilities Success',
  props<{ getutilitiesResponse: any }>()
);

export const getutilitiesFailure = createAction(
  '[utilities] getutilities Failure',
  props<{ getutilitiesError: any }>()
);

export const createutilities = createAction(
  '[utilities] Createutilities',
  props<{ utilitiesData: any }>()
);

export const Createutilitiesuccess = createAction(
  '[utilities] Createutilities Success',
  props<{ createutilitiesResponse1: any }>()
);

export const CreateutilitiesFailure = createAction(
  '[utilities] Createutilities Failure',
  props<{ createutilitiesError: any }>()
);

export const GetGasRateData = createAction(
  '[meter] GetGasRateData',
  props<{
    pageIndex: number;
    pageSize: number;
    utilityMasterId: string;
    searchTerm: string;
    projectId: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getGasRateDataSuccess = createAction(
  '[utilities] getGasRateDataSuccess',
  props<{ getGasRateDataResponse: any }>()
);
export const getGasRateDataFailure = createAction(
  '[utilities] getGasRateDataFailure',
  props<{ getGasRateDataError: any }>()
);

export const GetWaterRateData = createAction(
  '[meter] GetWaterRateData',
  props<{
    pageIndex: number;
    pageSize: number;
    utilityMasterId: string;
    searchTerm: string;
    projectId: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getWaterRateDataSuccess = createAction(
  '[utilities] getWaterRateDataSuccess',
  props<{ getWaterRateDataResponse: any }>()
);
export const getWaterRateDataFailure = createAction(
  '[utilities] getWaterRateDataFailure',
  props<{ getWaterRateDataError: any }>()
);

export const GetElectricityRateData = createAction(
  '[meter] GetElectricityRateData',
  props<{
    pageIndex: number;
    pageSize: number;
    utilityMasterId: string;
    searchTerm: string;
    projectId: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getElectricityRateDataSuccess = createAction(
  '[utilities] getElectricityRateDataSuccess',
  props<{ getElectricityRateDataResponse: any }>()
);
export const getElectricityRateDataFailure = createAction(
  '[utilities] getElectricityRateDataFailure',
  props<{ getElectricityRateDataError: any }>()
);



export const GetEvData = createAction(
  '[meter] GetEvData',
  props<{
    pageIndex: number;
    pageSize: number;
    utilityMasterId: string;
    searchTerm: string;
    projectId: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getEvDataSuccess = createAction(
  '[utilities] getEvDataSuccess',
  props<{ getEvDataResponse: any }>()
);
export const getEvDataFailure = createAction(
  '[utilities] getEvDataFailure',
  props<{ getEvDataError: any }>()
);




export const GetAllGasRateData = createAction(
  '[meter] GetAllGasRateData',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    utilityMasterId: string;
    searchTerm: string;
    projectId: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllGasRateDataSuccess = createAction(
  '[utilities] getAllGasRateDataSuccess',
  props<{ getAllGasRateDataResponse: any }>()
);
export const getAllGasRateDataFailure = createAction(
  '[utilities] getAllGasRateDataFailure',
  props<{ getAllGasRateDataError: any }>()
);

export const resetUnitRateHistoryState = createAction(
  '[resetUnitRateHistoryState ] resetUnitRateHistoryState'
);
