<div class="container-fluid">

  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-0 font-size-18">Meter Control</h4>
        <!-- <div class="page-title-right">
              <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item active" [routerLink]="'/wallet'"><a href="javascript: void(0);">Wallet</a>
                  </li>
                  
                  <li class="breadcrumb-item">Wallet Details
                  </li>
              
              </ol>
            </div> -->
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div id="basic-example">
            <ng-stepper #cdkStepper [linear]="true" class="wizard custom-steeper">

              <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                  <span class="number">1.</span>
                  <span>Meter</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 0){
                <section>
                  <kendo-grid [kendoGridBinding]="gridView" [reorderable]="true" filterable="menu"
                    [columnMenu]="{ filter: true }" [resizable]="true" [pageable]="true" [pageSize]="10"
                    [sortable]="true" [selectable]="true" kendoGridSelectBy="id" [resizable]="true">

                    <kendo-grid-pdf fileName="GridExport.pdf">
                    </kendo-grid-pdf>
                    <kendo-grid-excel fileName="GridExport.xlsx">
                    </kendo-grid-excel>

                    <ng-template kendoGridToolbarTemplate>
                      <div class="search_icon">
                        <kendo-textbox [style.width.px]="250" [style.height.px]="35"
                          placeholder="Search in all columns...">
                        </kendo-textbox>

                        <button class="btn btn-primary btn-md border-left-none" (click)="onFilter($event)">
                          <i class="fa fa-search"></i>
                        </button>
                      </div>
                      <!--  <kendo-textbox [style.width.px]="250" [style.height.px]="35" [style.height.px]="35"
                        placeholder="Search in all columns..." (valueChange)="onFilter($event)"></kendo-textbox> -->
                      <kendo-grid-spacer></kendo-grid-spacer>
                      <button kendoGridPDFCommand class="btn btn-light">
                        <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button>
                      <button kendoGridExcelCommand class="btn btn-light">
                        <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                      </button>

                    </ng-template>


                    <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                      [width]="40"></kendo-grid-checkbox-column>

                    <kendo-grid-column field="ProjectName" title="Meter Number"></kendo-grid-column>
                    <kendo-grid-column field="Utility" title="Meter Type"></kendo-grid-column>

                    <!-- <kendo-grid-command-column title="Actions" [columnMenu]="false" [width]="100">
                          <ng-template kendoGridCellTemplate>
                                  <button class="btn btn-primary btn-sm m-lg-auto ">
                                      View
                                  </button>
                          </ng-template>
                      </kendo-grid-command-column> -->

                  </kendo-grid>

                  <ul class="list-inline wizard d-flex justify-content-end mt-4">
                    <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                        cdkStepperPrevious>Previous</button></li>
                    <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary"
                        cdkStepperNext>Next</button></li>
                  </ul>
                </section>
                }
              </cdk-step>

              <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                  <span class="number">2.</span>
                  <span>On/Off History </span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 1){
                <section>
                  <kendo-grid [kendoGridBinding]="gridWalletView" [reorderable]="true" filterable="menu"
                    [columnMenu]="{ filter: true }" [resizable]="true" [pageable]="true" [pageSize]="10"
                    [sortable]="true" [selectable]="true" kendoGridSelectBy="id" [resizable]="true">

                    <kendo-grid-pdf fileName="GridExport.pdf">
                    </kendo-grid-pdf>
                    <kendo-grid-excel fileName="GridExport.xlsx">
                    </kendo-grid-excel>

                    <ng-template kendoGridToolbarTemplate>
                      <div class="search_icon">
                        <kendo-textbox [style.width.px]="250" [style.height.px]="35" [style.height.px]="35"
                          placeholder="Search in all columns...">
                        </kendo-textbox>

                        <button class="btn btn-primary btn-md border-left-none" (click)="onFilterWallet($event)">
                          <i class="fa fa-search"></i>
                        </button>
                      </div>
                      <!-- <kendo-textbox [style.width.px]="250" [style.height.px]="35" [style.height.px]="35"
                        placeholder="Search in all columns..." (valueChange)="onFilterWallet($event)"></kendo-textbox>
                      <kendo-grid-spacer></kendo-grid-spacer> -->
                      <button kendoGridPDFCommand class="btn btn-light">
                        <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button>
                      <button kendoGridExcelCommand class="btn btn-light">
                        <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                      </button>

                    </ng-template>


                    <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                      [width]="40"></kendo-grid-checkbox-column>
                    <kendo-grid-column field="ProjectCode" title="Meter Number"></kendo-grid-column>
                    <kendo-grid-column field="Utility" title="Meter Type"></kendo-grid-column>
                    <kendo-grid-column field="Consumption" title="Meter Status"></kendo-grid-column>
                    <kendo-grid-column field="BillDate" title="Date"></kendo-grid-column>
                    <!-- <kendo-grid-command-column title="Actions" [columnMenu]="false" [width]="100">
                      <ng-template kendoGridCellTemplate>
                        <button class="btn btn-primary btn-sm m-lg-auto ">
                          View
                        </button>
                      </ng-template>
                      </kendo-grid-command-column> -->

                  </kendo-grid>


                  <ul class="list-inline wizard d-flex justify-content-end mt-4">
                    <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                        cdkStepperPrevious>Previous</button></li>
                    <!-- <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary" cdkStepperNext>Next</button></li> -->
                  </ul>
                </section>}
              </cdk-step>

            </ng-stepper>
          </div>

        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>

</div>