<div class="container-fluid">


  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        <span class="breadcrumb m-0">
          @if(cdkStepper.selectedIndex === 1){<button class="btn btn-primary d-flex align-items-center"
            (click)="umdExportAll()">
            <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
          </button>}
          @if(cdkStepper.selectedIndex === 2){<button class="btn btn-primary d-flex align-items-center"
            (click)="electricCityExportAll()">
            <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
          </button>}

        </span>

      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title mb-4"></h4> -->
          <div>
            <ng-stepper #cdkStepper [linear]="true" class="wizard" (selectionChange)="onStepChange($event)">

              <cdk-step [optional]="false">
                <ng-template cdkStepLabel>
                  <span class="number">1.</span>
                  <span>Utilities</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 0){

                <section class="">
                  <form [formGroup]="utilitiesForm">
                    <div>
                      <accordion [closeOthers]="false" class="accordion custom-accordion" [isAnimated]="true">
                        <!-- Gas Section -->
                        <accordion-group [isOpen]="isAllOpen" heading="Gas" class="accordion-item mb-3 panel-open"
                          #group *ngIf="currentUser?.projectUtilities&&isUtilityDisplay('Gas')">
                          <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                            <i class="mdi font-size-24"
                              [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ' : 'mdi mdi-chevron-up accor-plus-icon '"></i>
                          </span>

                          <div class="row" formGroupName="gas">
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="gasValue">Gas Value <span class="required">*</span></label>
                                <input type="text" class="form-control" id="gasValue" formControlName="gasValue"
                                  (blur)="onGasValueBlur($event)">
                                @if((submitted || gasForm.controls['gasValue'].touched) &&
                                gasForm.controls['gasValue']?.errors) {
                                <div class="invalid-feedback">
                                  @if(gasForm.controls['gasValue'].errors?.['required']) {
                                  <div>{{ utility_gas_Validations.gasValue.required }}</div>
                                  }
                                  @if(gasForm.controls['gasValue'].errors?.['minlength']) {
                                  <div>{{utility_gas_Validations.gasValue.minLengthValidator}}</div>}
                                  @if(gasForm.controls['gasValue'].errors?.['maxlength']){
                                  <div>{{utility_gas_Validations.gasValue.MaxLengthValidator}}</div>
                                  }

                                  @if(gasForm.controls['gasValue'].errors?.['pattern']) {
                                  <div>{{utility_gas_Validations.gasValue.patternValidation}}</div>}
                                </div>
                                }
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="UnitLPGValue">1 KG/ LPG<span class="required">*</span></label>
                                <input type="text" class="form-control" id="UnitLPGValue"
                                  formControlName="UnitLPGValue">
                                @if((submitted || gasForm.controls['UnitLPGValue'].touched)&&
                                gasForm.controls['UnitLPGValue']?.errors) {
                                <div class="invalid-feedback">
                                  @if(gasForm.controls['UnitLPGValue']?.errors?.['required']) {
                                  <div>{{ utility_gas_Validations.UnitLPGValue.required }}</div>
                                  }
                                  @if(gasForm.controls['UnitLPGValue'].errors?.['pattern']) {
                                  <div>{{utility_gas_Validations.UnitLPGValue.patternValidation}}</div>}
                                </div>
                                }
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="unitPrice">Unit Price (1 KG/ LPG(In Rs))<span
                                    class="required">*</span></label>
                                <input type="text" class="form-control disabled" id="unitPrice"
                                  formControlName="unitPrice">
                                @if((submitted || gasForm.controls['unitPrice'].touched)&&
                                gasForm.get('unitPrice')?.errors) {
                                <div class="invalid-feedback">
                                  @if(gasForm.get('unitPrice')?.errors?.['required']) {
                                  <div>{{ utility_gas_Validations.unitPrice.required }}</div>
                                  }
                                  @if(gasForm.controls['unitPrice'].errors?.['pattern']) {
                                  <div>{{utility_gas_Validations.UnitLPGValue.patternValidation}}</div>}
                                </div>
                                }
                              </div>
                            </div>
                            <div class="col-lg-6 mb-3">
                              <!-- Title and Download Link in a single row -->
                              <div class="d-flex align-items-center">
                                <label for="basicpill-declaration-input" class="me-2">Upload Gas Document</label>


                              </div>
                              <!-- <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl"
                                formControlName="File" id="File" [batch]="false" [multiple]="false">
                              </kendo-upload> -->
                              <div class="upload-container">
                                <!-- Kendo Upload Component -->
                                <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl"
                                  (success)="onSuccess()" (upload)="uploadGasFileEventHandler($event)"
                                  (remove)="removeGasFileEventHandler($event)" formControlName="File" id="File"
                                  [batch]="false" [multiple]="false">
                                </kendo-upload>


                                <!-- Download Button -->
                                <button type="button"
                                  *ngIf="getUtilitiesData?.gas&&getUtilitiesData?.gas?.gasFile?.fileUrl"
                                  class="btn btn-icon" title="Download"
                                  (click)="downloadFile(getUtilitiesData?.gas?.gasFile?.fileUrl,getUtilitiesData?.gas?.gasFile?.fileName)"
                                  style="margin-left: 10px;">
                                  <i class="fa fa-download"></i>
                                </button>
                              </div>


                            </div>
                          </div>
                        </accordion-group>

                        <!-- Water Section -->
                        <accordion-group [isOpen]="isAllOpen" heading="Water" class="accordion-item mb-3 panel-open"
                          *ngIf="currentUser?.projectUtilities&&isUtilityDisplay('Water')" #md>
                          <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                            <i class="mdi font-size-24"
                              [ngClass]="!md?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ' : 'mdi mdi-chevron-up accor-plus-icon '"></i>
                          </span>
                          <div formArrayName="water">
                            <div *ngFor="let group of waterArray.controls; let i = index" [formGroupName]="i"
                              class="row row-cols-lg-4">
                              <div class="col">

                                <div class="mb-3">
                                  <label for="">Supplier Name <span class="required">*</span></label>
                                  <ng-select [items]="waterTypeItems" bindLabel="name" bindValue="id"
                                    [placeholder]="'Select...'" [multiple]="false"
                                    formControlName="slabName"></ng-select>
                                  <!-- <input type="text" class="form-control" formControlName="slabName"> -->
                                  @if((submitted || group.get('slabName')?.touched)&&
                                  group.get('slabName')?.errors) {
                                  <div class="invalid-feedback">
                                    @if(group.get('slabName')?.errors?.['required']) {
                                    <div>{{ utility_water_Validations.slabName.required }}</div>
                                    }
                                  </div>
                                  }


                                </div>
                              </div>
                              <div class="col">
                                <div class="mb-3">
                                  <label>Min L<span class="required">*</span></label>
                                  <input type="text" class="form-control" formControlName="MinValue">
                                  @if((submitted|| group.get('MinValue')?.touched) && group.get('MinValue')?.errors) {
                                  <div class="invalid-feedback">
                                    @if(group.get('MinValue')?.errors?.['required']) {
                                    <div>{{ utility_water_Validations.MinValue.required }}</div>
                                    }
                                    @if(group.get('MinValue')?.errors?.['pattern']) {
                                    <div>{{utility_water_Validations.MinValue.patternValidation}}</div>}
                                  </div>
                                  }
                                </div>
                              </div>
                              <div class="col">
                                <div class="mb-3">
                                  <label>Max<span class="required">*</span></label>
                                  <input type="text" class="form-control" formControlName="MaxValue">
                                  @if((submitted || group.get('MaxValue')?.touched)&&group.get('MaxValue')?.errors) {
                                  <div class="invalid-feedback">
                                    @if(group.get('MaxValue')?.errors?.['required']) {
                                    <div>{{ utility_water_Validations.MaxValue.required }}</div>
                                    }
                                    @if(group.get('MaxValue')?.errors?.['pattern']) {
                                    <div>{{utility_water_Validations.MaxValue.patternValidation}}</div>}
                                  </div>
                                  }
                                </div>
                              </div>
                              <div class="col">
                                <div class="mb-3 d-flex align-items-center justify-content-between">
                                  <div class="w-100">
                                    <label>Slab Per Unit Price<span class="required">*</span></label>
                                    <input type="text" class="form-control" formControlName="unitPrice"
                                      (blur)="onWaterValueBlur($event, i)">
                                    @if((submitted || group.get('unitPrice')?.touched) &&
                                    group.get('unitPrice')?.errors) {
                                    <div class="invalid-feedback">
                                      @if(group.get('unitPrice')?.errors?.['required']) {
                                      <div>{{ utility_water_Validations.slabPerUnit.required }}</div>
                                      }
                                      @if(group.get('unitPrice')?.errors?.['pattern']) {
                                      <div>{{utility_water_Validations.slabPerUnit.patternValidation}}</div>}
                                    </div>
                                    }
                                  </div>
                                  <button type="button" *ngIf="i >= 0&&waterArray.controls.length>1"
                                    class="btn btn-danger ms-2 mt-4 px-1 py-1" (click)="removeWater(i)">
                                    <i class="mdi mdi-trash-can d-block font-size-16"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="d-flex justify-content-end  mb-3">
                            <button type="button" class="btn btn-primary" (click)="addWater()"> Add Row</button>
                          </div>



                          <div class="col-lg-6 mb-3">
                            <!-- Title and Download Link in a single row -->
                            <div class="d-flex align-items-center">
                              <label for="basicpill-declaration-input">Upload Water Document</label>


                            </div>

                            <div class="upload-container">
                              <!-- Kendo Upload Component -->
                              <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl"
                                (success)="onSuccess()" (upload)="uploadWaterFileEventHandler($event)"
                                (remove)="removeWaterFileEventHandler($event)" formControlName="WaterFile"
                                id="WaterFile" [batch]="false" [multiple]="false">
                              </kendo-upload>


                              <!-- Download Button -->
                              <button type="button"
                                *ngIf="getUtilitiesData?.water&&getUtilitiesData?.water[0]?.waterFile?.fileUrl"
                                class="btn btn-icon" title="Download"
                                (click)="downloadFile(getUtilitiesData?.water[0]?.waterFile?.fileUrl,getUtilitiesData?.water[0]?.waterFile?.fileName)"
                                style="margin-left: 10px;">
                                <i class="fa fa-download"></i>
                              </button>
                            </div>
                          </div>
                        </accordion-group>

                        <!-- Electricity Section -->
                        <accordion-group [isOpen]="isAllOpen" heading="Electricity"
                          class="accordion-item mb-3 panel-open" #item3
                          *ngIf="currentUser?.projectUtilities&&isUtilityDisplay('Electricity')">
                          <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                            <i class="mdi font-size-24"
                              [ngClass]="!item3?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ' : 'mdi mdi-chevron-up accor-plus-icon '"></i>
                          </span>
                          <div formArrayName="electricity">
                            <div *ngFor="let group of electricityArray.controls; let j = index" [formGroupName]="j"
                              class="row row-cols-lg-4">
                              <div class="col">
                                <div class="mb-3">
                                  <label for=""> Supplier Name<span class="required">*</span></label>
                                  <ng-select [items]="electricityTypeItems" bindLabel="name" bindValue="id"
                                    [placeholder]="'Select...'" [multiple]="false"
                                    formControlName="slabName"></ng-select>
                                  @if((submitted || group.get('slabName')?.touched)&& group.get('slabName')?.errors) {
                                  <div class="invalid-feedback">
                                    @if(group.get('slabName')?.errors?.['required']) {
                                    <div>{{ utility_electricity_Validations.slabName.required }}</div>
                                    }
                                  </div>
                                  }
                                </div>
                              </div>
                              <div class="col">
                                <div class="mb-3">
                                  <label for="">Min kWh <span class="required">*</span></label>
                                  <input type="text" class="form-control" formControlName="minKwh">
                                  @if((submitted || group.get('minKwh')?.touched)&& group.get('minKwh')?.errors) {
                                  <div class="invalid-feedback">
                                    @if(group.get('minKwh')?.errors?.['required']) {
                                    <div>{{ utility_electricity_Validations.minKwh.required }}</div>
                                    }
                                    @if(group.get('minKwh')?.errors?.['pattern']) {
                                    <div>{{utility_electricity_Validations.minKwh.patternValidation}}</div>}
                                  </div>
                                  }
                                </div>
                              </div>
                              <div class="col">
                                <div class="mb-3">
                                  <label for="">Max kWh<span class="required">*</span></label>
                                  <input type="text" class="form-control" formControlName="maxKwh">
                                  @if((submitted|| group.get('maxKwh')?.touched) && group.get('maxKwh')?.errors) {
                                  <div class="invalid-feedback">
                                    @if(group.get('maxKwh')?.errors?.['required']) {
                                    <div>{{ utility_electricity_Validations.maxKwh.required }}</div>
                                    }
                                    @if(group.get('maxKwh')?.errors?.['pattern']) {
                                    <div>{{utility_electricity_Validations.maxKwh.patternValidation}}</div>}
                                  </div>
                                  }
                                </div>
                              </div>
                              <div class="col">
                                <div class="mb-3 d-flex align-items-center justify-content-between">
                                  <div class="w-100">
                                    <label for="">Slab Per Unit Price <span class="required">*</span></label>
                                    <input type="text" class="form-control" formControlName="unitPrice">
                                    @if((submitted || group.get('unitPrice')?.touched) &&
                                    group.get('unitPrice')?.errors) {
                                    <div class="invalid-feedback">
                                      @if(group.get('unitPrice')?.errors?.['required']) {
                                      <div>{{ utility_electricity_Validations.UnitPrice.required }}</div>
                                      }
                                      @if(group.get('unitPrice')?.errors?.['pattern']) {
                                      <div>{{utility_electricity_Validations.UnitPrice.patternValidation}}</div>}
                                    </div>
                                    }
                                  </div>
                                  <button type="button" *ngIf="j >= 0&&electricityArray.controls.length>1"
                                    class="btn btn-danger ms-2 mt-4 px-1 py-1" (click)="removeElectricity(j)">
                                    <i class="mdi mdi-trash-can d-block font-size-16"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="d-flex justify-content-end mb-3">
                            <button type="button" class="btn btn-primary" (click)="addElectricity()"> Add Row</button>
                          </div>

                          <div class="col-lg-6 mb-3">
                            <label for="basicpill-declaration-input">Upload Electricity Document</label>

                            <div class="upload-container">
                              <!-- Kendo Upload Component -->
                              <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl"
                                (success)="onSuccess()" (upload)="uploadEleFileEventHandler($event)"
                                (remove)="removeEleFileEventHandler($event)" formControlName="ElectricityFile"
                                id="ElectricityFile" [batch]="false" [multiple]="false">
                              </kendo-upload>

                              <!-- Download Button -->
                              <button type="button"
                                *ngIf="getUtilitiesData?.electricity&&getUtilitiesData?.electricity[0]?.electricityFile?.fileUrl"
                                class="btn btn-icon" title="Download"
                                (click)="downloadFile(getUtilitiesData?.electricity[0]?.electricityFile?.fileUrl,getUtilitiesData?.electricity[0]?.electricityFile?.fileName)"
                                style="margin-left: 10px;">
                                <i class="fa fa-download"></i>
                              </button>
                            </div>
                          </div>
                        </accordion-group>
                      </accordion>
                      <div class="d-flex justify-content-end">
                        <button [disabled]="isUploading" type="submit" class="btn btn-primary" (click)="onSave()">
                          {{ getUtilitiesData ? 'Update' : 'Save' }}
                        </button>
                      </div>
                    </div>
                  </form>


                </section>


                }
              </cdk-step>

              <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                  <span class="number">2.</span>
                  <span>Utility Measuring Device (UMD)</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 1){
                <section class="">
                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>

                  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="showTooltip($event)">
                    <kendo-grid [data]="umdgridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
                      [selectable]="selectableSettings" [pageable]="{
                                                buttonCount: buttonCount,
                                                info: info,
                                                type: type,
                                                pageSizes: pageSizes,
                                                previousNext: previousNext
                                              }" [reorderable]="true" filterable="menu" [filter]="umd_state.filter"
                      [sort]="umd_state.sort" [columnMenu]="{ filter: true }" (filterChange)="onUmdFilterChange($event)"
                      (sortChange)="onUmdSortChange($event)" [resizable]="true" scrollable="scrollable"
                      (pageChange)="umdPageChange($event)" (selectionChange)="selectionChanged($event)"
                      (dataStateChange)="dataStateChange($event)">

                      <ng-template kendoGridToolbarTemplate>
                        <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                          [(ngModel)]="umdSearchedValue" (valueChange)="onUmdFilter($event)"></kendo-textbox> -->
                        <div class="search_icon">
                          <kendo-textbox [style.width.px]="250" [clearButton]="true"
                            (valueChange)="UmdValueChange($event)" [style.height.px]="35" [(ngModel)]="umdSearchedValue"
                            placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary btn-md border-left-none" (click)="onUmdFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                        <kendo-grid-spacer></kendo-grid-spacer>
                        <div class="sm-device d-flex justify-content-between">
                          <button class="btn btn-outline-primary btn-sm me-2" (click)="add_UMD()">Add UMD </button>
                          <button class="btn btn-outline-primary btn-sm me-2" (click)="openBulkUpload()">Bulk Upload
                          </button>
                          <!-- <button class="btn btn-light me-2" (click)="onUmdPdfExport('Pdf')">
                          <span class="fas fa-file-pdf "> </span> &nbsp; Export to PDF</button> -->
                          <button class="btn btn-outline-primary btn-sm" (click)="onUmdPdfExport('Excel')">
                            <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                          </button>
                        </div>

                      </ng-template>
                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                        class="no-tooltip"></kendo-grid-checkbox-column>

                      <kendo-grid-column field="utility.name" title="Utility"></kendo-grid-column>
                      <kendo-grid-column field="supplierName" title="Supplier Name">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.supplierName !='' ? dataItem.supplierName :'-' }}
                        </ng-template>

                      </kendo-grid-column>
                      <kendo-grid-column field="meterSerialNo" title="Device Sr No"></kendo-grid-column>
                      <kendo-grid-column field="macAddress" title="MAC Address"></kendo-grid-column>
                      <kendo-grid-column field="status" title="Meter Status"></kendo-grid-column>
                      <!-- <kendo-grid-column title="Meter Status">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        {{ dataItem.isActive ? 'Active' : 'Inactive' }}
                      </ng-template>
                    </kendo-grid-column> -->
                      <kendo-grid-command-column title="" [columnMenu]="false" [width]="90">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="d-flex">
                            <!-- Edit Umd Icon -->
                            <span kendoTooltip title="Edit Umd" (click)="editUmd(dataItem)" style="cursor: pointer;"
                              class="me-3">
                              <i class="fas fa-edit text-primary"></i>
                            </span>

                            <!-- Delete Umd Icon -->
                            <span kendoTooltip title="Delete Umd" (click)="deleteUmd(dataItem)"
                              style="cursor: pointer;">
                              <i class="fas fa-trash-alt text-danger"></i>
                            </span>
                          </div>
                        </ng-template>
                      </kendo-grid-command-column>



                    </kendo-grid>
                  </div>

                  <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                    <kendo-grid
                      [kendoGridBinding]="mySelectionUmd.length>0 ? mySelectionUmd:allUmdList.length>0?allUmdList:umdgridData.data"
                      #umdGrid>
                      <kendo-grid-column field="utility.name" title="Utility"></kendo-grid-column>
                      <kendo-grid-column field="supplierName" title="Supplier Name"></kendo-grid-column>
                      <kendo-grid-column field="meterSerialNo" title="Device Sr No"></kendo-grid-column>
                      <kendo-grid-column field="macAddress" title="Mac Address"></kendo-grid-column>
                      <kendo-grid-column field="status" title="Meter Status"></kendo-grid-column>
                      <kendo-grid-pdf fileName="Umd.pdf" [allPages]="true" paperSize="A4"
                        [landscape]="true"></kendo-grid-pdf>
                      <kendo-grid-excel fileName="Umd.xlsx"></kendo-grid-excel>
                    </kendo-grid>
                  </div>



                </section>
                }
              </cdk-step>

              <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                  <span class="number">3.</span>
                  <span>Electricity Status</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 2){
                <section class="">

                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>

                  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="showTooltip($event)">
                    <kendo-grid [data]="electric_city_gridData" [pageSize]="electricity_pageSize" [skip]="skip"
                      [sortable]="true" [selectable]="selectableSettings" [pageable]="{
                                                                buttonCount: buttonCount,
                                                                info: info,
                                                                type: type,
                                                                pageSizes: pageSizes,
                                                                previousNext: previousNext
                                                              }" [reorderable]="true" filterable="menu"
                      [columnMenu]="{ filter: true }" [resizable]="true" scrollable="scrollable"
                      [filter]="electricityState.filter" [skip]="skip" [sort]="electricityState.sort"
                      [columnMenu]="{ filter: true }" (filterChange)="onElectricityFilterChange($event)"
                      (sortChange)="onElectricitySortChange($event)" (pageChange)="electricCityPageChange($event)"
                      (selectionChange)="electric_selectionChanged($event)"
                      (dataStateChange)="electric_dataStateChange($event)">

                      <ng-template kendoGridToolbarTemplate>
                        <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                          [(ngModel)]="electriCitySearch" (valueChange)="onElectricFilter($event)"></kendo-textbox> -->
                        <div class="search_icon">
                          <kendo-textbox [style.width.px]="250" [clearButton]="true"
                            (valueChange)="ElectricityStatusValueChange($event)" [style.height.px]="35"
                            [(ngModel)]="electriCitySearch" placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary border-left-none" (click)="onElectricFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                        <kendo-grid-spacer></kendo-grid-spacer>
                        <div class="sm-device d-flex justify-content-between">
                          <!-- <button class="btn btn-light me-2" (click)="onElectricCityPdfExport('Pdf')">
                          <span class="fas fa-file-pdf "> </span> &nbsp; Export to PDF</button> -->
                          <button class="btn btn-outline-primary btn-sm" (click)="onElectricCityPdfExport('Excel')">
                            <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                          </button>
                        </div>

                      </ng-template>
                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                        class="no-tooltip"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="macAddress" title="Mac Address"></kendo-grid-column>
                      <kendo-grid-column field="MeterSerialNo" title="Device Sr No"></kendo-grid-column>
                      <kendo-grid-column field="ebDgShiftStatus" title="Eb Dg Shift Status"></kendo-grid-column>
                      <kendo-grid-column field="ebDgShiftValue" title="Eb Dg Shift values"></kendo-grid-column>
                      <!-- <kendo-grid-column field="date" title="Date" format="{0:dd MMM yyyy}"></kendo-grid-column> -->

                      <kendo-grid-column field="date" title="Date" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.date | date: 'dd MMM yyyy hh:mm a'}}
                        </ng-template>

                      </kendo-grid-column>
                    </kendo-grid>
                  </div>
                  <!--master download buttons start-->
                  <!-- <kendo-excelexport
                    [data]="myElectricTable_SelectionUmd.length>0 ? myElectricTable_SelectionUmd:electric_city_gridData.data"
                    fileName="Electricity.xlsx" #electricexcelexport>
                    <kendo-excelexport-column field="macAddress" title="Mac Address"></kendo-excelexport-column>
                    <kendo-excelexport-column field="ebDgShiftStatus"
                      title="Eb Dg Shift Status"></kendo-excelexport-column>
                    <kendo-excelexport-column field="ebDgShiftValue"
                      title="Eb Dg Shift values"></kendo-excelexport-column>
                    <kendo-excelexport-column field="date" title="Date"
                      format="{0:dd-MM-yyyy}"></kendo-excelexport-column>
                  </kendo-excelexport> -->
                  <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                    <kendo-grid
                      [kendoGridBinding]="myElectricTable_SelectionUmd.length>0 ? myElectricTable_SelectionUmd:allElectricityList.length>0?allElectricityList:electric_city_gridData.data"
                      #electricGrid>
                      <kendo-grid-column field="macAddress" title="Mac Address"></kendo-grid-column>
                      <kendo-grid-column field="MeterSerialNo" title="Device Sr No"></kendo-grid-column>
                      <kendo-grid-column field="ebDgShiftStatus" title="Eb Dg Shift Status"></kendo-grid-column>
                      <kendo-grid-column field="ebDgShiftValue" title="Eb Dg Shift values"></kendo-grid-column>
                      <!-- <kendo-grid-column field="date" title="Date" format="{0:dd-MM-yyyy}"></kendo-grid-column> -->
                      <kendo-grid-column field="date" title="Date" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.date | date: 'dd MMM yyyy hh:mm a'}}
                        </ng-template>

                      </kendo-grid-column>
                      <kendo-grid-pdf fileName="Electricity.pdf" [allPages]="true" paperSize="A4"
                        [landscape]="true"></kendo-grid-pdf>
                      <kendo-grid-excel fileName="Electricity.xlsx"></kendo-grid-excel>
                    </kendo-grid>
                  </div>




                </section>
                }
              </cdk-step>

            </ng-stepper>
          </div>

        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>

</div>


<!-- Add UMD modal -->

<!-- Add UMD modal End  -->