import { createAction, props } from '@ngrx/store';
import { billSettingModel } from './billSettings.model';

// fetch  bills setting
export const getBillSettingslists = createAction(
  '[Bill Setting] billSetting ',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    projectId: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getBilSettingSuccess = createAction(
  '[Bill Seeting] fetch bills success',
  props<{ getBillSettingResponse: any }>()
);
export const getBillSettingFailure = createAction(
  '[Bill Seting] fetch bills failed]',
  props<{ getBillSettingError: any }>()
);

export const getAllBillSettingslists = createAction(
  '[Bill Setting]All billSetting ',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    projectId: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllBilSettingSuccess = createAction(
  '[Bill Seeting] fetch All bills success',
  props<{ getAllBillSettingResponse: any }>()
);
export const getAllBillSettingFailure = createAction(
  '[Bill Seting] fetch All bills failed]',
  props<{ getAllBillSettingError: any }>()
);

// fetch  invloice addsI
export const getBillinvloicelist1 = createAction(
  '[Bill Setting] Invoice',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    orderBy?: string;
    filter?: string;
  }>()
);
export const getBilinvloiceuccess = createAction(
  '[Bill Seeting] fetch bills success',
  props<{ getBillinvloiceResponse: any }>()
);
export const getBillinvloiceFailure = createAction(
  '[Bill Seting] fetch bills failed]',
  props<{ getBillinvloiceError: any }>()
);

// fetch  Allinvloice addsI
export const getAllBillinvloicelist1 = createAction(
  '[Bill Setting] AllInvoice',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    projectId: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllBilinvloiceuccess = createAction(
  '[Bill Seeting] Allfetch bills success',
  props<{ getAllBillinvloiceResponse: any }>()
);
export const getAllBillinvloiceFailure = createAction(
  '[Bill Seting] Allfetch bills failed]',
  props<{ getAllBillinvloiceError: any }>()
);

// fetch  Optional adds
export const getOptionallist = createAction(
  '[Optional] Optional ',
  props<{ projectId: string }>()
);
export const getOptionalSuccess = createAction(
  '[Optional] Optional bills success',
  props<{ getOptionalResponse: any }>()
);
export const getOptionalFailure = createAction(
  '[Optional] Optional bills failed]',
  props<{ getOptionalError: any }>()
);

//create  Billls  charges
export const createBillCharge = createAction(
  '[Bill Charge] CreateBillCharge',
  props<{ billCharge: any }>()
);
export const addBillChargeSuccess = createAction(
  '[Bill Charge] BillCharge Success',
  props<{ createBillChargeResponse: any }>()
);
export const addBillChargeFailure = createAction(
  '[Bill Charge] BillCharge Failure',
  props<{ createBillChargeError: any }>()
);

//create Optional Billls  charges
export const createOptionalBillCharge = createAction(
  '[OptionalBill Charge] CreateOptionalBillCharge',
  props<{ OptionalbillCharge: any }>()
);
export const addOptionalBillChargeSuccess = createAction(
  '[OptionalBill Charge] OptionalBillCharge Success',
  props<{ createOptionalBillChargeResponse: any }>()
);
export const addOptionalBillChargeFailure = createAction(
  '[OptionalBill Charge] OptionalBillCharge Failure',
  props<{ createOptionalBillChargeError: any }>()
);

//create Invoice
export const createInvoice = createAction(
  '[Invoice ] CreateInvoice',
  props<{ Invoice: any; projectId: string }>()
);
export const addInvoiceSuccess = createAction(
  '[Invoice ]Invoice Success',
  props<{ createInvoiceResponse: any }>()
);
export const addInvoiceFailure = createAction(
  '[Invoice ]Invoice Failure',
  props<{ createInvoiceError: any }>()
);

// Update  Invoice Data
export const updateInvoice = createAction(
  '[Invoice ] Update Invoice',
  props<{ UpdateInvoice: any; id: string; projectId: string }>()
);
export const updateInvoiceSuccess = createAction(
  '[Invoice] Update Invoice Success',
  props<{ updateInvoiceResponse: any }>()
);
export const updateInvoiceFailure = createAction(
  '[Invoice] Update Invoice Failure',
  props<{ updateInvoiceError: any }>()
);

// Update  Optional Billls Data
export const updateOptionalBillCharge = createAction(
  '[OptionalBillCharge ] Update OptionalBillCharge',
  props<{ UpdateOptionalBillCharge: any }>()
);
export const updateOptionalBillChargeSuccess = createAction(
  '[OptionalBillCharge] Update OptionalBillCharge Success',
  props<{ updateOptionalBillChargeResponse: any }>()
);
export const updateOptionalBillChargeFailure = createAction(
  '[OptionalBillCharge] Update OptionalBillCharge Failure',
  props<{ updateOptionalBillChargeError: any }>()
);

// Update Data
export const updateBillCharge = createAction(
  '[BillCharge ] Update BillCharge',
  props<{ UpdateBillCharge: any; id: string }>()
);
export const updateBillChargeSuccess = createAction(
  '[BillCharge] Update BillCharge Success',
  props<{ updateBillChargeResponse: any }>()
);
export const updateBillChargeFailure = createAction(
  '[BillCharge] Update BillCharge Failure',
  props<{ updateBillChargeError: any }>()
);

// Delete Invoice Charge
export const deleteInvoice = createAction(
  '[Invoice] Delete Invoice',
  props<{ id: string }>()
);
export const deleteInvoiceSuccess = createAction(
  '[Invoice] Delete Invoice Success',
  props<{ deleteInvoiceResponse: any }>()
);
export const deleteInvoiceFailure = createAction(
  '[Invoice] Delete Invoice Failure',
  props<{ deleteInvoiceError: any }>()
);

// Delete bill Charge
export const deleteBillCharge = createAction(
  '[BillCharge] Delete BillCharge',
  props<{ id: string }>()
);
export const deleteBillChargeSuccess = createAction(
  '[BillCharge] Delete BillCharge Success',
  props<{ deleteBillChargeResponse: any }>()
);
export const deleteBillChargeFailure = createAction(
  '[BillCharge] Delete BillCharge Failure',
  props<{ deleteBillChargeError: any }>()
);
//store reset
export const resetState = createAction('[App] Reset State');
export const resetInvoiceState = createAction(
  '[resetInvoiceState ] resetInvoiceState'
);
