
import {
  GetAllmeter,
  Getmeter,
  resetState,
  updateMeterStatus,
} from './../../../store/meters/meters.actions';

import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { Store, select } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';

import {
  process,
  State,
  toODataString,
  SortDescriptor,
} from '@progress/kendo-data-query';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';

import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
  getAllmeterConfigResponse,
  getmeterConfigResponse,
  updateMeterStatusResponse,
} from '../../../store/meters/meters-selector';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { table_pageSize } from '../../../shared/constants/templatesNames';
import { SweetAlertIcon, UtilityType } from '../../../shared/constants/enum';
import {
  GetAllGasRateData,
  GetEvData,
  GetGasRateData,
  resetUnitRateHistoryState,
} from '../../../store/Utilities/utilities.actions';
import { CdkStepper } from '@angular/cdk/stepper';
import {
  getAllGasRateDataResponse,
  getEVDataResponse,
  getGasRateDataResponse,
} from '../../../store/Utilities/utilities.selector';
import { SelectionChange } from '@angular/cdk/collections';
import { Utilites } from '../../../store/Utilities/utilities.models';
import { Wallet } from '../../../shared/components/wallet-history-expended/wallet-history-expended.component';
import {
  GetWaterRateData,
  GetElectricityRateData,
} from '../../../store/Utilities/utilities.actions';
import {
  getWaterRateDataResponse,
  getElectricityRateDataResponse,
} from '../../../store/Utilities/utilities.selector';
import { reverse } from 'dns';

@Component({
  selector: 'app-rp-unit_rate_history',
  templateUrl: './rp-unit_rate_history.component.html',
  styleUrl: './rp-unit_rate_history.component.scss',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .k-grid .k-grid-content td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `,
  ],
})
export class RpUnitRateHistoryComponent {
  @ViewChild('cdkStepper') stepper: CdkStepper | undefined;
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  @ViewChild('waterGrid') public waterGridhiddenGrid: GridComponent | undefined;
  @ViewChild('electGrid') public electGridhiddenGrid: GridComponent | undefined;
  @ViewChild('evGrid') public evGridhiddenGrid: GridComponent | undefined;
  // @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  public selectableSettings: SelectableSettings = {
    mode: 'multiple',
    checkboxOnly: true,
  };
  breadCrumbItems: Array<{}> | undefined;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';
  selectValue!: string[];
  public gasState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  currentUser: any;
  projectId: any;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public gasPageSize = 10;
  public waterPageSize = 10;
  public electricPageSize = 10;
  public skip = 0;
  public waterskip = 0;
  public electricskip = 0;
  public evskip = 0;
  totalGasCount: number = 0;
  totalWaterCount: number = 0;
  totalElectricCount: number = 0;
  currentPage: number = 1;
  waterCurrentPage: number = 1;
  electricCurrentPage: number = 1;
  evCurrentPage: number = 1;

  public evPageSize: number = 1;
  gasDataList: any[] = [];
  waterDataList: any[] = [];
  electricDataList: any[] = [];
  public sort: SortDescriptor[] = [];
  public gasGridData: GridDataResult = { data: [], total: 0 };
  waterGridData: GridDataResult = { data: [], total: 0 };
  ElectricityGridData: GridDataResult = { data: [], total: 0 };
  EvGridData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public waterState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };


  public electricState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  //public gasPageSizes = true;
  public previousNext = true;
  mySelectionGas: any = [];
  mySelectionWater: any = [];
  gasOrderByQuery: string = '';
  gasQueryString: string = '';
  gasSearchedValue: any = '';
  allgasDataList: any[] = [];

  utilitiesData: any;
  selectedIndex: number = 0;
  waterQueryString: string = '';
  waterOrderByQuery: string = '';
  electricQueryString: string = '';
  evQueryString: string = '';
  electricOrderByQuery: string = '';
  evOrderByQuery: string = '';
  mySelectionElectric: any = [];
  mySelectionEv: any = [];
  utilityType = UtilityType;
  selectedUtility: string = '';
  hasGas: boolean = false;
  hasWater: boolean = false;
  hasElectricity: boolean = false;
  hasEv: boolean = false;
  allMeterconfigList: any = [];
  currentprojectUtilities: any;
  selectedUtilityId: any;
  evDataList: any = [];
  totalEvCount: number = 0
  constructor(
    private store: Store,
    private sharedService: SharedService,
    // private router: Router,
    private PdfexportService: PdfexportService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.currentprojectUtilities = this.currentUser.projectUtilities
    this.projectId = this.currentUser?.project.id;
    this.getStoreInfo();
    this.breadCrumbItems = [{ label: 'Unit rate history' }];
  }
  private exportSubscription: Subscription = new Subscription();
  getStoreInfo() {
    let getGasRateDataResponse$ = this.store.pipe(
      select(getGasRateDataResponse)
    );

    let getWaterRateDataResponse$ = this.store.pipe(
      select(getWaterRateDataResponse)
    );

    let getElectricityRateDataResponse$ = this.store.pipe(
      select(getElectricityRateDataResponse)
    );
    let getEvDataResponse$ = this.store.pipe(
      select(getEVDataResponse)
    );
    this.exportSubscription = this.store
      .pipe(select(getAllGasRateDataResponse))
      .subscribe((res: any) => {
        if (res) {
          this.allMeterconfigList = [];
          if (res.items.length > 0) {
            this.allMeterconfigList = [...res.items];

            const transformedData = this.allMeterconfigList
              .map((item: any) => {
                const dataObj: any = {};

                if (
                  item.utilityName !== null &&
                  item.utilityName !== undefined
                ) {
                  dataObj['Utility Name'] = item.utilityName;
                }
                if (
                  item.slabName !== null &&
                  item.slabName !== undefined &&
                  item.slabName !== ''
                ) {
                  dataObj['Slab Name'] = item.slabName;
                }

                if (item.maxValue !== null && item.maxValue !== undefined) {
                  dataObj['Max Value'] = item.maxValue;
                }
                if (item.minValue !== null && item.minValue !== undefined) {
                  dataObj['Min Value'] = item.minValue;
                }

                if (item.gasValue !== null && item.gasValue !== undefined && item.utilityName == 'Gas') {
                  dataObj['Gas Value'] = item.gasValue;
                }

                if (item.unitLPGValue !== null && item.unitLPGValue !== undefined && item.utilityName == 'Gas') {
                  dataObj['1 KG/ LPG'] = item.unitLPGValue;
                }

                // if (item.fromDate && item.toDate) {
                //   const fromDate = new Date(item.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
                //   const toDate = new Date(item.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
                //   dataObj['From and To Date'] = `${fromDate} - ${toDate}`;
                // } else {
                //   dataObj['From and To Date'] = 'N/A'; // Handle missing dates
                // }
                if (item.fromDate) {
                  const fromDate = new Date(item.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
                  dataObj['From Date'] = fromDate;
                } else {
                  dataObj['From Date'] = 'N/A'; // Handle missing fromDate
                }

                if (item.toDate) {
                  const toDate = new Date(item.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
                  dataObj['To Date'] = toDate;
                } else {
                  dataObj['To Date'] = 'Till now'; // Handle missing toDate
                }

                if (item.unitPrice !== null && item.unitPrice !== undefined) {
                  dataObj['Unit Price'] = item.unitPrice;
                }

                // Return only if 'Project Name' exists
                return Object.keys(dataObj).length > 0 ? dataObj : null;
              })
              .filter((item: any) => item !== null); // Filter out any null entries

            this.allMeterconfigList = [];

            // Check if there's data to export after filtering null values
            if (transformedData.length > 0) {
              this.PdfexportService.downloadExcel(
                transformedData,
                transformedData[0].UtilityName
              );
            } else {
              this.sharedService.showMessageDialog(
                'No valid data to export',
                '',
                SweetAlertIcon.INFO
              );
            }
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.INFO
            );
          }
        }
      });

    let updateMeterStatusResponse$ = this.store.pipe(
      select(updateMeterStatusResponse)
    );
    getGasRateDataResponse$.subscribe((res: any) => {
      if (res) {
        if (res) {
          // if (this.selectedUtility == this.utilityType.GAS) {
          this.gasDataList = [...res.items];
          this.gasPageSize = res.pageSize;
          this.totalGasCount = res.totalCount;
          this.selectedUtility = this.utilityType.GAS;
          // } else if (this.selectedUtility == this.utilityType.WATER) {
          //   this.waterDataList = [...res.items];
          //   this.waterPageSize = res.pageSize;
          //   this.totalWaterCount = res.totalCount;
          //   this.selectedUtility = this.utilityType.WATER;
          // } else if (this.selectedUtility == this.utilityType.ELECTRICITY) {
          //   this.electricDataList = [...res.items];
          //   this.electricPageSize = res.pageSize;
          //   this.totalElectricCount = res.totalCount;
          //   this.selectedUtility = this.utilityType.ELECTRICITY;
          // }
        }
      }
      this.loadGasConfig();
      console.log('MetersConfiguration', res);
    });

    getWaterRateDataResponse$.subscribe((res: any) => {
      if (res) {
        if (res) {
          if (this.selectedUtility == this.utilityType.WATER) {
            this.waterDataList = [...res.items];
            this.waterPageSize = res.pageSize;
            this.totalWaterCount = res.totalCount;
            this.selectedUtility = this.utilityType.WATER;
          }
        }
      }
      this.loadGasConfig();
      console.log('MetersConfiguration', res);
    });

    getElectricityRateDataResponse$.subscribe((res: any) => {
      if (res) {
        if (res) {
          if (this.selectedUtility == this.utilityType.ELECTRICITY) {
            this.electricDataList = [...res.items];
            this.electricPageSize = res.pageSize;
            this.totalElectricCount = res.totalCount;
            this.selectedUtility = this.utilityType.ELECTRICITY;
          }
        }
      }
      this.loadGasConfig();
      console.log('MetersConfiguration', res);
    });

    getEvDataResponse$.subscribe((res: any) => {
      if (res) {
        if (res) {
          if (this.selectedUtility == this.utilityType.EV) {
            this.evDataList = [...res.items];
            this.evPageSize = res.pageSize;
            this.totalEvCount = res.totalCount;
            this.selectedUtility = this.utilityType.EV;
          }
        }
      }
      this.loadGasConfig();
      console.log('MetersConfiguration', res);
    });



    updateMeterStatusResponse$.subscribe((res: any) => {
      if (res) {
        this.gasDataList = this.gasDataList.map((meter) => {
          if (meter.meterId === res.data.id) {
            return {
              ...meter,
              onRequest: res.data.onRequest,
              onRequestDate: res.data.onRequestDate,
              offRequest: res.data.offRequest,
              offRequestDate: res.data.offRequestDate,
            };
          }
          return meter;
        });
        // this.gasDataList = [...res.items];
        // this.gasPageSize = res.gasPageSize;
        // this.totalGasCount = res.totalCount;
      }
      this.loadGasConfig();
      console.log('MetersConfiguration', res);
    });
  }

  isUtilityDisplay(type: string): boolean {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.utilitiesData = this.currentUser.projectUtilities.reverse();
    const utility = this.utilitiesData.find((u: any) => u.name === type);
    return !!utility; // Returns true if 'Electricity' is found, false otherwise
  }

  downloadFile(file: string, fileName: string) {
    this.sharedService.downloadUtilityFiles(file, fileName);
  }

  ngOnInit(): void {
    // this.getAllConfigData();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.utilitiesData = this.currentUser.projectUtilities.reverse();

  }

  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((res) => {
      if (
        res.statusCode == 200 &&
        res.message == 'Config data retrieved successfully.'
      ) {
        this.utilitiesData = res.data.utilities.reverse();
        // this.onStepChange({ selectedIndex: 0 });
        // this.getListGasUnitRateData();
        // this.getGasUnitRateData();
      }
    });
  }
  ngAfterViewInit() {
    this.hasGas = this.isUtilityDisplay('Gas');
    this.hasWater = this.isUtilityDisplay('Water');
    this.hasElectricity = this.isUtilityDisplay('Electricity');
    this.hasEv = this.isUtilityDisplay('EV');
    this.onStepChange({ selectedIndex: 0 });
  }

  // getUtilityIndex(type: string): number {
  //   this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
  //   const utilityIndex = this.currentUser.projectUtilities.findIndex(
  //     (u: any) => u.name === type
  //   );
  //   return utilityIndex + 1; // Returns the index if 'Electricity' is found, -1 otherwise
  // }

  getUtilityIndex(type: string): number {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');

    // Get the list of available utilities
    const availableUtilities = this.currentUser?.projectUtilities || [];

    // Create an ordered array of utility names for reference
    const orderedUtilityNames = ['Gas', 'Water', 'Electricity'];

    // Sort the available utilities based on the pre-defined order
    const sortedUtilities = availableUtilities
      .filter((u: any) => orderedUtilityNames.includes(u.name)) // Ensure valid utilities are considered
      .sort((a: any, b: any) => orderedUtilityNames.indexOf(a.name) - orderedUtilityNames.indexOf(b.name));

    // Find the index of the requested utility (e.g., 'Gas', 'Water', 'Electricity')
    const utilityIndex = sortedUtilities.findIndex((u: any) => u.name === type);

    // If the utility exists in the sorted list, return its position (1-based index)
    if (utilityIndex !== -1) {
      return utilityIndex + 1;
    }

    // If the utility isn't found, return 0 or handle appropriately
    return 0;
  }


  ngOnDestroy() {
    this.store.dispatch(resetUnitRateHistoryState());
    this.exportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  onGasSortChange(sort: SortDescriptor[]): void {
    // if (this.selectedIndex == 0) {
    //   this.gasState.sort = sort;
    //   console.log(this.gasState.sort);
    //   const gasOrderByQuery = this.sharedService.createOrderByQuery(
    //     this.gasState.sort
    //   );
    //   this.gasOrderByQuery = gasOrderByQuery;
    // } else if (this.selectedIndex == 1) {
    //   this.waterState.sort = sort;
    //   const gasOrderByQuery = this.sharedService.createOrderByQuery(
    //     this.waterState.sort
    //   );
    //   this.waterOrderByQuery = gasOrderByQuery;
    // } else {
    //   this.electricState.sort = sort;
    //   const gasOrderByQuery = this.sharedService.createOrderByQuery(
    //     this.electricState.sort
    //   );
    //   this.electricOrderByQuery = gasOrderByQuery;
    // }




    if (this.selectedUtility == UtilityType.GAS) {
      this.gasState.sort = sort;
      console.log(this.gasState.sort);
      const gasOrderByQuery = this.sharedService.createOrderByQuery(
        this.gasState.sort
      );
      this.gasOrderByQuery = gasOrderByQuery;
    } else if (this.selectedUtility == UtilityType.WATER) {
      this.waterState.sort = sort;
      const gasOrderByQuery = this.sharedService.createOrderByQuery(
        this.waterState.sort
      );
      this.waterOrderByQuery = gasOrderByQuery;
    } else if (this.selectedUtility == UtilityType.ELECTRICITY) {
      this.electricState.sort = sort;
      const gasOrderByQuery = this.sharedService.createOrderByQuery(
        this.electricState.sort
      );
      this.electricOrderByQuery = gasOrderByQuery;
    }
    else if (this.selectedUtility == UtilityType.EV) {
      this.electricState.sort = sort;
      const gasOrderByQuery = this.sharedService.createOrderByQuery(
        this.electricState.sort
      );
      this.evOrderByQuery = gasOrderByQuery;
    }






    this.getGasUnitRateData();
  }

  public onGasRateFilterChange(filter: CompositeFilterDescriptor): void {
    this.skip = 0
    // if (this.selectedIndex == 0) {
    //   this.gasState.filter = filter;
    //   console.log(this.gasState);
    //   const gasQueryString = this.sharedService.createQuery(this.gasState);
    //   this.gasQueryString = this.sharedService.createQuery(this.gasState);
    // } else if (this.selectedIndex == 1) {
    //   this.waterState.filter = filter;
    //   console.log(this.waterState);
    //   const gasQueryString = this.sharedService.createQuery(this.waterState);
    //   this.waterQueryString = this.sharedService.createQuery(this.waterState);
    // } else {
    //   this.electricState.filter = filter;
    //   console.log(this.waterState);
    //   const gasQueryString = this.sharedService.createQuery(this.electricState);
    //   this.electricQueryString = this.sharedService.createQuery(
    //     this.electricState
    //   );
    // }


    if (this.selectedUtility == UtilityType.GAS) {
      this.gasState.filter = filter;
      console.log(this.gasState);
      const gasQueryString = this.sharedService.createQuery(this.gasState);
      this.gasQueryString = this.sharedService.createQuery(this.gasState);
    } else if (this.selectedUtility == UtilityType.WATER) {
      this.waterState.filter = filter;
      console.log(this.waterState);
      const gasQueryString = this.sharedService.createQuery(this.waterState);
      this.waterQueryString = this.sharedService.createQuery(this.waterState);
    } else if (this.selectedUtility == UtilityType.ELECTRICITY) {
      this.electricState.filter = filter;
      console.log(this.waterState);
      const gasQueryString = this.sharedService.createQuery(this.electricState);
      this.electricQueryString = this.sharedService.createQuery(
        this.electricState
      );
    }
    else if (this.selectedUtility == UtilityType.EV) {
      this.electricState.filter = filter;
      console.log(this.waterState);
      const gasQueryString = this.sharedService.createQuery(this.electricState);
      this.evQueryString = this.sharedService.createQuery(
        this.electricState
      );
    }







    this.getGasUnitRateData();
  }

  // getGasUnitRateData(): void {
  //   if (this.stepper) {
  //     const selectedUtility = this.utilitiesData[this.selectedIndex];
  //     const utilityMasterId = selectedUtility ? selectedUtility.id : '';
  //     let selectedFilterQuery,
  //       selectedOrderQuery,
  //       paseSize = 0,
  //       currentPage = 0;
  //     if (this.selectedIndex == 0) {
  //       selectedFilterQuery = this.gasQueryString;
  //       selectedOrderQuery = this.gasOrderByQuery;
  //       paseSize = this.gasPageSize;
  //       currentPage = this.currentPage;
  //     } else if (this.selectedIndex == 1) {
  //       selectedFilterQuery = this.waterQueryString;
  //       selectedOrderQuery = this.waterOrderByQuery;
  //       paseSize = this.waterPageSize;
  //       currentPage = this.waterCurrentPage;
  //     } else {
  //       selectedFilterQuery = this.electricQueryString;
  //       selectedOrderQuery = this.electricOrderByQuery;
  //       paseSize = this.electricPageSize;
  //       currentPage = this.electricCurrentPage;
  //     }
  //     this.store.dispatch(
  //       GetGasRateData({
  //         pageIndex: currentPage,
  //         pageSize: paseSize,
  //         searchTerm: this.gasSearchedValue,
  //         filter: selectedFilterQuery,
  //         orderBy: selectedOrderQuery,
  //         projectId: this.projectId,
  //         utilityMasterId: utilityMasterId,
  //       })
  //     );
  //   } else {
  //     console.error('Stepper is not defined');
  //   }
  // }



  public onValueChange(ev: string): void {

    if (ev.length == 0) {
      this.gasUnitRatePageChange({ skip: 0, take: 10 })
      this.getGasUnitRateData()
    }
  }

  private getGasUnitRateData(): void {
    // const selectedUtility = this.utilitiesData[this.selectedIndex];
    const selectedUtility = this.utilitiesData
      .filter((item: any) => this.selectedUtility === item.name) // Filter to match the selected utility
      .map((item: any) => item.id);
    const utilityMasterId = selectedUtility;
    this.selectedUtilityId = utilityMasterId;
    let selectedFilterQuery,
      selectedOrderQuery,
      paseSize = 0,
      currentPage = 0;
    // if (this.selectedIndex == 0) {
    //   selectedFilterQuery = this.gasQueryString;
    //   selectedOrderQuery = this.gasOrderByQuery;
    //   paseSize = this.gasPageSize;
    //   currentPage = this.currentPage;
    // } else if (this.selectedIndex == 1) {
    //   selectedFilterQuery = this.waterQueryString;
    //   selectedOrderQuery = this.waterOrderByQuery;
    //   paseSize = this.waterPageSize;
    //   currentPage = this.waterCurrentPage;
    // } else {
    //   selectedFilterQuery = this.electricQueryString;
    //   selectedOrderQuery = this.electricOrderByQuery;
    //   paseSize = this.electricPageSize;
    //   currentPage = this.electricCurrentPage;
    // }

    if (this.selectedUtility == UtilityType.GAS) {
      selectedFilterQuery = this.gasQueryString;
      selectedOrderQuery = this.gasOrderByQuery;
      paseSize = this.gasPageSize;
      currentPage = this.currentPage;
    } else if (this.selectedUtility == UtilityType.WATER) {
      selectedFilterQuery = this.waterQueryString;
      selectedOrderQuery = this.waterOrderByQuery;
      paseSize = this.waterPageSize;
      currentPage = this.waterCurrentPage;
    } else if (this.selectedUtility == UtilityType.ELECTRICITY) {
      selectedFilterQuery = this.electricQueryString;
      selectedOrderQuery = this.electricOrderByQuery;
      paseSize = this.electricPageSize;
      currentPage = this.electricCurrentPage;
    }
    else if (this.selectedUtility == UtilityType.EV) {
      selectedFilterQuery = this.evQueryString;
      selectedOrderQuery = this.evOrderByQuery;
      paseSize = this.evPageSize;
      currentPage = this.evCurrentPage;
    }
    switch (this.selectedUtility) {
      case 'Gas':
        this.store.dispatch(
          GetGasRateData({
            pageIndex: 1,
            pageSize: paseSize,
            searchTerm: this.gasSearchedValue,
            filter: selectedFilterQuery,
            orderBy: selectedOrderQuery,
            projectId: this.projectId,
            utilityMasterId: utilityMasterId,
          })
        );

        break;
      case 'Water':
        this.store.dispatch(
          GetWaterRateData({
            pageIndex: 1,
            pageSize: paseSize,
            searchTerm: this.gasSearchedValue,
            filter: selectedFilterQuery,
            orderBy: selectedOrderQuery,
            projectId: this.projectId,
            utilityMasterId: utilityMasterId,
          })
        );
        break;
      case 'Electricity':
        this.store.dispatch(
          GetElectricityRateData({
            pageIndex: 1,
            pageSize: paseSize,
            searchTerm: this.gasSearchedValue,
            filter: selectedFilterQuery,
            orderBy: selectedOrderQuery,
            projectId: this.projectId,
            utilityMasterId: utilityMasterId,
          })
        );
        break;
      case 'EV':
        this.store.dispatch(
          GetEvData({
            pageIndex: 1,
            pageSize: paseSize,
            searchTerm: this.gasSearchedValue,
            filter: selectedFilterQuery,
            orderBy: selectedOrderQuery,
            projectId: this.projectId,
            utilityMasterId: utilityMasterId,
          })
        );
        break;
    }
  }

  private getListGasUnitRateData(): void {
    this.utilitiesData.forEach((element: any, index: number) => {
      this.selectedIndex = index;
      const selectedUtility = this.utilitiesData[this.selectedIndex];
      const utilityMasterId = selectedUtility ? selectedUtility.id : '';
      let selectedFilterQuery,
        selectedOrderQuery,
        paseSize = 0,
        currentPage = 0;
      if (this.selectedIndex == 0) {
        selectedFilterQuery = this.gasQueryString;
        selectedOrderQuery = this.gasOrderByQuery;
        paseSize = this.gasPageSize;
        currentPage = this.currentPage;
      } else if (this.selectedIndex == 1) {
        selectedFilterQuery = this.waterQueryString;
        selectedOrderQuery = this.waterOrderByQuery;
        paseSize = this.waterPageSize;
        currentPage = this.waterCurrentPage;
      } else {
        selectedFilterQuery = this.electricQueryString;
        selectedOrderQuery = this.electricOrderByQuery;
        paseSize = this.electricPageSize;
        currentPage = this.electricCurrentPage;
      }
      switch (element.name) {
        case 'Gas':
          this.store.dispatch(
            GetGasRateData({
              pageIndex: currentPage,
              pageSize: paseSize,
              searchTerm: this.gasSearchedValue,
              filter: selectedFilterQuery,
              orderBy: selectedOrderQuery,
              projectId: this.projectId,
              utilityMasterId: utilityMasterId,
            })
          );

          break;
        case 'Water':
          this.store.dispatch(
            GetWaterRateData({
              pageIndex: currentPage,
              pageSize: paseSize,
              searchTerm: this.gasSearchedValue,
              filter: selectedFilterQuery,
              orderBy: selectedOrderQuery,
              projectId: this.projectId,
              utilityMasterId: utilityMasterId,
            })
          );
          break;
        case 'Electricity':
          this.store.dispatch(
            GetElectricityRateData({
              pageIndex: currentPage,
              pageSize: paseSize,
              searchTerm: this.gasSearchedValue,
              filter: selectedFilterQuery,
              orderBy: selectedOrderQuery,
              projectId: this.projectId,
              utilityMasterId: utilityMasterId,
            })
          );
          break;
      }
    });
  }

  getTotalGasUnitRatesData(): void {
    const selectedUtility = this.utilitiesData[this.selectedIndex];
    const utilityMasterId = this.selectedUtilityId;
    // let selectedFilterQuery,
    //   selectedOrderQuery,
    //   paseSize = 0,
    //   currentPage = 0;
    // if (this.selectedIndex == 0) {
    //   selectedFilterQuery = this.gasQueryString;
    //   selectedOrderQuery = this.gasOrderByQuery;
    //   paseSize = this.totalGasCount;
    // } else if (this.selectedIndex == 1) {
    //   selectedFilterQuery = this.waterQueryString;
    //   selectedOrderQuery = this.waterOrderByQuery;
    //   paseSize = this.totalWaterCount;
    // } else {
    //   selectedFilterQuery = this.electricQueryString;
    //   selectedOrderQuery = this.electricOrderByQuery;
    //   paseSize = this.totalElectricCount;
    // }
    this.store.dispatch(
      GetAllGasRateData({
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
        projectId: this.projectId,
        utilityMasterId: utilityMasterId,
      })
    );

    // this.store.dispatch(
    //   GetAllGasRateData({
    //     pageIndex: 1,
    //     pageSize: this.totalGasCount,
    //     searchTerm: this.gasSearchedValue,
    //     filter: this.gasQueryString,
    //     orderBy: this.gasOrderByQuery,
    //     projectId: this.projectId,
    //     utilityMasterId: utilityMasterId,
    //   })
    // );
  }

  protected gasUnitRatePageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    // if (this.selectedIndex == 0) {
    //   this.mySelectionGas = [];
    //   this.skip = skip;
    //   this.gasPageSize = take;
    //   this.currentPage = Math.floor(skip / take) + 1;
    //   console.log('currentpage', Math.floor(skip / take) + 1);
    // } else if (this.selectedIndex == 1) {
    //   this.mySelectionWater = [];
    //   this.waterskip = skip;
    //   this.waterPageSize = take;
    //   this.waterCurrentPage = Math.floor(skip / take) + 1;
    // } else {
    //   this.mySelectionElectric = [];
    //   this.electricskip = skip;
    //   this.electricPageSize = take;
    //   this.electricCurrentPage = Math.floor(skip / take) + 1;
    // }

    if (this.selectedUtility == UtilityType.GAS) {
      this.mySelectionGas = [];
      this.skip = skip;
      this.gasPageSize = take;
      this.currentPage = Math.floor(skip / take) + 1;
      console.log('currentpage', Math.floor(skip / take) + 1);
    } else if (this.selectedUtility == UtilityType.WATER) {
      this.mySelectionWater = [];
      this.waterskip = skip;
      this.waterPageSize = take;
      this.waterCurrentPage = Math.floor(skip / take) + 1;
    } else if (this.selectedUtility == UtilityType.ELECTRICITY) {
      this.mySelectionElectric = [];
      this.electricskip = skip;
      this.electricPageSize = take;
      this.electricCurrentPage = Math.floor(skip / take) + 1;
    } else if (this.selectedUtility == UtilityType.EV) {
      this.mySelectionEv = [];
      this.evskip = skip;
      this.evPageSize = take;
      this.evCurrentPage = Math.floor(skip / take) + 1;
    }



    this.getGasUnitRateData();
    // this.loadGasConfig();
  }

  public gasSelectionChanged(selection: any) {
    // Handle deselected rows
    // if (this.selectedIndex == 0) {
    //   selection.deselectedRows.forEach((row: any) => {
    //     const index = this.mySelectionGas.findIndex(
    //       (selectedRow: any) => selectedRow.id === row.dataItem.id
    //     );
    //     if (index > -1) {
    //       this.mySelectionGas.splice(index, 1);
    //     }
    //   });

    //   // Handle selected rows
    //   selection.selectedRows.forEach((row: any) => {
    //     if (!this.isGasRowSelected(row.dataItem)) {
    //       this.mySelectionGas.push(row.dataItem);
    //     }
    //   });
    // } else if (this.selectedIndex == 1) {
    //   selection.deselectedRows.forEach((row: any) => {
    //     const index = this.mySelectionWater.findIndex(
    //       (selectedRow: any) => selectedRow.id === row.dataItem.id
    //     );
    //     if (index > -1) {
    //       this.mySelectionWater.splice(index, 1);
    //     }
    //   });
    //   // Handle selected rows
    //   selection.selectedRows.forEach((row: any) => {
    //     if (!this.isWaterRowSelected(row.dataItem)) {
    //       this.mySelectionWater.push(row.dataItem);
    //     }
    //   });
    // } else {
    //   selection.deselectedRows.forEach((row: any) => {
    //     const index = this.mySelectionElectric.findIndex(
    //       (selectedRow: any) => selectedRow.id === row.dataItem.id
    //     );
    //     if (index > -1) {
    //       this.mySelectionElectric.splice(index, 1);
    //     }
    //   });
    //   // Handle selected rows
    //   selection.selectedRows.forEach((row: any) => {
    //     if (!this.isElectricRowSelected(row.dataItem)) {
    //       this.mySelectionElectric.push(row.dataItem);
    //     }
    //   });
    // }




    if (this.selectedUtility == UtilityType.GAS) {
      selection.deselectedRows.forEach((row: any) => {
        const index = this.mySelectionGas.findIndex(
          (selectedRow: any) => selectedRow.id === row.dataItem.id
        );
        if (index > -1) {
          this.mySelectionGas.splice(index, 1);
        }
      });

      // Handle selected rows
      selection.selectedRows.forEach((row: any) => {
        if (!this.isGasRowSelected(row.dataItem)) {
          this.mySelectionGas.push(row.dataItem);
        }
      });
    } else if (this.selectedUtility == UtilityType.WATER) {
      selection.deselectedRows.forEach((row: any) => {
        const index = this.mySelectionWater.findIndex(
          (selectedRow: any) => selectedRow.id === row.dataItem.id
        );
        if (index > -1) {
          this.mySelectionWater.splice(index, 1);
        }
      });
      // Handle selected rows
      selection.selectedRows.forEach((row: any) => {
        if (!this.isWaterRowSelected(row.dataItem)) {
          this.mySelectionWater.push(row.dataItem);
        }
      });
    } else if (this.selectedUtility == UtilityType.ELECTRICITY) {
      selection.deselectedRows.forEach((row: any) => {
        const index = this.mySelectionElectric.findIndex(
          (selectedRow: any) => selectedRow.id === row.dataItem.id
        );
        if (index > -1) {
          this.mySelectionElectric.splice(index, 1);
        }
      });
      // Handle selected rows
      selection.selectedRows.forEach((row: any) => {
        if (!this.isElectricRowSelected(row.dataItem)) {
          this.mySelectionElectric.push(row.dataItem);
        }
      });
    }
    else if (this.selectedUtility == UtilityType.EV) {
      selection.deselectedRows.forEach((row: any) => {
        const index = this.mySelectionEv.findIndex(
          (selectedRow: any) => selectedRow.id === row.dataItem.id
        );
        if (index > -1) {
          this.mySelectionEv.splice(index, 1);
        }
      });
      // Handle selected rows
      selection.selectedRows.forEach((row: any) => {
        if (!this.isEvRowSelected(row.dataItem)) {
          this.mySelectionEv.push(row.dataItem);
        }
      });
    }


  }

  protected gasDataStateChange(state: DataStateChangeEvent): void {
    // if (this.selectedIndex == 0) {
    // }
    // this.sort = state.sort || [];
    // this.filter = state.filter || { logic: 'and', filters: [] };
    // this.loadGasConfig();
  }

  private loadGasConfig(): void {
    let sort,
      filter,
      totalcount = 0,
      take,
      data = [];
    if (this.selectedUtility === this.utilityType.GAS) {
      take = this.gasPageSize;
      data = this.gasDataList;
      totalcount = this.totalGasCount;
      const state: State = {
        take: take,
        sort: this.sort,
        filter: this.filter,
      };

      const processedData = process(data, state);

      this.gasGridData = {
        data: processedData.data,
        total: totalcount,
      };
      this.gasGridData.data = this.gasGridData?.data.map((gas) => {
        const fromFormatted = new Date(gas.fromDate).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        });

        // If `toDate` is null or 'Till now', set the "to" date accordingly
        const toFormatted = gas.toDate
          ? new Date(gas.toDate).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          })
          : 'Till now';

        // If `toDate` is 'Till now', format `from_and_toDate` correctly
        const from_and_toDate = gas.toDate
          ? `${fromFormatted} - ${new Date(gas.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`
          : `${fromFormatted} - Till now`;


        return {
          ...gas,
          from: `${new Date(gas.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`,
          to: gas.toDate
            ? new Date(gas.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })
            : 'Till now',
          from_and_toDate: from_and_toDate,
          /*  from_and_toDate: gas.fromDate
             ? gas.toDate
               ? `${new Date(gas.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })} - ${new Date(gas.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`
               : `${new Date(gas.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`
             : 'N/A', */
          /*  from_and_toDate: gas.fromDate && gas.toDate
             ? `${new Date(gas.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })} - ${new Date(gas.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`
             : 'N/A' */ // If either date is missing, set 'N/A' or handle accordingly
        };
      });
      console.log(this.gasGridData.data)

    } else if (this.selectedUtility === this.utilityType.WATER) {
      take = this.waterPageSize;
      data = this.waterDataList;
      totalcount = this.totalWaterCount;
      const state: State = {
        take: take,
        sort: this.sort,
        filter: this.filter,
      };

      const processedData = process(data, state);

      this.waterGridData = {
        data: processedData.data,
        total: totalcount,
      };
      /* this.waterGridData.data = this.waterGridData?.data.map((gas) => {
        return {
          ...gas, from: `${new Date(gas.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`,
          to: gas.toDate
            ? new Date(gas.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })
            : 'Till now',
          from_and_toDate: gas.fromDate && gas.toDate
            ? `${new Date(gas.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })} - ${new Date(gas.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`
            : 'N/A' // If either date is missing, set 'N/A' or handle accordingly
        };
      }); */
      console.log('Before transformation:', this.waterGridData?.data);

      this.waterGridData.data = this.waterGridData?.data.map((gas) => {

        const fromFormatted = new Date(gas.fromDate).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        });

        // If `toDate` is null or 'Till now', set the "to" date accordingly
        const toFormatted = gas.toDate
          ? new Date(gas.toDate).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          })
          : 'Till now';

        // If `toDate` is 'Till now', format `from_and_toDate` correctly
        const from_and_toDate = gas.toDate
          ? `${fromFormatted} - ${new Date(gas.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`
          : `${fromFormatted} - Till now`;





        return {
          ...gas,
          from: `${new Date(gas.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`,
          to: gas.toDate
            ? new Date(gas.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })
            : 'Till now',
          from_and_toDate: from_and_toDate
          /*  from_and_toDate: gas.fromDate
             ? gas.toDate
               ? `${new Date(gas.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })} - ${new Date(gas.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`
               : `${new Date(gas.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`
             : 'N/A', */
        };
      });

      console.log('After transformation:', this.waterGridData?.data);



    } else if (this.selectedUtility === this.utilityType.ELECTRICITY) {
      take = this.electricPageSize;
      data = this.electricDataList;
      totalcount = this.totalElectricCount;
      const state: State = {
        take: take,
        sort: this.sort,
        filter: this.filter,
      };

      const processedData = process(data, state);

      this.ElectricityGridData = {
        data: processedData.data,
        total: totalcount,
      };

      this.ElectricityGridData.data = this.ElectricityGridData?.data.map((gas) => {
        const fromFormatted = new Date(gas.fromDate).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        });

        // If `toDate` is null or 'Till now', set the "to" date accordingly
        const toFormatted = gas.toDate
          ? new Date(gas.toDate).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          })
          : 'Till now';

        // If `toDate` is 'Till now', format `from_and_toDate` correctly
        const from_and_toDate = gas.toDate
          ? `${fromFormatted} - ${new Date(gas.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`
          : `${fromFormatted} - Till now`;


        return {
          ...gas, from: `${new Date(gas.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`,
          to: gas.toDate
            ? new Date(gas.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })
            : 'Till now',
          from_and_toDate: from_and_toDate
          /*  from_and_toDate: gas.fromDate
             ? gas.toDate
               ? `${new Date(gas.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })} - ${new Date(gas.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`
               : `${new Date(gas.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`
             : 'N/A', */

        };
      });

    }
    else if (this.selectedUtility === this.utilityType.EV) {
      take = this.evPageSize;
      data = this.evDataList;
      totalcount = this.totalEvCount;
      const state: State = {
        take: take,
        sort: this.sort,
        filter: this.filter,
      };

      const processedData = process(data, state);
      this.EvGridData = {
        data: processedData.data,
        total: totalcount,
      };

      this.EvGridData.data = this.EvGridData?.data.map((ev) => {
        const fromFormatted = new Date(ev.fromDate).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        });

        // If `toDate` is null or 'Till now', set the "to" date accordingly
        const toFormatted = ev.toDate
          ? new Date(ev.toDate).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          })
          : 'Till now';

        // If `toDate` is 'Till now', format `from_and_toDate` correctly
        const from_and_toDate = ev.toDate
          ? `${fromFormatted} - ${new Date(ev.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`
          : `${fromFormatted} - Till now`;
        return {
          ...ev, from: `${new Date(ev.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`,
          to: ev.toDate
            ? new Date(ev.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })
            : 'Till now',
          from_and_toDate: from_and_toDate
          /*  from_and_toDate: ev.fromDate && ev.toDate
             ? `${new Date(ev.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })} - ${new Date(ev.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`
             : 'N/A' // If either date is missing, set 'N/A' or handle accordingly */
        };
      });
    }
    // if (this.selectedIndex == 0) {
    //   take = this.gasPageSize;
    //   data = this.gasDataList;
    // } else if (this.selectedIndex == 1) {
    //   take = this.waterPageSize;
    //   data = this.waterDataList;
    // } else {
    //   take = this.waterPageSize;
    //   data = this.electricDataList;
    // }

    this.hasGas = this.isUtilityDisplay('Gas');
    this.hasWater = this.isUtilityDisplay('Water');
    this.hasElectricity = this.isUtilityDisplay('Electricity');
    // this.gasGridData.data = this.gasGridData.data.map((project) => {
    //   console.log(new Date(project.currentDate));
    //   return {
    //     ...project,
    //     currentDate: new Date(project.currentDate), // Modify the billDate property
    //   };
    // });
  }

  public isGasRowSelected = (rowArg: any) => {
    return this.mySelectionGas.some((row: any) => row.id === rowArg.id);
  };
  public isWaterRowSelected = (rowArg: any) => {
    return this.mySelectionWater.some((row: any) => row.id === rowArg.id);
  };

  public isElectricRowSelected = (rowArg: any) => {
    return this.mySelectionElectric.some((row: any) => row.id === rowArg.id);
  };

  public isEvRowSelected = (rowArg: any) => {
    return this.mySelectionEv.some((row: any) => row.id === rowArg.id);
  };



  public onMeterConfigFilter(value: any): void {
    this.gasUnitRatePageChange({ skip: 0, take: 10 })
    this.getGasUnitRateData();
  }
  exportAll() {
    this.getTotalGasUnitRatesData();
  }
  public onMeterPdfExport(type: string, utility: string) {
    if (type === 'Excel') {
      if (utility == 'gas') {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      } else if (utility == 'water') {
        setTimeout(() => {
          this.waterGridhiddenGrid?.saveAsExcel();
        }, 1000);
      } else if (utility == 'ele') {
        setTimeout(() => {
          this.electGridhiddenGrid?.saveAsExcel();
        }, 1000);
      } else if (utility == 'ev') {
        setTimeout(() => {
          this.evGridhiddenGrid?.saveAsExcel();
        }, 1000);
      }
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }


  // onStepChange(event: any): void {
  //   this.selectedIndex = 0
  //   this.gasSearchedValue = '';
  //   this.selectedIndex = event.selectedIndex;
  //   const selectedIndex = event.selectedIndex;
  //   if (this.currentprojectUtilities.length >= 3) {
  //     if (this.selectedIndex == 0) {
  //       this.selectedUtility = this.utilityType.GAS;
  //     } else if (this.selectedIndex == 1) {
  //       this.selectedUtility = this.utilityType.WATER;
  //     } else if (this.selectedIndex == 2) {
  //       this.selectedUtility = this.utilityType.ELECTRICITY;
  //     }
  //   } else if (this.currentprojectUtilities.length == 2) {
  //     if (this.isUtilityDisplay('Water') && this.isUtilityDisplay('Gas')) {
  //       if (this.selectedIndex == 0) {
  //         this.selectedUtility = this.utilityType.GAS;
  //       } else if (this.selectedIndex == 1) {
  //         this.selectedUtility = this.utilityType.WATER;
  //       }
  //     } else if (this.isUtilityDisplay('Water') && this.isUtilityDisplay('Electricity')) {
  //       if (this.selectedIndex == 0) {
  //         this.selectedUtility = this.utilityType.WATER;
  //       } else if (this.selectedIndex == 1) {
  //         this.selectedUtility = this.utilityType.ELECTRICITY;
  //       }
  //     } else if (
  //       this.isUtilityDisplay('Electricity') &&
  //       this.isUtilityDisplay('Gas')
  //     ) {
  //       if (this.selectedIndex == 0) {
  //         this.selectedUtility = this.utilityType.GAS;
  //       } else if (this.selectedIndex == 1) {
  //         this.selectedUtility = this.utilityType.ELECTRICITY;
  //       }
  //     }
  //   } else if (this.currentprojectUtilities.length == 1) {
  //     if (
  //       this.isUtilityDisplay('Gas') &&
  //       !this.isUtilityDisplay('Water') &&
  //       !this.isUtilityDisplay('Electricity')
  //     ) {
  //       this.selectedUtility = this.utilityType.GAS;
  //     } else if (
  //       !this.isUtilityDisplay('Gas') &&
  //       this.isUtilityDisplay('Water') &&
  //       !this.isUtilityDisplay('Electricity')
  //     ) {
  //       this.selectedUtility = this.utilityType.WATER;
  //     } else if (
  //       !this.isUtilityDisplay('Gas') &&
  //       !this.isUtilityDisplay('Water') &&
  //       this.isUtilityDisplay('Electricity')
  //     ) {
  //       this.selectedUtility = this.utilityType.ELECTRICITY;
  //     }

  //   }
  //   this.getGasUnitRateData();
  //   // Determine which utility step is selected and load its data

  // }

  onStepChange(event: any): void {
    this.selectedIndex = 0;
    this.gasSearchedValue = '';
    this.selectedIndex = event.selectedIndex;
    const selectedIndex = event.selectedIndex;

    if (this.currentprojectUtilities.length >= 3) {
      // Handling four utilities: Gas, Water, Electricity, EV
      if (this.selectedIndex == 0) {
        this.selectedUtility = this.utilityType.GAS;
      } else if (this.selectedIndex == 1) {
        this.selectedUtility = this.utilityType.WATER;
      } else if (this.selectedIndex == 2) {
        this.selectedUtility = this.utilityType.ELECTRICITY;
      } else if (this.selectedIndex == 3) {
        this.selectedUtility = this.utilityType.EV;
      }
    } else if (this.currentprojectUtilities.length == 3) {
      // Handling three utilities, various combinations including EV
      if (this.isUtilityDisplay('Gas') && this.isUtilityDisplay('Water') && this.isUtilityDisplay('Electricity')) {
        if (this.selectedIndex == 0) {
          this.selectedUtility = this.utilityType.GAS;
        } else if (this.selectedIndex == 1) {
          this.selectedUtility = this.utilityType.WATER;
        } else if (this.selectedIndex == 2) {
          this.selectedUtility = this.utilityType.ELECTRICITY;
        }
      } else if (this.isUtilityDisplay('Gas') && this.isUtilityDisplay('Water') && this.isUtilityDisplay('EV')) {
        if (this.selectedIndex == 0) {
          this.selectedUtility = this.utilityType.GAS;
        } else if (this.selectedIndex == 1) {
          this.selectedUtility = this.utilityType.WATER;
        } else if (this.selectedIndex == 2) {
          this.selectedUtility = this.utilityType.EV;
        }
      } else if (this.isUtilityDisplay('Gas') && this.isUtilityDisplay('Electricity') && this.isUtilityDisplay('EV')) {
        if (this.selectedIndex == 0) {
          this.selectedUtility = this.utilityType.GAS;
        } else if (this.selectedIndex == 1) {
          this.selectedUtility = this.utilityType.ELECTRICITY;
        } else if (this.selectedIndex == 2) {
          this.selectedUtility = this.utilityType.EV;
        }
      } else if (this.isUtilityDisplay('Water') && this.isUtilityDisplay('Electricity') && this.isUtilityDisplay('EV')) {
        if (this.selectedIndex == 0) {
          this.selectedUtility = this.utilityType.WATER;
        } else if (this.selectedIndex == 1) {
          this.selectedUtility = this.utilityType.ELECTRICITY;
        } else if (this.selectedIndex == 2) {
          this.selectedUtility = this.utilityType.EV;
        }
      }
    } else if (this.currentprojectUtilities.length == 2) {
      // Handling two utilities, various combinations including EV
      if (this.isUtilityDisplay('Water') && this.isUtilityDisplay('Gas')) {
        if (this.selectedIndex == 0) {
          this.selectedUtility = this.utilityType.GAS;
        } else if (this.selectedIndex == 1) {
          this.selectedUtility = this.utilityType.WATER;
        }
      } else if (this.isUtilityDisplay('Water') && this.isUtilityDisplay('Electricity')) {
        if (this.selectedIndex == 0) {
          this.selectedUtility = this.utilityType.WATER;
        } else if (this.selectedIndex == 1) {
          this.selectedUtility = this.utilityType.ELECTRICITY;
        }
      } else if (this.isUtilityDisplay('Electricity') && this.isUtilityDisplay('Gas')) {
        if (this.selectedIndex == 0) {
          this.selectedUtility = this.utilityType.GAS;
        } else if (this.selectedIndex == 1) {
          this.selectedUtility = this.utilityType.ELECTRICITY;
        }
      } else if (this.isUtilityDisplay('Electricity') && this.isUtilityDisplay('EV')) {
        if (this.selectedIndex == 0) {
          this.selectedUtility = this.utilityType.ELECTRICITY;
        } else if (this.selectedIndex == 1) {
          this.selectedUtility = this.utilityType.EV;
        }
      } else if (this.isUtilityDisplay('Gas') && this.isUtilityDisplay('EV')) {
        if (this.selectedIndex == 0) {
          this.selectedUtility = this.utilityType.GAS;
        } else if (this.selectedIndex == 1) {
          this.selectedUtility = this.utilityType.EV;
        }
      } else if (this.isUtilityDisplay('Water') && this.isUtilityDisplay('EV')) {
        if (this.selectedIndex == 0) {
          this.selectedUtility = this.utilityType.WATER;
        } else if (this.selectedIndex == 1) {
          this.selectedUtility = this.utilityType.EV;
        }
      }
    } else if (this.currentprojectUtilities.length == 1) {
      // Handling a single utility
      if (this.isUtilityDisplay('Gas')) {
        this.selectedUtility = this.utilityType.GAS;
      } else if (this.isUtilityDisplay('Water')) {
        this.selectedUtility = this.utilityType.WATER;
      } else if (this.isUtilityDisplay('Electricity')) {
        this.selectedUtility = this.utilityType.ELECTRICITY;
      } else if (this.isUtilityDisplay('EV')) {
        this.selectedUtility = this.utilityType.EV;
      }
    }

    this.getGasUnitRateData();
    // Determine which utility step is selected and load its data
  }


}
