<!-- <div bsModal tabindex="-1">
    <div class="modal-dialog modal-dialog-centered"> -->
<div class="modal-content">
    <div class="modal-header bg-primary text-white">
        <!-- <h5 class="modal-title"></h5> -->

        <!-- <li class="breadcrumb-item active"><a href="javascript: void(0);" [routerLink]="'/dashboard'"><i
                        class="fa fa-home" aria-hidden="true"></i></a>
            </li> -->
        <h5 class="modal-title">Feedback</h5>



        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
            (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
        <form class="form-horizontal" [formGroup]="consumerFeedback">
            <label for="basicpill-lastname-input">Feedback<span class="required">*</span></label>
            <textarea id="basicpill-address-input" id="feedback" formControlName="feedback" name="feedback"
                class="form-control" rows="2" placeholder="Enter Feedback"></textarea>
            @if(submitted && consumersFeedbackControls['feedback'].errors){
            <div class="invalid-feedback">
                @if(consumersFeedbackControls['feedback'].errors['required']){
                <div>{{prifileValidations.consumersPage.feedback}}</div>}
                @if(consumersFeedbackControls['feedback'].errors['minlength']){
                <div>{{prifileValidations.consumersPage.min_feedback}}</div>
                }
            </div>}
        </form>
    </div>
    <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
        <button type="button" class="btn btn-primary waves-effect waves-light" (click)="onSubmit()">Submit</button>
    </div>
</div><!-- /.modal -->
<!--  </div>
</div> -->