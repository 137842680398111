<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    <button class="btn btn-primary d-flex align-items-center" (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>


                </span>

            </div>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-12">
            <div class="page-title-right">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
            </div>
        </div>
    </div> -->

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ng-template #template let-anchor>
                        <span>{{ anchor.nativeElement.innerText }}</span>
                    </ng-template>

                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                        (mouseover)="showTooltip($event)">
                        <kendo-grid [data]="mappinggridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
                            [selectable]="selectableSettings" [pageable]="{
                buttonCount: buttonCount,
                info: info,
                type: type,
                pageSizes: pageSizes,
                previousNext: previousNext
              }" [reorderable]="true" filterable="menu" [filter]="mappingState.filter" [skip]="mappingState.skip"
                            [sort]="mappingState.sort" [columnMenu]="{ filter: true }"
                            (filterChange)="onprojectFilterChange($event)" (sortChange)="onSortChange($event)"
                            [resizable]="true" scrollable="scrollable" (pageChange)="mappingConfigPageChange($event)"
                            (selectionChange)="selectionChanged($event)" (dataStateChange)="dataStateChange($event)"
                            style="width: 100%;">

                            <ng-template kendoGridToolbarTemplate>
                                <div class="search_icon">
                                    <kendo-textbox [style.width.px]="250" [clearButton]="true"
                                        (valueChange)="onValueChange($event)" [(ngModel)]="searchedValue"
                                        placeholder="Search in all columns...">
                                    </kendo-textbox>

                                    <button class="btn btn-primary border-left-none "
                                        (click)="onmappingConfigFilter($event)">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                                <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                                    [(ngModel)]="searchedValue"
                                    (valueChange)="onmappingConfigFilter($event)"></kendo-textbox> -->
                                <kendo-grid-spacer></kendo-grid-spacer>


                                <div class="sm-device d-flex justify-content-between">
                                    <button class="btn btn-outline-primary btn-sm me-2" (click)="addNewMapping()">Link
                                        New Meter </button>
                                    <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                        (click)="onmappingPdfExport('Excel')">
                                        <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                    </button>
                                </div>
                            </ng-template>

                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                                class="no-tooltip"></kendo-grid-checkbox-column>

                            <kendo-grid-column field="utility.name" title="utility" [width]="100"></kendo-grid-column>
                            <kendo-grid-column field="macAddress" title="Mac Address" [width]="100"></kendo-grid-column>
                            <kendo-grid-column field="meterSerialNo" title="Meter Serial No"
                                [width]="100"></kendo-grid-column>
                            <kendo-grid-column field="mcbMainPower" title="Mcb Main Power"
                                [width]="150"></kendo-grid-column>
                            <kendo-grid-column field="status" title="Status" [width]="100"></kendo-grid-column>
                            <kendo-grid-command-column title="" [columnMenu]="false" [width]="80">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex">
                                        <!-- Unlink Project Icon with Tooltip and No Background -->
                                        <span kendoTooltip title="Unlinking Meter" class="me-2"
                                            (click)="unMapping(dataItem)">
                                            <i class="fas fa-unlink text-success" style="cursor: pointer;"></i>
                                        </span>
                                    </div>
                                </ng-template>
                            </kendo-grid-command-column>


                        </kendo-grid>
                    </div>

                    <!--mapping download buttons start-->

                    <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                        <kendo-grid
                            [kendoGridBinding]="mySelectionmapping.length>0 ? mySelectionmapping:allmappingconfigList.length>0?allmappingconfigList:mappinggridData.data"
                            #pdfGrid>
                            <kendo-grid-column field="utility.name" title="utility" [width]="100"></kendo-grid-column>
                            <kendo-grid-column field="macAddress" title="Mac Address" [width]="100"></kendo-grid-column>
                            <kendo-grid-column field="meterSerialNo" title="Meter Serial No"
                                [width]="100"></kendo-grid-column>
                            <kendo-grid-column field="mcbMainPower" title="Mcb Main Power"
                                [width]="150"></kendo-grid-column>
                            <kendo-grid-column field="status" title="Status" [width]="100"></kendo-grid-column>
                            <kendo-grid-excel fileName="MappingList.xlsx"></kendo-grid-excel>
                        </kendo-grid>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>