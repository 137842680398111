import { Component } from '@angular/core';
import { Store, compose, select } from '@ngrx/store';
import { LoaderService } from '../../../core/services/loader.service';
import { SharedService } from '../../../shared/shared.service';
import { Router } from '@angular/router';
import { getBillsInvoice, resetbillsInvoiceState } from '../../../store/Bills/bills.action';
import { Subscription } from 'rxjs';
import { getBillsInvoiceResponse } from '../../../store/Bills/bills-selector';
@Component({
  selector: 'app-bill-invoices',
  templateUrl: './bill-invoices.component.html',
  styleUrl: './bill-invoices.component.scss'
})
export class BillInvoicesComponent {

  projectId: any;
  billsInvoiceList: any
  currentUser: any;
  private billsinvoicesSubscription: Subscription = new Subscription;

  constructor(private sharedService: SharedService, private router: Router,
    private store: Store) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectId = this.currentUser?.project.id;
    this.getBillsStoreInfo();

  }


  getBillsStoreInfo() {
    this.store.dispatch(resetbillsInvoiceState());

    /*  this.billsinvoicesSubscription = this.store.pipe(select(getBillsInvoiceResponse)).subscribe({
       next: (res: any) => {
         console.log(res)
         if (res) {
           this.billsInvoiceList = res.data;
         }
       },
     }) */

  }


  ngOnInit(): void {
    // this.store.dispatch(getBillsInvoice({ ProjectId: this.projectId }));



  }
  ngOnDestroy() {
    this.billsinvoicesSubscription.unsubscribe();
  }




















}
