<!-- <div class="container-fluid">
    <div bsModal tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered"> -->
<div class="modal-content">
    <div class="modal-header bg-primary text-white">
        @if(umdData?.utility?.id){
        <h5 class="modal-title">Update UMD</h5>
        } @else {<h5 class="modal-title">Add UMD</h5>}

        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
            (click)="dismissModal()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="createUMDForm">
            <div class="mb-3">
                <label>Utility Type <span class="required">*</span></label>
                <ng-select [items]="configData?.utilities" bindLabel="name" id="utilityMasterId" name="utilityMasterId"
                    bindValue="id" formControlName="utilityMasterId" [multiple]="false"
                    [placeholder]="'Select...'"></ng-select>
                @if(submitted && createUMDForm.controls['utilityMasterId'].errors) {
                <div class="invalid-feedback">
                    @if(createUMDForm.controls['utilityMasterId'].errors['required']) {
                    <div>{{Umd_Validations.utilityMasterId.required}}</div>}
                </div>}
            </div>

            <div class="mb-3">
                <label>Mac Address <span class="required">*</span></label>
                <input type="text" class="form-control" formControlName="macAddress" id="macAddress" name="macAddress"
                    placeholder="Enter Mac Address">
                @if(submitted && createUMDForm.controls['macAddress'].errors) {
                <div class="invalid-feedback">
                    @if(createUMDForm.controls['macAddress'].errors['required']) {
                    <div>{{Umd_Validations.macAddress.required}}</div>}
                </div>}
            </div>

            <div class="mb-3">
                <label>Meter Serial Number <span class="required">*</span></label>
                <input type="text" class="form-control" formControlName="meterSerialNo" id="meterSerialNo"
                    name="meterSerialNo" placeholder="Enter Meter Serial Number">
                @if(submitted && createUMDForm.controls['meterSerialNo'].errors) {
                <div class="invalid-feedback">
                    @if(createUMDForm.controls['meterSerialNo'].errors['required']) {
                    <div>{{Umd_Validations.meterSerialNo.required}}</div>}
                </div>}
            </div>

            <div class="mb-3">
                <label>Meter Status <span class="required">*</span></label>
                <ng-select [items]="configData?.statuses" formControlName="status" id="meterStatus" name="status"
                    [multiple]="false" [placeholder]="'Select...'"></ng-select>
                @if(submitted && createUMDForm.controls['status'].errors) {
                <div class="invalid-feedback">
                    @if(createUMDForm.controls['status'].errors['required']) {
                    <div>{{Umd_Validations.meterStatus.required}}</div>}
                </div>}
            </div>
            <div class="mb-3"  *ngIf="requiredMcbMainPowerfield">
                <label>MCB / Main Power (On/Off button) <span *ngIf="requiredfield" class="required">*</span></label>
                <ng-select [items]="mcbPowerButtons" formControlName="mcbMainPower" id="mcbMainPower"
                    name="mcbMainPower" [multiple]="false" [placeholder]="'Select...'" [bindValue]="'value'"
                    [bindLabel]="'value'">
                </ng-select>
                <div *ngIf="submitted && createUMDForm.controls['mcbMainPower'].errors" class="invalid-feedback">
                  <div *ngIf="createUMDForm.controls['mcbMainPower'].errors['required']">
                    {{ Umd_Validations.mcbMainPower.required }}
                  </div>
                </div>
              </div>

              <div class="mb-3" *ngIf="requiredSlabNamefield" >
                <label for="">Supplier Name <span class="required">*</span></label>
                <ng-select [items]="waterTypeItems" bindLabel="name" bindValue="id"
                  [placeholder]="'Select...'" [multiple]="false"
                  formControlName="supplierId"></ng-select>
                  <div *ngIf="submitted && createUMDForm.controls['supplierId'].errors" class="invalid-feedback">
                    <div *ngIf="createUMDForm.controls['supplierId'].errors['required']">
                      {{ Umd_Validations.supplierId.required }}
                    </div>
                  </div>
                </div>
        </form>
    </div>
    <div class="modal-footer">
        @if(umdData?.utility?.id){<button type="button" class="btn btn-primary waves-effect waves-light"
            (click)="CreateUMD('Update')">Update</button>}
        @else{<button type="button" class="btn btn-primary waves-effect waves-light"
            (click)="CreateUMD('Create')">Save</button>}
    </div>
</div><!-- /.modal-content -->