import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  LegendItemClickEvent,
  CategoryAxis,
  Legend,
  Series,
  SeriesLabelsContentArgs,
  LineStyle,
} from '@progress/kendo-angular-charts';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-postpaid-admin-dashboard',
  templateUrl: './postpaid-admin-dashboard.component.html',
  styleUrl: './postpaid-admin-dashboard.component.scss',
  providers: [DecimalPipe],
})
export class PostpaidAdminDashboardComponent {
  public seriesVisible = true;
  public residentialUnitChart: any;
  public values: any = ['All Projects'];
  dateRange: boolean = false;
  public style: LineStyle = 'normal';
  public line: LineStyle = 'smooth';
  totalBill = 0;
  totalPrice = 0;
  currentUserData: any;

  constructor(private decimalPipe: DecimalPipe) {}

  TotalWalletData = [
    {
      data: [
        1, 10, 12, 2, 14, 23, 13, 23, 12, 32, 4, 1, 10, 12, 2, 14, 23, 13, 23,
        12, 32, 4,
      ],
      color: '#adb5bd',
    },
  ];
  // public seriesBorder: any = {
  //   color: 'black',
  //   width: 2,
  //   dashType: 'dash'
  // };

  deductedWalletData = [
    {
      data: [
        1, 10, 12, 2, 14, 23, 13, 23, 12, 32, 4, 1, 10, 12, 2, 14, 23, 13, 23,
        12, 32, 4,
      ],
      color: '#556EE6',
    },
  ];

  consumptionData = [
    {
      data: [1, 10, 12, 2, 14],
      color: '#63ABFD',
    },
  ];

  topConsumption = [
    {
      data: [100, 123, 234, 343, 232],
      color: '#63ABFD',
    },
  ];

  revenueData = [
    {
      data: [40],
      color: '#63ABFD',
      name: 'Communicating',
    },
    {
      data: [80],
      color: '#FFA5CB',
      name: 'Non-Communicating',
    },
  ];
  public categories: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May'];

  public pieData = [
    {
      value: 180,
      color: '#63ABFD',
      name: 'Occupied units',
    },
    {
      value: 20,
      color: '#FFA5CB',
      name: 'Unoccupied units',
    },
  ];

  public labelContent(e: SeriesLabelsContentArgs): string {
    return e.value;
  }

  ngOnInit() {
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    this.calculateTotals();
  }

  calculateTotals() {
    this.totalBill = this.projects.reduce((sum, item) => sum + item.bill, 0);
    this.totalPrice = this.projects.reduce(
      (sum, item) => sum + item.revenue,
      0
    );
  }

  getFormattedNumber(value: number): any {
    return this.decimalPipe.transform(value, '1.0-0');
  }

  public residentialUnitsData = [
    { name: 'Occupied units', value: 200, color: '#63ABFD' },
    { name: 'Unoccupied units', value: 200, color: '#FFA5CB' },
  ];

  public meterData = [
    { name: 'Turn On', value: 800, color: '#63ABFD' },
    { name: 'Turned Off', value: 200, color: '#FFA5CB' },
  ];

  public consumerData = [
    { name: 'Active', value: 180, color: '#63ABFD' },
    { name: 'Inactive', value: 20, color: '#FFA5CB' },
  ];

  public listItems: Array<string> = ['Project 1', 'Project 2', 'Project 3'];

  public meter: Array<string> = ['Water', 'Gas', 'Electricity'];

  public months: Array<string> = [
    'This Months',
    'Last Months',
    'Three Months',
    'Six Months',
    'One Year',
    'Custom Range',
  ];

  projects = [
    { name: 'Paid Bills', revenue: 8000000, bill: 100 },
    { name: 'Unpaid Bills', revenue: 8000000, bill: 20 },
  ];

  legend: Legend = {
    position: 'bottom',
  };

  public onLegendItemClick(e: LegendItemClickEvent): void {
    /* Do not hide the series on legend click */
    e.preventDefault();

    /* Hide the series manually */
    this.seriesVisible = !this.seriesVisible;
  }

  public selectionChange(value: any): void {
    // this.log("selectionChange", value);
    console.log(value);
    if (value == 'Custom Range') {
      this.dateRange = true;
    } else {
      this.dateRange = false;
    }
  }
}
