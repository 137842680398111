import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpMetersComponent } from './rp-meters/rp-meters.component';
import { RpMetersViewComponent } from './rp-meters-view/rp-meters-view.component';

const routes: Routes = [
  { path: 'meters', component: RpMetersComponent },
  { path: 'meters/metersView', component: RpMetersViewComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MetersRoutingModule {}
