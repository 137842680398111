import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { User } from '../../store/Authentication/auth.models';
import { AppConfigData } from '../config/appConfigData';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Faq } from '../../store/Faq/faq.models';
import { Roles } from '../../shared/constants/enum';

@Injectable({ providedIn: 'root' })
export class FeedBackService {
  listOfRoles = Roles;
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) { }

  getFeedBackServie(projectId: string): Observable<any> {
    return this.http
      .get<any>(AppConfigData.getconsumerfeedbackUrl + projectId, {})
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('feedbacklist  failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }
  getSuperAdminFeedBackServie(): Observable<any> {
    return this.http.get<any>(AppConfigData.getAgentAdminfeedbackUrl, {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('superadmin feedback list  failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  saveFeedback(requestPayload: any, role: any) {
    let requestUrl;
    if ((role == this.listOfRoles.ADMIN) || (role == this.listOfRoles.AGENT)) {
      requestUrl = AppConfigData.saveAgentAdminfeedbackUrl;
    } else {
      requestUrl = AppConfigData.saveconsumerfeedbackUrl; // consumer feedback
    }
    return this.http.post<any>(requestUrl, requestPayload).pipe(
      map((resp) => {
        return resp;
      })
    );
  }
}
