import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UtilitiesState } from './utilities.reducer';

export const getUtilitiesState =
  createFeatureSelector<UtilitiesState>('utilities');

export const selectCreateutilitiesResponse = createSelector(
  getUtilitiesState,
  (state: UtilitiesState) => state.createutilitiesResponse1
);

export const selectCreateutilitiesError = createSelector(
  getUtilitiesState,
  (state: UtilitiesState) => state.createutilitiesError
);

export const getutilitiesResponse = createSelector(
  getUtilitiesState,
  (state: UtilitiesState) => state.getutilitiesResponse
);

export const getGasRateDataResponse = createSelector(
  getUtilitiesState,
  (state: UtilitiesState) => state.getGasRateDataResponse
);

export const getAllGasRateDataResponse = createSelector(
  getUtilitiesState,
  (state: UtilitiesState) => state.getAllGasRateDataResponse
);

export const getWaterRateDataResponse = createSelector(
  getUtilitiesState,
  (state: UtilitiesState) => state.getWaterRateDataResponse
);

export const getElectricityRateDataResponse = createSelector(
  getUtilitiesState,
  (state: UtilitiesState) => state.getElectricityRateDataResponse
);


export const getEVDataResponse = createSelector(
  getUtilitiesState,
  (state: UtilitiesState) => state.getEvDataResponse
);


