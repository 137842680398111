import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/shared.service';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store, compose, select } from '@ngrx/store';
import { LoaderService } from '../../../core/services/loader.service';
import { Validations } from '../../../shared/constants/validations';
import { createBills, getBillReadingsByMonthAdhocList, getBillReadingsByMonthList, getBillReadingsInfoList, getResidentiallist, resetBillReadingsInfoState, resetbillsState } from '../../../store/Bills/bills.action';
import { getBillReadingsByMonthAdhocResponse, getBillReadingsByMonthResponse, getBillReadingsInfoResponse, getresidentialResponse } from '../../../store/Bills/bills-selector';
import { take } from 'rxjs';
import { log } from 'console';
import Swal from 'sweetalert2';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
@Component({
  selector: 'app-rp-create-bill',
  templateUrl: './rp-create-bill.component.html',
  styleUrl: './rp-create-bill.component.scss'
})
export class RpCreateBillComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig> | undefined;
  hidden!: boolean;
  billgeneratetype!: string;
  billsForm!: UntypedFormGroup;
  submitted: boolean = false;
  selectValue!: string[];
  configData: any;
  utilityList: any[] = [];
  currentUserData: any;
  projectId: any;
  isGasOpen: boolean = false;
  isWaterOpen: boolean = false;
  isEleOpen: boolean = false;
  prifileValidations = Validations;
  selectedUtilityType: any[] = [];
  selectedMeterNos: any[] = [];
  billsData: any
  breadCrumbItems: Array<{}> | undefined;
  lengthOfgas = 'Gas';
  lengthOFwater = 'Water';
  lengthOfelectricity = 'Electricity';
  ismultiDropDown: boolean = false;
  currentMonth!: number;
  currentYear!: number;
  utilityTypeList: any[] = [];
  utilityMetersList: any[] = [];
  selectedConsumerId: any;
  selectedResidentialunit: any;
  selectedresidentilaUnitId: any;
  billformonthList: any = [] = []
  residentialUnitList: any = {}
  today: Date;
  minDate: Date;
  selectedDate: any;
  constructor(private sharedService: SharedService, private formBuilder: UntypedFormBuilder, private loaderService: LoaderService,
    private router: Router,
    private store: Store) {
    this.datePickerConfig = {
      containerClass: 'theme-default', // Optional: Customize the theme
      rangeSeparator: ' to ',
      minDate: this.getMinDate(),
      // maxDate: this.getcurentdateDisabled(),
      showWeekNumbers: false,
      // other configurations as needed
    };
    let givenDate = new Date();

    // Subtract one day
    let previousDate = new Date(givenDate);
    previousDate.setDate(givenDate.getDate() - 1);

    // Format the result to 'YYYY-MM-DD' format
    let vacateDate = previousDate.toISOString().split('T')[0];

    //console.log(vacateDate); // Outputs: 2024-08-22

    this.breadCrumbItems = [{ label: 'Bills', url: 'bills', active: true }, { label: 'Create Bill', }];
    if (history.state.billsData != undefined && history.state.billsData !== null) {
      this.billsData = history.state.billsData;
      console.log(this.billsData);
    }
    this.today = new Date();
    this.minDate = new Date(this.today.getFullYear(), this.today.getMonth() - 6, 1);
    this.datePickerConfig = Object.assign({}, {
      dateInputFormat: 'DD/MM/YYYY', // Set to dd/MM/yyyy format

      /*  minDate: this.minDate,
       maxDate: this.today, */

    });


    this.billsForm = this.formBuilder.group({
      ResidentialUnit: [, [Validators.required]],
      consumer: ['', [Validators.required]],
      utilityType: [, [Validators.required]],
      MeterNumber: [, [Validators.required]],
      selectBillForMonth: [, [Validators.required]],
      gas_array: this.formBuilder.array([]),
      water_array: this.formBuilder.array([]),
      electricity_array: this.formBuilder.array([]),
      totalAmount: ['', [Validators.required]],
      billStatus: ['', [Validators.required]],
      LastMonthBillGeneratedDate: [],
      billGeneratedDate: ['', [Validators.required]],
      SelectDatesForBillGaneration: ['',],
      startdate: ['',],
      endDate: [''],
      billedDays: ['', [Validators.required]],

    })
    this.getAllConfigData()
  }
  add_water_Row(meter: any, index: number): void {
    let row = this.formBuilder.group({
      unitRateWater: [{ value: meter.unitRate, disabled: true }],
      type: [{ value: 'Water', disabled: true }], //'water'],
      macAddress: [{ values: meter.macAddress, disabled: true }], //meter.macAddress],
      previousMeterReadingWater: [{ value: meter.previousMeterReading, disabled: true }],
      currentMeterReadinWater: [{ value: meter.currentMeterReading, disabled: true }],
      noOfUnitsConsumedWater: [{ value: meter.noofUnitsConsumed, disabled: true }],
      billAmount: [{ value: meter.billAmount, disabled: true }], //meter.billAmount],
      totalAmount: [{ value: meter.totalAmount, disabled: true }], //meter.totalAmount],
      utilityMasterId: [{ value: meter.utilityMasterId, disabled: true }], //meter.utilityMasterId],
      otherCharges: [meter.otherCharges],
    });
    this.water_array.push(row);
    if (this.water_array.length > 0) {
      this.isWaterOpen = true
    }
    this.lengthOFwater = this.water_array.length > 0 ? `Water ( ${this.water_array.length})` : 'Water';

  }

  add_gas_Row(meter: any, index: number): void {
    let row = this.formBuilder.group({
      UnitRateGas: [{ value: meter.unitRate, disabled: true }],
      type: [{ value: 'Gas', disabled: true }], //'Gas'],
      macAddress: [{ value: meter.macAddress, disabled: true }], //meter.macAddress],
      previousMeterReadingGas: [{ value: meter.previousMeterReading, disabled: true }],
      componenurrentMeterReadingGas: [{ value: meter.currentMeterReading, disabled: true }],
      noOfUnitsConsumedGas: [{ value: meter.noofUnitsConsumed, disabled: true }],
      billAmount: [{ value: meter.billAmount, disabled: true }],
      totalAmount: [{ value: meter.totalAmount, disabled: true }], // [meter.totalAmount]
      utilityMasterId: [meter.utilityMasterId],
      otherCharges: [meter.otherCharges],

    });

    this.gas_array.push(row);
    if (this.gas_array.length > 0) {
      this.isGasOpen = true;
    }
    // console.log(this.gas_array)

    this.lengthOfgas = `Gas ( ${this.gas_array.length})`


  }
  /*   getformControlKey(group: any,): { key: string, value: string }[] {
      
      return Object.keys(group.controls).map(key => ({ key, value: group.controls[key] }));
    } */
  add_electricity_Row(meter: any, index: number): void {

    let row = this.formBuilder.group({
      MeterNo: [{ value: meter.macAddress, disabled: true }],
      unitRateEB: [{ value: meter.unitRateEB, disabled: true }],
      unitRateDG: [{ value: meter.unitRateDG, disabled: true }],
      type: [{ value: 'Electricity', disabled: true }],
      previousMeterReadingEB: [{ value: '', disabled: true }],
      currentMeterReadingEB: [{ value: '', disabled: true }],
      noOfUnitsConsumedEB: [{ value: '', disabled: true }],
      previousMeterReadingDG: [{ value: '', disabled: true }],
      currentMeterReadingDG: [{ value: '', disabled: true }],
      noOfUnitsConsumedDG: [{ value: '', disabled: true }],
      billAmount: [{ value: meter.billAmount, disabled: true }],
      totalAmount: [{ value: meter.totalAmount, disabled: true }],
      utilityMasterId: [meter.utilityMasterId],
      otherCharges: [meter.otherCharges],
    });

    this.electricity_array.push(row);
    //  console.log("eletcricity->", this.electricity_array.value)
    //console.log("eletcricity->", this.electricity_array.value)
    this.lengthOfelectricity = `Electricity ( ${this.electricity_array.length})`
    if (this.electricity_array.length > 0) {
      this.isEleOpen = true
    }
  }

  get gas_array(): FormArray {
    return this.billsForm.get('gas_array') as FormArray
  }

  get water_array(): FormArray {
    return this.billsForm.get('water_array') as FormArray
  }

  get electricity_array(): FormArray {
    return this.billsForm.get('electricity_array') as FormArray
  }

  ngOnInit(): void {

    const currentUserString = localStorage.getItem('currentUser');
    if (this.billsData.billType == 'adhoc') {
      this.billsControls['SelectDatesForBillGaneration'].setValidators(Validators.required);
      this.billsControls['SelectDatesForBillGaneration'].updateValueAndValidity();
    }
    else {
      this.billsControls['SelectDatesForBillGaneration'].clearValidators();
      this.billsControls['SelectDatesForBillGaneration'].updateValueAndValidity();
    }
    this.currentUserData = JSON.parse(currentUserString ?? '');
    if (this.currentUserData.project !== null) {
      this.projectId = this.currentUserData.project.id;
      this.billgeneratetype = this.currentUserData.project.billGenerationType;

      this.store.dispatch(
        getResidentiallist({ projectId: this.projectId, })
      );
    }
    this.billsForm.controls['LastMonthBillGeneratedDate'].disable();
    this.billsForm.controls['billGeneratedDate'].disable();
    this.billsForm.controls['startdate'].disable();



    this.billsForm.controls['totalAmount'].disable();
    this.billsForm.controls['billStatus'].disable();
    this.billsForm.controls['billedDays'].disable();

    if (this.billgeneratetype == "Combined") {
      this.ismultiDropDown = true;
      this.billsForm.controls['consumer'].disable();
      this.billsForm.controls['utilityType'].disable();
      this.billsForm.controls['MeterNumber'].disable();
    } else {
      this.ismultiDropDown = false;
      this.billsForm.controls['consumer'].enable();
      this.billsForm.controls['utilityType'].enable();
      this.billsForm.controls['MeterNumber'].enable();
    }

  }



  getcurentdateDisabled() {
    const today = new Date();
    const previousDay = new Date(today);
    previousDay.setDate(today.getDate() - 1); // Subtract 1 day

    //console.log('Previous Day:', previousDay.toISOString().split('T')[0]);

    return this.formatDate(previousDay);
  }
  getMinDate(): any {
    const today = new Date();
    const minDate = new Date(today.getFullYear(), today.getMonth() - 6, 1); // First day of the month, 6 months ago
    return this.formatDate(minDate);
  }
  getAllConfigData(): void {
    let getresidentionBills$ = this.store.pipe(select(getresidentialResponse));

    this.sharedService.getAllConfigData().subscribe((data) => {
      if (data.statusCode == 200 && data.message == 'Config data retrieved successfully.') {
        this.configData = data.data;
        this.utilityList = this.configData.utilities;
        this.billformonthList = this.configData.months;

        let date = new Date();
        this.currentMonth = date.getMonth();
        this.currentYear = date.getFullYear();
        this.billformonthList = this.billformonthList.map((p: any, index: number) => {
          let startIndex = (this.currentMonth - 6 + 12) % 12;
          if (
            (startIndex <= this.currentMonth && index >= startIndex && index <= this.currentMonth) ||
            (startIndex > this.currentMonth && (index >= startIndex || index <= this.currentMonth))
          ) {
            // Disable the current month
            return { ...p, disabled: index === this.currentMonth };
          } else {
            return { ...p, disabled: true };
          }
        });

        /* this.billformonthList = this.billformonthList.map((p: any, index: number) =>
          index >= this.currentMonth - 5 && index <= this.currentMonth
            ? { ...p, disabled: false } // Enable the last six months including the current month
            : { ...p, disabled: true }  // Disable the other months
        ); */
        //const month = date.toLocaleString('default', { month: 'long' });

        /*  this.billformonthList = this.billformonthList.map((p: any) =>
           this.isMonthDisabled(p) ? p : { ...p, disabled: true }
         ); */


      }
    })
    getresidentionBills$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.residentialUnitList = res;

      }
    })

  }

  isMonthDisabled(month: any): boolean {
    return month.id <= this.currentMonth;
  }
  get billsControls() {
    return this.billsForm.controls;
  }

  saveBills(): void {
    this.submitted = true;
    if (this.billsForm.invalid) { return }
    else {
      console.log("billls form Details ", this.billsForm.value);
      console.log(this.electricity_array.value)
      // var dt = this.billsForm.get('SelectDatesForBillGaneration')?.value;

      // var fromdate = this.billsForm.get('SelectDatesForBillGaneration')?.value;
      //var todate= this.billsForm.get('SelectDatesForBillGaneration')?.value;

      var fromdate = this.billsForm.get('startdate')?.value;
      var todate = this.billsForm.get('endDate')?.value;
      console.log("fromdate", fromdate);
      console.log("todate", todate);
      let request: any = {
        ProjectId: this.projectId,
        residentialUnitId: this.billsForm.get('ResidentialUnit')?.value,
        consumerId: this.selectedConsumerId,
        ConsumerName: this.billsForm.get('consumer')?.value,
        lastBillGeneratedDate: this.billsForm.get('LastMonthBillGeneratedDate')?.value,
        currentBillGeneratedDate: this.billsForm.get('billGeneratedDate')?.value,

        billGenerateFromDate: fromdate,
        billGenerateToDate: todate,

        billMonth: this.selectedMonth?.name.slice(0, 3).toUpperCase(),
        // optionalCharges: 0,
        billAmount: this.updateBillsbyResidentilauit.billAmount,
        //additionalCharges: 
        generalCharges: this.updateBillsbyResidentilauit.additionalCharges,
        billYear: this.currentYear,
        billType: this.billsData.billType,
        isCombinedBill: this.billgeneratetype == "Combined" ? true : false,
        totalAmount: this.billsForm.get('totalAmount')?.value,

        billDays: this.billsForm.get('billedDays')?.value,
        billStatus: this.billsForm.get('billStatus')?.value,
        OtherCharges: this.generalCharges,
        //utilityType: this.billsForm.get('utilityType')?.value,
      }

      let electricity: any[] = [];
      let gas: any[] = [];
      let water: any[] = [];
      if (this.electricity_array.length > 0) {
        this.updateBillsbyResidentilauit.electricity.forEach((item: any) => {
          electricity.push({
            macAddress: item.macAddress,
            unitRateEB: item.unitRateEB,
            unitRateDG: item.unitRateDG,
            type: 'Electricity',
            utilityMasterId: item.utilityMasterId,
            electricityPreviousMeterReadingEB: item.electricityPreviousMeterReadingEB,
            electricityCurrentMeterReadingEB: item.electricityCurrentMeterReadingEB,
            noofElectricityUnitsConsumedEB: item.noofElectricityUnitsConsumedEB,
            electricityPreviousMeterReadingDG: item.noofElectricityUnitsConsumedEB,
            electricityCurrentMeterReadingDG: item.electricityCurrentMeterReadingDG,
            noofElectricityUnitsConsumedDG: item.noofElectricityUnitsConsumedDG,
            billAmount: item.billAmount == null ? 0 : item.billAmount,
            totalAmount: item.totalAmount == null ? 0 : item.totalAmount,
            // otherCharges: item.otherCharges,

          })



        })
      }
      if (this.gas_array.length > 0) {
        this.updateBillsbyResidentilauit.gas.forEach((item: any) => {
          gas.push({
            unitRate: item.UnitRateWater,
            type: 'Gas',
            macAddress: item.macAddress,
            previousMeterReading: item.previousMeterReading,
            currentMeterReading: item.currentMeterReading,
            noofUnitsConsumed: item.noofUnitsConsumed,
            utilityMasterId: item.utilityMasterId,
            billAmount: item.billAmount == null ? 0 : item.billAmount,
            totalAmount: item.totalAmount == null ? 0 : item.totalAmount,
            // otherCharges: item.otherCharges,

          })

        })
      }
      if (this.water_array.length > 0) {
        this.updateBillsbyResidentilauit.water
          .forEach((item: any) => {
            water.push({
              unitRate: item.unitRate,
              type: 'Water',
              utilityMasterId: item.utilityMasterId,
              macAddress: item.macAddress,
              previousMeterReading: item.previousMeterReading,
              currentMeterReading: item.currentMeterReading,
              noofUnitsConsumed: item.noofUnitsConsumed,
              billAmount: item.billAmount == null ? 0 : item.billAmount,
              totalAmount: item.totalAmount == null ? 0 : item.totalAmount,
              //otherCharges: item.otherCharges,
            })


          })
      }
      request.gas = gas,
        request.water = water,
        request.electricity = electricity,

        this.store.dispatch(createBills({ bilss: request }));
    }

  }
  onDateRangeSelected(event: any): void {
    console.log('Selected date:', event);
    this.selectedDate = event;
    console.log(this.selectedDate)

    // Handle the selected date here
  }

  selectedMonth: any;
  Selectdates(event: any) {
    this.selectedDate = event;
    //console.log('Selected date range:', event);
    // Perform your logic here
    //2024-10-16'
    ///const input = event.target as HTMLInputElement;
    // console.log('Selected Date:', input.value);
    // console.log(event)
    //  if (input.value !== undefined) {

    if (this.selectedDate) {
      this.billsControls['selectBillForMonth'].clearValidators();
      this.billsControls['selectBillForMonth'].updateValueAndValidity();

      if (this.selectedresidentilaUnitId) {
        if (this.billgeneratetype == 'Individual') {
          if (this.selectedMeterNos.length == 0 || this.selectedUtilityType.length == 0) {
            // this.resetCalculations(undefined);
            let message = this.selectedUtilityType.length == 0 ? 'Select utlity ' : 'Select Meter Number';
            this.sharedService.showMessageDialog(message, '', SweetAlertIcon.ERROR);
            return;
          }
        }
        let filterdata = this.utilityMetersList.filter((item: any) => item.umdId == this.selectedMeterNos);
        console.log(filterdata)
        let MacAddress = this.billgeneratetype.toLowerCase() == 'individual' ? filterdata[0].macAddress : '';
        let request = {
          residentialUnitId: this.selectedresidentilaUnitId,
          projectId: this.projectId,
          macAddress: MacAddress,
          // fromDate: this.formatDate(event[0]),
          // toDate: input.value,
          toDate: this.selectedDate,
          isCombined: this.billgeneratetype == "Combined" ? true : false
        }
        this.store.dispatch(getBillReadingsByMonthAdhocList({ adhocBills: request }));
        this.bindBillinfoDetailsForAdhoc();
      } else {
        this.sharedService.showMessageDialog('Select Residential Unit', '', SweetAlertIcon.ERROR);
      }
    }


  }

  monthChange(event: any) {
    //console.log(this.selectedMeterNos)
    this.store.dispatch(resetBillReadingsInfoState());
    this.water_array.clear();
    this.gas_array.clear();
    this.electricity_array.clear();
    if (this.selectedresidentilaUnitId) {
      if (this.billgeneratetype == 'Individual') {
        if (this.selectedMeterNos.length == 0 || this.selectedUtilityType.length == 0) {
          this.billsForm.controls['selectBillForMonth'].setValue('');
          let message = this.selectedUtilityType.length == 0 ? 'Select utlity ' : 'Select Meter Number';
          this.sharedService.showMessageDialog(message, '', SweetAlertIcon.ERROR);
          return;
        }
      }
      this.selectedMonth = event;
      let monthName: any;
      let filterdata = this.utilityMetersList.filter((item: any) => item.umdId == this.selectedMeterNos);
      //console.log(filterdata)
      let MacAddress = this.billgeneratetype == 'Individual' ? filterdata[0].macAddress : '';
      monthName = this.selectedMonth.name.slice(0, 3).toUpperCase();
      let IsCombined = this.billgeneratetype == "Combined" ? true : false;
      this.store.dispatch(
        getBillReadingsByMonthList({ id: this.selectedresidentilaUnitId, projectId: this.projectId, BillMonth: monthName, MacAddress: MacAddress, IsCombined: IsCombined })
      );
      this.bindBillinfoDetailsForManual();

    } else {
      this.sharedService.showMessageDialog('Select Residential Unit', '', SweetAlertIcon.ERROR);
    }

  }
  async residentialUnitChange(event: any) {

    // this.loaderService.setLoading(true)
    this.store.dispatch(resetBillReadingsInfoState()); // Optionally reset state
    this.selectedresidentilaUnitId = event?.residentialUnitId;
    console.log(this.selectedresidentilaUnitId)
    this.selectedUtilityType = [];

    console.log(event)


    if (event == undefined) {
      await this.resetCalculations(undefined);
      this.billsForm.controls['consumer'].setValue('');
      this.utilityTypeList = [];
      this.selectedUtilityType = [];
      this.loaderService.setLoading(false)
      return
    }


    if (event?.consumerName == '' || event?.utilityData.length == 0 || event?.utilityMeters.length == 0) {
      let message = event.utilityData.length == 0 ? ` ${event.residentialUnit}:The utility was not found.` : `  ${event.residentialUnit}: The meter was not found.`;
      this.sharedService.showMessageDialog('', message, SweetAlertIcon.ERROR);
      this.utilityMetersList = []
      this.selectedMeterNos = []
      this.utilityTypeList = [];
      this.selectedUtilityType = []
      this.billsForm.controls['LastMonthBillGeneratedDate'].setValue(null);
      this.billsForm.controls['consumer'].setValue(event.consumerName);

    } else {
      if (this.selectedresidentilaUnitId) {
        await this.resetCalculations(undefined)
      }

      this.billsForm.controls['consumer'].setValue(event.consumerName);
      this.billsForm.controls['ResidentialUnit'].setValue(event?.residentialUnitId);
      this.selectedConsumerId = event.consumerId;
      this.selectedResidentialunit = event;
      this.utilityTypeList = event.utilityData;
      if (this.billgeneratetype == "Combined") {
        this.utilityMetersList = event.utilityMeters;
        let date = this.formatDate(this.utilityMetersList[0].previousBillDate);
        console.log(date)
        this.billsForm.controls['startdate'].setValue(date);
        this.billsForm.controls['LastMonthBillGeneratedDate'].setValue(date);

        let utlityArray: any[] = [];
        let meter: any[] = [];

        this.utilityTypeList.forEach((item: any) => {
          utlityArray.push(item.utilityId)
        })
        this.utilityMetersList.forEach((item: any) => {
          meter.push(item.umdId)
        })
        this.selectedMeterNos = meter;
        this.selectedUtilityType = utlityArray;
        /*  this.store.dispatch(
           getBillReadingsInfoList({ id: event.residentialUnitId, projectId: this.projectId, MacAddress: '' })
         ); */

        // this.bindBillinfoDetails();
      }
    }


  }
  resetCalculations(event: any) {
    this.selectedMeterNos = [];
    this.billsForm.controls['selectBillForMonth'].setValue('');
    this.billsForm.controls['totalAmount'].setValue('');
    this.billsForm.controls['billStatus'].setValue('');
    this.billsForm.controls['startdate'].setValue('');
    this.billsForm.controls['endDate'].setValue('');
    this.billsForm.controls['LastMonthBillGeneratedDate'].setValue('');
    this.billsForm.controls['billGeneratedDate'].setValue('');
    this.billsForm.controls['SelectDatesForBillGaneration'].setValue('');
    this.billsForm.controls['billedDays'].setValue('');
    this.gas_array.clear()
    this.water_array.clear()
    this.electricity_array.clear();
    this.otherCharge = []

  }
  utilityChange(event: any) {
    //console.log(event)
    if (event == undefined) {
      this.utilityMetersList = [];
    }
    this.store.dispatch(resetBillReadingsInfoState())
    this.resetCalculations(undefined) /// to reste form values
    let meter: any[] = [];

    this.selectedResidentialunit?.utilityMeters.forEach((item: any) => {
      if (item.utilityId == event.utilityId) {
        meter.push(item);
      }
    })
    this.utilityMetersList = meter;
  }
  meterChange(event: any) {
    if (event == undefined) {
      this.resetCalculations(undefined)
    } else {
      this.billsForm.controls['SelectDatesForBillGaneration'].setValue('');
      this.store.dispatch(resetBillReadingsInfoState())
    }
  }

  aggregateAmounts = (data: any) => {
    const result = data.reduce((acc: any, current: any) => {
      if (!acc[current.name]) {
        acc[current.name] = { name: current.name, amount: current.amount };
      } else {
        acc[current.name].amount += current.amount;
      }
      return acc;
    }, {});

    return Object.values(result);
  };
  ele_otherCgarges: any[] = [];
  water_otherCgarges: any[] = [];
  gas_otherCgarges: any[] = [];

  generalCharges: any[] = []
  updateBillsbyResidentilauit: any = {};

  isInvalidBill: boolean = false;
  content: string = ''
  dailog() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        allowOutsideClick: false,
        // title: 'Session out',
        text: this.content,
        icon: 'warning',
        confirmButtonText: 'Ok',
        /// cancelButtonText: 'No, Cancel',
        showCancelButton: false,
        // timer: 20000, // close automatically after timeout
        //timerProgressBar: true,
      })
      .then((result) => {
        console.log(result, 'result');
        if (result.value) {
          this.router.navigate(['/bills']);
        }
      });
  }
  otherCharge: any[] = [];
  isdisabled: boolean = true;
  updateBillStatus(res: any) {
    this.isdisabled = false;
    this.isInvalidBill = res.isInvalidBill;
    this.water_array.clear();
    this.gas_array.clear();
    this.electricity_array.clear();
    this.loaderService.setLoading(true);
    this.updateBillsbyResidentilauit = {};
    this.updateBillsbyResidentilauit = res;
    //this.otherCharge = this.updateBillsbyResidentilauit?.billCharges
    this.otherCharge = this.updateBillsbyResidentilauit?.commonCharges
    this.generalCharges = this.aggregateAmounts(this.updateBillsbyResidentilauit?.commonCharges);

    this.billsForm.patchValue({
      billGeneratedDate: this.formatDate(res.currentBillGenerateDate),
      startdate: this.formatDate(res.lastBillGenerateDate), //previous Month Bill generated Date
      LastMonthBillGeneratedDate: this.formatDate(res.lastBillGenerateDate),//previous Month Bill generated Date
      billedDays: this.updateBillsbyResidentilauit.billedDays,
      totalAmount: this.updateBillsbyResidentilauit.totalAmount,
      billStatus: this.updateBillsbyResidentilauit.billStatus,

    })
    this.updateBillsbyResidentilauit?.electricity.forEach((item: any, index: number) => {
      this.add_electricity_Row(item, index);
      this.ele_otherCgarges = item.otherCharges;
      //console.log(this.ele_otherCgarges)

    })
    this.updateBillsbyResidentilauit?.gas.forEach((item: any, index: number) => {
      this.add_gas_Row(item, index);
      this.gas_otherCgarges = item.otherCharges;

    })
    this.updateBillsbyResidentilauit?.water.forEach((item: any, index: number) => {
      this.add_water_Row(item, index);
      this.water_otherCgarges = item.otherCharges;

    })
    this.updateBillsbyResidentilauit?.gas.forEach((item: any, index: number) => {
      let formarray = this.billsForm.controls['gas_array'] as FormArray
      formarray.at(index).patchValue({
        unitRateGas: item.unitRate,
        macAddress: item.macAddress,
        previousMeterReadingGas: item.previousMeterReading,
        currentMeterReadingGas: item.currentMeterReading,
        noOfUnitsConsumedGas: item.noofUnitsConsumed,
      })
    })
    this.updateBillsbyResidentilauit?.water.forEach((item: any, index: number) => {
      let formarray = this.billsForm.controls['water_array'] as FormArray
      formarray.at(index).patchValue({
        unitRateWater: item.unitRate,
        macAddress: item.macAddress,
        previousMeterReadingWater: item.previousMeterReading,
        currentMeterReadinWater: item.currentMeterReading,
        noOfUnitsConsumedWater: item.noofUnitsConsumed,
      })
    })
    this.updateBillsbyResidentilauit?.electricity.forEach((item: any, index: number) => {
      let formarray = this.billsForm.controls['electricity_array'] as FormArray
      formarray.at(index).patchValue({
        unitRateEB: item.unitRateEB,
        unitRateDG: item.unitRateDG,
        previousMeterReadingEB: item.electricityPreviousMeterReadingEB,
        currentMeterReadingEB: item.electricityCurrentMeterReadingEB,
        noOfUnitsConsumedEB: item.noofElectricityUnitsConsumedEB,
        previousMeterReadingDG: item.electricityPreviousMeterReadingDG,
        currentMeterReadingDG: item.electricityCurrentMeterReadingDG,
        noOfUnitsConsumedDG: item.noofElectricityUnitsConsumedDG,
      })
    })
    this.loaderService.setLoading(false);
  }


  bindBillinfoDetailsForAdhoc() {
    this.store.pipe(select(getBillReadingsByMonthAdhocResponse)).subscribe(
      (res: any) => {
        if (res) {
          this.updateBillStatus(res)
          /* if (res.billStatus !== '') {
            if (res.billStatus == 'paid') {
              this.content = res.message;
              this.isdisabled = true;
              this.dailog();
            } else {
              if (res.isInvalidBill == true) {
                this.updateBillStatus(res)
              } else {
                this.isdisabled = true
                this.content = res.message;
                this.dailog();
              }
            }
          } else {
            if (res.isInvalidBill == false) {
              this.updateBillStatus(res);
            }
            
          } */
        }

      });
  }
  bindBillinfoDetailsForManual() {
    let getBillingInfo$ = this.store.pipe(select(getBillReadingsByMonthResponse));
    getBillingInfo$.pipe(
      take(2)).subscribe((res: any) => {
        if (res) {
          console.log(res)
          if (res.billStatus !== '') {
            if (res.billStatus == 'paid') {
              this.content = res.message;
              this.isdisabled = true;
              this.dailog();
            } else {
              if (res.isInvalidBill == true) {
                this.updateBillStatus(res);
              } else {
                this.isdisabled = true
                this.content = res.message;
                this.dailog();
              }
            }
          } else {
            this.isdisabled = true;
            this.content = res.message;
            this.dailog();
          }


        }
      })
  }

  formatDate(isoString: any) {
    const date = new Date(isoString);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

}
