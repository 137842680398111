import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpCreateResidentialUnitComponent } from './rp-create-residential-unit/rp-create-residential-unit.component';
import { RpResidentialUnitComponent } from './rp-residential-unit/rp-residential-unit.component';


const routes: Routes = [

  { path: 'residentialUnit', component: RpResidentialUnitComponent },
  { path: 'residentialUnit/createResidentialUnit', component: RpCreateResidentialUnitComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResidentialUnitRoutingModule { }
