import { MappingModule } from './rp-mapping/mapping.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectModule } from './projects/projects.module';
import { MastersModule } from './masters/masters.module';
import { UsersModule } from './users/users.module';
import { FaqModule } from './faq/faq.module';
import { ConsumersModule } from './consumers/consumers.module';
import { MetersModule } from './meters/meters.module';
import { ResidentialUnitModule } from './residential-units/residentialunit.module';
import { WalletModule } from './wallet/wallet.module';
import { InvoiceModule } from './invoice/invoice.module';
import { AlertsModule } from './alerts/alerts.module';
import { UtilitiesModule } from './utilities/utilities.module';
import { BillsModule } from './bills/bills.module';

import { ConsumerPostpaidModule } from './consumer-postpaid/consumer-postpaid.module';
//import { BillHistoryModule } from './bill-history/bills-history.module';
import { MeterControlModule } from './meter-control/meter-control.module';
import { ConsumerWalletModule } from './consumer-wallet/consumer-wallet.module';
import { RechargeHistoryModule } from './recharge-history/recharge-history.module';
import { ConsumersMeterDetailModule } from './consumer-meter-details/consumers-meter-details.module';
import { ConsumerFaqModule } from './consumer-faq/consumer-faq.module';
import { FeedbackHistoryModule } from './feedback-history/feedback-history.module';
import { FeaturesRoutingModule } from './features-routing.module';
import { RpDashboardComponent } from './rp-dashboard/rp-dashboard.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { ConsumptionHistoryModule } from './consumption-history/consumption-history.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { PrepaidAdminDashboardComponent } from './prepaid-admin-dashboard/prepaid-admin-dashboard.component';
import { PostpaidAdminDashboardComponent } from './postpaid-admin-dashboard/postpaid-admin-dashboard.component';
import { PostpaidConsumerDashboardComponent } from './postpaid-consumer-dashboard/postpaid-consumer-dashboard.component';
import { UiSwitchModule } from 'ngx-ui-switch';
//import { PrepaidConsumerDashboardComponent } from './prepaid-consumer-dashboard/prepaid-consumer-dashboard.component';
import { ReportsModule } from './rp-reports/rp-reports.module';
import { NgApexchartsModule } from 'ng-apexcharts';

@NgModule({
  declarations: [
    RpDashboardComponent,
    PrepaidAdminDashboardComponent,
    PostpaidAdminDashboardComponent,
    PostpaidConsumerDashboardComponent,

    //PrepaidConsumerDashboardComponent,
  ],
  imports: [
    MappingModule,
    CommonModule,
    ProjectModule,
    MastersModule,
    ReportsModule,
    UsersModule,
    FaqModule,
    NgApexchartsModule,
    ConsumptionHistoryModule,
    ConsumersModule,
    MetersModule,
    ResidentialUnitModule,
    WalletModule,
    InvoiceModule,
    AlertsModule,
    UtilitiesModule,
    BillsModule,
    MeterControlModule,
    // BillHistoryModule,
    ConsumerPostpaidModule,
    ConsumerWalletModule,
    ConsumersMeterDetailModule,
    RechargeHistoryModule,
    ConsumerFaqModule,
    FeedbackHistoryModule,
    NgSelectModule,
    BsDatepickerModule,

    TimepickerModule,
    BsDropdownModule,
    NgStepperModule,
    CdkStepperModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    DropDownsModule,
    LabelModule,
    ModalModule.forRoot(),
    FeaturesRoutingModule,
    ChartsModule,
    UiSwitchModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FeaturesModule { }
