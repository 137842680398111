import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState } from './dashboard.reducer';

export const getDashboardState =
  createFeatureSelector<DashboardState>('dashboard');

export const getSuperadmindashboardResponse = createSelector(
  getDashboardState,
  (state: DashboardState) => state.getSuperadmindashboardResponse
);

export const getSuperadmindashboardRevenueResponse = createSelector(
  getDashboardState,
  (state: DashboardState) => state.getSuperadmindashboardRevenueResponse
);

export const getSuperadmindashboardRevenueOnDateResponse = createSelector(
  getDashboardState,
  (state: DashboardState) => state.getSuperadmindashboardRevenueOnDateResponse
);

export const getadmindashboardResponse = createSelector(
  getDashboardState,
  (state: DashboardState) => state.getadmindashboardResponse
);
export const getadminFlatwiseConsmptionsdashboardResponse = createSelector(
  getDashboardState,
  (state: DashboardState) => state.getadminFlatwiseConsmptionsdashboardResponse
);

export const getConumerPostpaidResponse = createSelector(
  getDashboardState,
  (state: DashboardState) => state.getConumerPostpaidResponse
);

export const getConumerPostpaidConsumptionResponse = createSelector(
  getDashboardState,
  (state: DashboardState) => state.getConumerPostpaidConsumptionResponse
);

export const getConumerConnectionsDataResponse = createSelector(
  getDashboardState,
  (state: DashboardState) => state.getConumerConnectionsDataResponse
);

//getConumerConnectionsDataResponse