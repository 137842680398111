import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../shared/components/components.module';

import { ConsumerFaqRoutingModule } from './consumer-faq-routing.module';
import { RpConsumerFaqComponent } from './rp-consumer-faq/rp-consumer-faq.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';


@NgModule({
  declarations: [
    RpConsumerFaqComponent,
  ],
  imports: [
    CommonModule,
    ConsumerFaqRoutingModule, ComponentsModule,
    AccordionModule
  ],

})
export class ConsumerFaqModule { }
