import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpWalletComponent } from './rp-wallet/rp-wallet.component';
import { RpWalletHistoryComponent } from './rp-wallet-history/rp-wallet-history.component';


const routes: Routes = [

  { path: 'consumerWallet', component: RpWalletComponent },
  { path: 'walletHistory', component: RpWalletHistoryComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsumerWalletRoutingModule { }
