<div class="container-fluid">

  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>

        <!--  <h4 class="mb-0 font-size-18">Wallet Information</h4> -->
        <!-- <div class="page-title-right">
              <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item active" [routerLink]="'/wallet'"><a href="javascript: void(0);">Wallet</a>
                  </li>
                  
                  <li class="breadcrumb-item">Wallet Details
                  </li>
              
              </ol>
            </div> -->
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">

          <section class="p-2">
            <form>
              <div class="row ">

                <div class="col-lg-3 mb-3">
                  <div class="mb-4">
                    <label for="basicpill-lastname-input">Residential Unit</label>
                    <p>{{walletHistoryList?.residentialUnit}}</p>
                  </div>
                </div>

                <div class="col-lg-3 mb-3" *ngIf="this.walletHistoryList?.maxRechargeAmount>0">
                  <div class="mb-4">
                    <label for="basicpill-lastname-input">Current Balance</label>
                    <p>{{ walletHistoryList?.currentBalance !=undefinde?
                      (walletHistoryList?.currentBalance
                      |currency:'INR':true):'-'}} </p>
                    <!--  <p><span>₹</span> {{walletHistoryList.currentBalance |currency:'INR':true}}</p> -->
                  </div>
                </div>
                <div class="col-lg-3 mb-3">
                  <div class="mb-4">
                    <label for="basicpill-lastname-input">Last recharge amount</label>
                    <p>{{ walletHistoryList?.lastRechargeAmount !=undefinde?
                      (walletHistoryList?.lastRechargeAmount
                      |currency:'INR':true):'-'}} </p>

                  </div>
                </div>
                <div class="col-lg-3 mb-3">
                  <div class="mb-4">
                    <label for="basicpill-lastname-input">Last Recharge Date</label>
                    <p>{{walletHistoryList?.lastRechargeDate!=null?
                      (walletHistoryList?.lastRechargeDate |date:'dd MMM yyyy hh:mm a')
                      :'--' }}
                  </div>
                </div>
                <div class="col-lg-3 mb-3">
                  <div class="mb-4">
                    <form [formGroup]="walletForm">
                      <label>Amount <span class="required">*</span></label>
                      <div class="d-flex">
                        <input type="text" placeholder="Enter Amount" id="amount" formControlName="amount"
                          (input)="onInputChange($event)" class="form-control" />
                        <button class="btn btn-primary ms-3" (click)="payNow()">Recharge</button>
                      </div>
                      @if((submitted || walletFormConntrols['amount'].touched) && walletFormConntrols['amount'].errors){
                      <div class="invalid-feedback">
                        @if(walletFormConntrols['amount'].errors['required']){
                        <div>{{prifileValidations.billCharge.amount}}</div>}

                        @if(walletFormConntrols['amount'].errors['max']){
                        <div>{{prifileValidations.billCharge.max_amount}}:{{max_amount}}</div>}

                        @if(walletFormConntrols['amount'].errors['min']){
                        <div>{{prifileValidations.billCharge.min_amount}}:{{min_amount}}</div>}

                        @if(walletFormConntrols['amount'].errors['pattern']){
                        <div>{{prifileValidations.billCharge.Onlynumberwithdecimals}}</div>}
                      </div>}
                      <!-- @if(max_amount_error){<span class="invalid-feedback">maximum recharge amount is :
                        {{max_amount}}</span>} -->

                    </form>
                  </div>

                  <div class="row">
                    <div class="d-flex">
                      <div class="me-2" *ngFor=" let amt of amountValues">
                        <button type="button" class="btn btn-outline-primary" (click)="setAmount(amt.amount)">
                          ₹{{amt.amount}}</button>
                      </div>

                    </div>

                    <!-- <span class="mb-3 "  *ngFor="let amount of amountValues">
                      <button type="button" class="btn btn-outline-primary" (click)="setAmount(500)"><span>₹</span>
                        {{amount.amount}}</button></span> -->
                  </div>
                </div>


              </div>


            </form>

          </section>


        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>


  <!-- paymet alert sucess -->





  <ng-template #template>
    <div class="modal-header ">
      <h5 class="modal-title">Add Occupants</h5>
      <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
        (click)="paymentlaertModal.hide()"></button>
    </div>
    <div class="modal-body">
      <div class="main-payment">
        <div class="align-center">
          <div class="tick-success">
            <img src="../../../assets/images/tick-circle.png">
          </div>
        </div>
        <div class="text-center mt-5">
          <h3>Payment Success</h3>
          <p class="text-muted">Your payment has been successfully done</p>
          <hr class="mx-auto">
        </div>
        <div class="text-center ">
          <p class="text-muted font-size-18">Total Payment</p>
          <h3><span>₹</span> 1685.75</h3>
          <hr class="mx-auto">
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="p-2 mt-3 mx-2">
              <table class="table">

                <tr>
                  <td class="text-muted">Name</td>
                  <td class="float-end font-weight-bolder  font-size-14">Swetha Mallecheruvu</td>
                </tr>
                <tr>
                  <td class="text-muted">Flat#</td>
                  <td class="float-end font-weight-bolder ">200</td>
                </tr>
                <tr>
                  <td class="text-muted">Payment ID</td>
                  <td class="float-end font-weight-bolder ">12345655524444</td>
                </tr>

                <tr>
                  <td class="text-muted">Date & Time</td>
                  <td class="float-end font-weight-bolder ">06 Jun 2024 ; 18:05:03</td>
                </tr>

              </table>


            </div>
          </div>

        </div>

        <div class=" row mt-4">
          <div class="col-lg-12 w-80 mx-auto text-center d-flex flex-column">
            <p class="text-primary">Thank you for choosing Ripple Services</p>
            <button class="btn btn-primary"><i class="bx bx-download me-2"></i> Download Receipt</button>
            <button class="btn btn-outline-primary mt-2 mb-4"> Back to Homepage</button>
          </div>

        </div>
      </div>
    </div>

  </ng-template>

</div>