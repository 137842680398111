import { drawing } from '@progress/kendo-drawing';
import { SankeyElement } from './element';
import { deepExtend } from '../common';
import { defined } from '../drawing-utils';
export class Link extends SankeyElement {
  getElement() {
    const link = this.options.link;
    const {
      source,
      target,
      y0,
      y1
    } = link;
    const xC = (source.x0 + target.x1) / 2;
    return new drawing.Path(this.visualOptions()).moveTo(source.x1, y0).curveTo([xC, y0], [xC, y1], [target.x0, y1]);
  }
  visualOptions() {
    const options = this.options;
    const link = this.options.link;
    return {
      stroke: {
        width: options.link.width,
        color: link.color || options.color,
        opacity: defined(link.opacity) ? link.opacity : options.opacity
      }
    };
  }
}
export const resolveLinkOptions = (link, options, sourceNode, targetNode) => {
  const linkOptions = deepExtend({}, options, {
    link,
    opacity: link.opacity,
    color: link.color,
    colorType: link.colorType,
    visual: link.visual,
    highlight: link.highlight
  });
  if (linkOptions.colorType === 'source') {
    linkOptions.color = sourceNode.options.fill.color;
  } else if (linkOptions.colorType === 'target') {
    linkOptions.color = targetNode.options.fill.color;
  }
  return linkOptions;
};