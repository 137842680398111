import { createAction, props } from '@ngrx/store';
import { Umd } from './umd.models';

// Umd creation
export const GetUmd = createAction(
  '[Umd] getUmd',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    projectId?: any;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getUmdSuccess = createAction(
  '[Umd] GetUmd Success',
  props<{ getUmdResponse: any }>()
);
export const getUmdFailure = createAction(
  '[Umd] GetUmd Failure',
  props<{ getUmdError: any }>()
);

// AllUmd creation 
export const GetAllUmd = createAction(
  '[Umd] getAllUmd',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    projectId?: any;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllUmdSuccess = createAction(
  '[Umd] GetAllUmd Success',
  props<{ getAllUmdResponse: any }>()
);
export const getAllUmdFailure = createAction(
  '[Umd] GetAllUmd Failure',
  props<{ getAllUmdError: any }>()
);

// Create Umd
export const createUmd = createAction(
  '[Umd] CreateUmd',
  props<{ UmdData: Umd }>()
);
export const CreateUmdSuccess = createAction(
  '[Umd] CreateUmd Success',
  props<{ createUmdResponse: any }>()
);
export const CreateUmdFailure = createAction(
  '[Umd] CreateUmd Failure',
  props<{ createUmdError: any }>()
);

// Update Umd
export const updateUmd = createAction(
  '[Umd] UpdateUmd',
  props<{ UmdData: Umd; id: string }>()
);
export const updateUmduccess = createAction(
  '[Umd] updateaster Success',
  props<{ updateUmdResponse: any }>()
);
export const updateUmdFailure = createAction(
  '[Umd] updateUmd Failure',
  props<{ updateUmdError: any }>()
);

//delete Umd
export const deleteUmd = createAction(
  '[Umd] deleteUmd',
  props<{ id: string; projectId?: string }>()
);
export const deleteUmduccess = createAction(
  '[Umd] deleteUmd Success',
  props<{ deleteUmdResponse: any }>()
);
export const deleteUmdFailure = createAction(
  '[Umd] deleteUmd Failure',
  props<{ deleteUmdError: any }>()
);

export const GetElectricityStatus = createAction(
  '[Umd] GetElectricityStatus',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    projectId?: any;
    filter?: string;
    orderBy?: string;
  }>()
);
export const GetElectricitySuccessStatus = createAction(
  '[Umd]GetElectricitySuccessStatus',
  props<{ getElectricitySuccessResponse: any }>()
);

export const GetElectricityFailureStatus = createAction(
  '[Umd]GetElectricityFailureStatus',
  props<{ getElectricityFailureResponse: any }>()
);

export const GetAllElectricityStatus = createAction(
  '[Umd] GetAllElectricityStatus',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    projectId?: any;
    filter?: string;
    orderBy?: string;
  }>()
);
export const GetAllElectricitySuccessStatus = createAction(
  '[Umd]GetAllElectricitySuccessStatus',
  props<{ GetAllElectricitySuccessResponse: any }>()
);

export const GetAllElectricityFailureStatus = createAction(
  '[Umd]GetAllElectricityFailureStatus',
  props<{ GetAllElectricityFailureResponse: any }>()
);

export const resetUmdState = createAction('[resetUmdState ] resetUmdState');
export const resetElectricityState = createAction(
  '[resetElectricityState ] resetElectricityState'
);
