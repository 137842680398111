<!-- <div bsModal tabindex="-1">
  <div class="modal-dialog modal-dialog-centered"> -->



<div class="modal-content">
  <div class="modal-header bg-primary text-white ">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
      (click)="dismissModal()"></button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="createMappingForm">

      <div class="mb-3">
        <label>Utility Measuring Device(Umd) <span class="required">*</span></label>
        <ng-select [items]="unmappedUmdList" bindLabel="macAddress" bindValue="id" formControlName="utilityId" id="type"
          [multiple]="true"></ng-select>
        @if(submitted && createMappingForm.controls['utilityId'].errors) {
        <div class="invalid-feedback">
          @if(createMappingForm.controls['utilityId'].errors['required']) {
          <div>{{Mapping_Validations.utilityId.required}}</div>}
        </div>}

      </div>


    </form>


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary waves-effect waves-light" (click)="CreateMapping('Create')">Save
      Mapping</button>
  </div>
</div><!-- /.modal -->
<!-- </div>
</div> -->