import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpWalletComponent } from './rp-wallet/rp-wallet.component';
import { RpWalletDetailsComponent } from './rp-wallet-details/rp-wallet-details.component';

const routes: Routes = [
  { path: 'wallet', component: RpWalletComponent },
  { path: 'wallet/walletDetails', component: RpWalletDetailsComponent },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WalletRoutingModule { }
