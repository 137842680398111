import { Component, ViewChild } from '@angular/core';
import { FilterableSettings, DataBindingDirective } from "@progress/kendo-angular-grid";
import { SVGIcon, filePdfIcon, fileExcelIcon } from "@progress/kendo-svg-icons";
import { process } from "@progress/kendo-data-query";
import { alertsData } from './alerts-data';

@Component({
  selector: 'app-rp-alerts',
  templateUrl: './rp-alerts.component.html',
  styleUrl: './rp-alerts.component.scss'
})
export class RpAlertsComponent {

  public filterMode: FilterableSettings = "row";
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;

  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;

  selectValue!: string[];

  public gridData: unknown[] = alertsData;
  public gridView!: unknown[];


  constructor() {
  
  }
  


  public onFilter(value: Event): void {
    const inputValue = value;

    this.gridView = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "Utility",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "MacAddress",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "MeterStatus",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "CurrentReading",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "Occupant",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "ResidentialUnit",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "Onrequestsent",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "Offrequestsent",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;

  }

  

  ngOnInit(): void {
    this.gridView = this.gridData;

  }

}
