import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FilterableSettings, DataBindingDirective } from "@progress/kendo-angular-grid";
import { SVGIcon, filePdfIcon, fileExcelIcon } from "@progress/kendo-svg-icons";
import { process } from "@progress/kendo-data-query";
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { billsData } from '../rp-bills/bills-data';
import { createinvildBills, getBillsByIdlist, Paycashbill, resetbillsState } from '../../../store/Bills/bills.action';
import { Router } from '@angular/router';
import { Store, compose, select } from '@ngrx/store';
import { LoaderService } from '../../../core/services/loader.service';
import { getBillsByIdResponse } from '../../../store/Bills/bills-selector';
import { take } from 'rxjs';
import { url } from 'inspector';
import { SharedService } from '../../../shared/shared.service';


@Component({
  selector: 'app-rp-bills-view',
  templateUrl: './rp-bills-view.component.html',
  styleUrl: './rp-bills-view.component.scss',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .k-grid .k-grid-content td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `,
  ],
})
export class RpBillsViewComponent {

  billsDataById = Object.assign({});
  isAllOpen: boolean = true;
  modalRef!: BsModalRef;
  modalRefInvoice!: BsModalRef;
  billapidmodal!: BsModalRef;
  reason: any
  viewBilldeatils = Object.assign({});
  breadCrumbItems: Array<{}> | undefined;
  public waterForm: FormGroup;
  public electricityForm: FormGroup;
  public paybycashform!: FormGroup;

  public filterMode: FilterableSettings = "row";
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  public mySelection: string[] = [];


  // file upload
  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
  manualBillGrid: boolean = true;
  manualBillView: boolean = false;
  adhocBillGrid: boolean = true;
  adhocBillView: boolean = false;
  billPaymentDate: any;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  undefinde: any;
  currentUser: any;
  projectId: any
  billingType: any
  constructor(private sharedService: SharedService, private modalService: BsModalService, private fb: FormBuilder, private router: Router,
    private store: Store) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectId = this.currentUser?.project.id;
    this.billGenerationDate = this.currentUser.billGenerationDate;
    this.billingType = this.currentUser.project.billingType;
    if (history.state.bilss != undefined && history.state.bilss !== null) {
      this.billsDataById = history.state.bilss;
      console.log(this.billsDataById)
      this.sharedService.setbilldeatilsById(this.billsDataById)
    }
    console.log(this.billsDataById)
    this.breadCrumbItems = [{ label: 'Bills', url: 'bills', active: true }, { label: 'Bills Details', }];
    this.waterForm = this.fb.group({
      water: this.fb.array([this.addWaterGroup()]),
    });

    this.paybycashform = this.fb.group({
      amount: [''],
      billPaymentDate: [null, Validators.required],
      reason: ['', Validators.required],
    })

    this.electricityForm = this.fb.group({
      electricity: this.fb.array([this.addElectricityGroup()]),
    });
  }
  billGenerationDate: any
  minDate: any;
  today: any;
  ngOnInit(): void {
    //console.log(this.formatDate(this.billGenerationDate))
    console.log(this.billGenerationDate);
    this.today = new Date().toISOString().split('T')[0];
    console.log(this.billingType)
    this.minDate = this.billingType == 'Postpaid' ? this.formatDate(this.billGenerationDate) : this.today
    //console.log(this.minDate)

    /* if (Object.keys(this.billsDataById).length > 0) {
      this.store.dispatch(resetbillsState()); 
       this.store.dispatch(  getBillsByIdlist({ id: this.billsDataById.id }));
      this.getbillsByid()
    } */
  }

  formatDate(isoString: any) {
    const date = new Date(isoString);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
  buttondisabled: boolean = true
  billPaymentDateChange(event: any) {
    const dt = this.paybycashform.get('billPaymentDate')?.value;
    console.log(dt)
    console.log(event)
    if (dt) {
      this.buttondisabled = false;
    }
  }
  getbillsByid() {
    let getBillsById$ = this.store.pipe(select(getBillsByIdResponse));
    getBillsById$.pipe(take(2)).subscribe((res: any) => {
      if (res) {
        this.viewBilldeatils = res;
        this.paybycashform.patchValue({
          amount: res.totalAmount
        })
      }
    })

  }

  get paybycashControls() {
    return this.paybycashform.controls;
  }
  payByCash() {
    console.log(this.paybycashform.invalid)
    if (this.paybycashform.invalid) {
      return;
    } else {
      let request = {
        "billId": this.billsDataById.id,
        "amount": this.paybycashform.get('amount')?.value,
        "paidDate": this.paybycashform.get('billPaymentDate')?.value,
        reason: this.paybycashform.get('reason')?.value
      }
      this.store.dispatch(Paycashbill({ bilss: request }));
      this.billapidmodal.hide()
    }
  }
  saveInvlaidBills() {
    this.store.dispatch(
      createinvildBills({ reason: this.reason, id: this.billsDataById.id })
    );
    this.modalRefInvoice.hide()

  }
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  manualBill(event: any) {
    this.manualBillGrid = false;
    this.manualBillView = true;
  }

  backBillGrid(event: any) {
    this.manualBillGrid = true;
    this.manualBillView = false;
  }


  adhocBill(event: any) {
    this.adhocBillGrid = false;
    this.adhocBillView = true;
  }

  backAdhocBill(event: any) {
    this.adhocBillGrid = true;
    this.adhocBillView = false;
  }
  deleteUtilities() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2'
      },
      buttonsStyling: false
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true
      })
      .then(result => {
        if (result.value) {
          swalWithBootstrapButtons.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
        }
      });
  }
  //Append Fields Set
  private addWaterGroup(): FormGroup {
    return this.fb.group({
      slabName: [],
      min: [],
      max: [],
      slabPerUnit: [],
    });
  }

  //Add Fields
  addWater(): void {
    this.waterArray.push(this.addWaterGroup());
  }

  //Remove Fields
  removeWater(index: number): void {
    this.waterArray.removeAt(index);
  }

  //Fields Array
  get waterArray(): FormArray {
    return <FormArray>this.waterForm.get('water');
  }

  //Append Fields Set
  private addElectricityGroup(): FormGroup {
    return this.fb.group({
      slabName: [],
      minKwh: [],
      maxKwh: [],
      slabPerUnitPrice: [],
    });
  }

  //Add Fields
  addElectricity(): void {
    this.electricityArray.push(this.addElectricityGroup());
  }

  //Remove Fields
  removeElectricity(index: number): void {
    this.electricityArray.removeAt(index);
  }

  //Fields Array
  get electricityArray(): FormArray {
    return <FormArray>this.electricityForm.get('electricity');
  }


  /**
  * Open center modal
  * @param invalidBillModal center modal data
  */
  invalidBillsModal(invalidBillModal: any) {
    this.reason = ''
    this.modalRefInvoice = this.modalService.show(invalidBillModal, { class: 'modal-dialog-centered' });

  }


  markASPaid(paidBillModal: any) {
    this.reason = ''
    this.billapidmodal = this.modalService.show(paidBillModal, { class: 'modal-dialog-centered' });

  }










}

