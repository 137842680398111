import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsumerPostpaidRoutingModule } from './consumer-postpaid-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { GridModule, PDFModule, ExcelModule, } from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SortableModule } from "@progress/kendo-angular-sortable";
import { UploadsModule } from '@progress/kendo-angular-upload';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { RpPostpaidBillsComponent } from './rp-postpaid-bills/rp-postpaid-bills.component';
import { ComponentsModule } from '../../shared/components/components.module';

@NgModule({
  declarations: [
    RpPostpaidBillsComponent,
  ],
  imports: [
    CommonModule,
    ConsumerPostpaidRoutingModule,
    NgSelectModule,
    BsDatepickerModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    ModalModule,
    UiSwitchModule,
    SortableModule,
    UploadsModule,
    AccordionModule, ComponentsModule
  ],

})
export class ConsumerPostpaidModule { }
