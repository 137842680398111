export const walletData = [
    { ProjectCode: 'Anuj Tatla', ProjectName: 'Yojith', Region: '₹ 5000', Utility:'07-03-2024', BillDate:'6727', ProjectType:'John Doe', ProjectStatus:'Active', Unit:'Welding Service'},
    { ProjectCode: 'Somil Singh', ProjectName: 'Bhavin', Region: '₹ 5000', Utility:'01-04-2024', BillDate:'6727', ProjectType:'John Doe', ProjectStatus:'Active', Unit:'Theatre Artist'},
    { ProjectCode: 'Nikita Kumar', ProjectName: 'Bhavin', Region: '₹ 5000', Utility:'16-02-2024', BillDate:'6727', ProjectType:'John Doe', ProjectStatus:'Active',Unit:'Masonry Worker' },
    { ProjectCode: 'Anand Kumar', ProjectName: 'Hridaan', Region: '₹ 2323', Utility:'03-04-2024', BillDate:'2323', ProjectType:'John Doe', ProjectStatus:'Active',Unit:'Forensic Chemist' },
    { ProjectCode: 'Akhilesh Singh', ProjectName: 'Bhavin', Region: '₹ 5230', Utility:'25-03-2024', BillDate:'5232', ProjectType:'John Doe', ProjectStatus:'Inactive',Unit:'Chief Marketing Officer' },
    { ProjectCode: 'Prashant Apte', ProjectName: 'Yojith', Region: '₹ 6000', Utility:'01-02-2024', BillDate:'4435', ProjectType:'John Doe', ProjectStatus:'Inactive',Unit:'Catering manager' },
    { ProjectCode: 'Ananya Grover', ProjectName: 'Bhavin', Region: '₹ 2000', Utility:'11-03-2024', BillDate:'5765', ProjectType:'John Doe', ProjectStatus:'Active', Unit:'Account Manager'},
    { ProjectCode: 'Ishani Ghosh', ProjectName: 'Bhavin', Region: '₹ 3223', Utility:'06-01-2024', BillDate:'6234', ProjectType:'John Doe', ProjectStatus:'Active',Unit:'Account Manager' },
    { ProjectCode: 'Ishani Tatla', ProjectName: 'Hridaan', Region: '₹ 5000', Utility:'08-03-2024', BillDate:'2133', ProjectType:'John Doe', ProjectStatus:'Inactive', Unit:'Catering manager'},
    { ProjectCode: 'Deshad Bhatt', ProjectName: 'Bhavin', Region: '₹ 5000', Utility:'19-01-2024', BillDate:'3232', ProjectType:'John Doe', ProjectStatus:'Active',Unit:'Account Manager' },
    
];