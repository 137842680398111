import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpDashboardComponent } from './rp-dashboard/rp-dashboard.component';
import { PrepaidAdminDashboardComponent } from './prepaid-admin-dashboard/prepaid-admin-dashboard.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { PostpaidAdminDashboardComponent } from './postpaid-admin-dashboard/postpaid-admin-dashboard.component';
import { PostpaidConsumerDashboardComponent } from './postpaid-consumer-dashboard/postpaid-consumer-dashboard.component';
var Role = { admin: 'Admin', super_Admin: 'Super Admin' };

const routes: Routes = [
  { path: '', component: RpDashboardComponent },
  { path: 'dashboard', component: RpDashboardComponent },
  { path: 'admindashboard', component: PrepaidAdminDashboardComponent },
  { path: 'postpaidDashboard', component: PostpaidAdminDashboardComponent },
  {
    path: 'consumerdashboard',
    component: PostpaidConsumerDashboardComponent,
  },
  /* {
    path: 'prepaidConsumerDashboard',
    component: PrepaidConsumerDashboardComponent,
  }, */
  {
    path: 'projects',
    loadChildren: () =>
      import('../features/projects/projects.module').then(
        (project) => project.ProjectModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('../features/users/users.module').then((user) => user.UsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'Consumers',
    loadChildren: () =>
      import('../features/consumers/consumers.module').then(
        (consumers) => consumers.ConsumersModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('../features/faq/faq.module').then((faq) => faq.FaqModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'masters',
    loadChildren: () =>
      import('../features/masters/masters.module').then(
        (masters) => masters.MastersModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('../features/rp-reports/rp-reports.module').then(
        (reports) => reports.ReportsModule
      ),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeaturesRoutingModule { }
