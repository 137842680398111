import { createFeatureSelector, createSelector } from '@ngrx/store';
import { residentialUnitState } from './residentialUnit.reducer';

export const selectDataState =
  createFeatureSelector<residentialUnitState>('residentilaUnit');

export const getResidentialUnitResponse = createSelector(
  selectDataState,
  (state: residentialUnitState) => state.getResidentialUnitResponse
);

export const createResidentialUnitResponse = createSelector(
  selectDataState,
  (state: residentialUnitState) => state.createResidentialUnitResponse
);

export const getOccupationHistoryResponse = createSelector(
  selectDataState,
  (state: residentialUnitState) => state.getOccupationHistoryResponse
);

export const getResidentialUnitByIdResponse = createSelector(
  selectDataState,
  (state: residentialUnitState) => state.getResidentialUnitByIdResponse
);

export const getAllResidentialUnit_Response = createSelector(
  selectDataState,
  (state: residentialUnitState) => state.getAllResidentialUnit_Response
);


export const getOccupationHistoryExpotyExcelResponse = createSelector(
  selectDataState,
  (state: residentialUnitState) => state.getOccupationHistoryExpotyExcelResponse
);

export const getResidentialHistoryResponse = createSelector(
  selectDataState,
  (state: residentialUnitState) => state.getResidentialHistoryResponse
);
export const getExportResidentialHistoryResponse = createSelector(
  selectDataState,
  (state: residentialUnitState) => state.getExportResidentialHistoryResponse
);
