import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpMeterControlComponent } from './rp-meter-control/rp-meter-control.component'

const routes: Routes = [

  { path: 'meterControl', component: RpMeterControlComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MeterControlRoutingModule { }
