import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { SharedService } from '../../shared/shared.service';
import { Roles, SweetAlertIcon } from '../../shared/constants/enum';
import { MENU } from '../../layouts/rp-sidebar/menu';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private authenticationService: AuthenticationService
  ) {}

  private checkUserRole(role: string, url: string): boolean {
    const menuItem = MENU.find((item) => item.link === url);
    if (menuItem && menuItem.roles.includes(role)) {
      this.sharedService.closeDialog();
      return true;
    }
    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const currentUser = this.authenticationService.currentUserValue;
    if (!currentUser) {
      // User is not logged in
      /*  this.sharedService.showMessageDialog(
         'Session out',
         'Please Login To Access Module',
         SweetAlertIcon.INFO
       ); */
      // alert('Please Login To Access Module');
      localStorage.getItem('currentUser');
      this.router.navigate(['/auth/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }

    const url = state.url.split('?')[0]; // Get the URL without query parameters
    return this.sharedService.getCurrentUser().pipe(
      take(1),
      map((user) => {
        const userRole = user?.currentUserRoleDetails?.role;
        if (userRole && this.checkUserRole(userRole, url)) {
          return true;
        } else {
          if (userRole == Roles.ADMIN) {
            this.sharedService.showMessageDialog(
              'Access Denied',
              'You do not have permission to access this module',
              SweetAlertIcon.ERROR
            );
            this.router.navigate(['/admindashboard']);
            return false;
          } else if (userRole == Roles.SUPERADMIN) {
            this.sharedService.showMessageDialog(
              'Access Denied',
              'You do not have permission to access this module',
              SweetAlertIcon.ERROR
            );
            this.router.navigate(['/dashboard']); // Redirect to a safe default route
            return false;
          } else if (userRole == Roles.CONSUMER) {
            this.sharedService.showMessageDialog(
              'Access Denied',
              'You do not have permission to access this module',
              SweetAlertIcon.ERROR
            );
            this.router.navigate(['/consumerdashboard']); // Redirect to a safe default route
            return false;
          }
          // User is logged in but does not have the necessary role
          this.sharedService.showMessageDialog(
            'Access Denied',
            'You do not have permission to access this module',
            SweetAlertIcon.ERROR
          );
          this.router.navigate(['/dashboard']); // Redirect to a safe default route
          return false;
        }
      })
    );
  }
}
