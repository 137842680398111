<div class="container-fluid">

    <div class="row">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        <!--         <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-0 font-size-18">View Faq's</h4>
            </div>
        </div> -->
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">

                    @if(consumerFaq.length<1){ <div class="text-center">
                        <img src="../../../assets/images/nodata.jpg" alt="" height="250">
                        <h4 class="mt-5">No Data Found</h4>
                </div> }

                @else{
                <accordion [closeOthers]="true" class="accordion custom-accordian" [isAnimated]="true">
                    @for (faq of consumerFaq; track $index) {
                    <accordion-group [isOpen]="$index==0?isFirstOpen: false" [heading]="faq.title"
                        class="accordion-item" #group>
                        <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                            @if(faq.fileUrl){ <i
                                class="mdi mdi-attachment font-size-22 c-mr-1 mdi-attachment-rotate text-primary"
                                (click)="downloadAttachedFile(faq)"></i>}
                            <!-- <i class="mdi mdi-square-edit-outline font-size-18 text-black c-mr-1"
                                (click)="updateFaq(faq)"></i>
                            <i class="mdi mdi-trash-can-outline font-size-18 text-danger c-mr-1"
                                (click)="deleteFaq(faq.id)"></i> -->
                            <i class="mdi font-size-24"
                                [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                        </span>
                        <div class="text-muted">
                            {{faq.content}}
                        </div>
                    </accordion-group>
                    }

                </accordion>
                }
                <!--  <accordion [closeOthers]="true" class="accordion custom-accordian" [isAnimated]="true">
                        <accordion-group [isOpen]="isFirstOpen" heading="FAQ #1" class="accordion-item" #group>
                            <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                                <i class="mdi mdi-attachment font-size-22 c-mr-1 mdi-attachment-rotate text-primary"></i>
                                <i class="mdi font-size-24"
                                    [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                            </span>
                            <div class="text-muted">
                                It is hidden by default, until the collapse plugin adds the appropriate
                                classes that we use to style each element. These classes control the overall appearance,
                                as well as the showing
                                and hiding via CSS transitions. You can modify any of this with custom CSS or overriding
                                our default variables. It's
                                also worth noting that just about any HTML can go within the , though the transition
                                does limit
                                overflow.
                            </div>
                        </accordion-group>
                        <accordion-group heading="FAQ #2" class="accordion-item" #md>
                            <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                                <i class="mdi mdi-attachment font-size-22 c-mr-1 mdi-attachment-rotate text-primary"></i>
                                <i class="mdi font-size-24"
                                    [ngClass]="!md?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                            </span>
                            <div class="text-muted">
                                It is hidden by default, until the collapse plugin adds the appropriate
                                classes that we use to style each element. These classes control the overall appearance,
                                as well as the showing
                                and hiding via CSS transitions. You can modify any of this with custom CSS or overriding
                                our default variables. It's
                                also worth noting that just about any HTML can go within the , though the transition
                                does limit
                                overflow.
                            </div>
                        </accordion-group>
                        <accordion-group heading="FAQ #3" class="accordion-item" #item3>
                            <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                                <i class="fas font-size-24"
                                    [ngClass]="!item3?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                            </span>
                            <div class="text-muted">
                                It is hidden by default, until the collapse plugin adds the appropriate
                                classes that we use to style each element. These classes control the overall appearance,
                                as well as the showing
                                and hiding via CSS transitions. You can modify any of this with custom CSS or overriding
                                our default variables. It's
                                also worth noting that just about any HTML can go within the , though the transition
                                does limit
                                overflow.
                            </div>
                        </accordion-group>
                    </accordion> -->

            </div>
        </div>
    </div>
</div>


</div>