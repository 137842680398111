import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { LoaderService } from '../../../core/services/loader.service';
import { SharedService } from '../../../shared/shared.service';
import { getConsumersFaqResponse } from '../../../store/customer/consumer-selector';
import { getConsumerFaq } from '../../../store/customer/consumer.action';


@Component({
  selector: 'app-rp-consumer-faq',
  templateUrl: './rp-consumer-faq.component.html',
  styleUrl: './rp-consumer-faq.component.scss'
})
export class RpConsumerFaqComponent {
  consumerFaq: any[] = [];
  currentUser: any;
  consumerId: any;
  breadCrumbItems: Array<{}> | undefined;
  isFirstOpen: boolean = true;
  constructor(private store: Store,
    private router: Router,
    private loaderService: LoaderService,
    private sharedService: SharedService) {
    this.breadCrumbItems = [{ label: "View Faq's" }];
    let getConsumersFaq$ = this.store.pipe(select(getConsumersFaqResponse));

    getConsumersFaq$.subscribe((response: any) => {
      if (response) {
        this.consumerFaq = [...response.items]
      }
    })


  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.consumerId = this.currentUser?.currentUserRoleDetails?.userId;
    this.store.dispatch(getConsumerFaq());
  }


  downloadAttachedFile(faq: any) {
  }

}
