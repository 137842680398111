import { Wallet } from './../../../shared/components/wallet-history-expended/wallet-history-expended.component';
import { LoaderService } from './../../../core/services/loader.service';
import { Component, Input,ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/shared.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  createMaster,
  updateMaster,
} from '../../../store/Masters/masters.actions';
import { Helper } from '../../../core/helpers/helper';
import { Validations } from '../../../shared/constants/validations';
import { Umd } from '../../../shared/modals/common';
import { GetUmd, createUmd, updateUmd } from '../../../store/Umd/umd.actions';
import { McbButtons, UtilityType } from '../../../shared/constants/enum';
import { Utilites } from '../../../store/Utilities/utilities.models';
@Component({
  selector: 'app-rp-create-umd',
  templateUrl: './rp-create-umd.component.html',
  styleUrl: './rp-create-umd.component.scss',
})
export class RpCreateUMDComponent {
  // configData: any;
  createUMDForm!: UntypedFormGroup;
  submitted: boolean = false;
  @Input() umdData!: any;
  @Input() configData!: any;

  Umd_Validations = Validations.UmdCreatePage;
  currentUser: any;
  projectId: any;
  UtilityType = UtilityType;
  mcbPowerButtons = Object.values(McbButtons); // Convert enum to array of values
  requiredfield: boolean=false;
  requiredSlabNamefield:boolean=false;
  requiredMcbMainPowerfield:boolean=false;
  waterTypeItems:any=[]
  constructor(
    private router: Router,
    private store: Store,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef
  ) {}
  dismissModal(): void {
    this.modalService.hide();
  }


  isDisplay(utility:any) {
 
      const matchingUtility = this.configData?.utilities.find(
        (u: any) => u.id === utility
      );
      return matchingUtility?.name;
   
  }


  CreateUMD(type: string): void {
    this.submitted = true;
    if (this.createUMDForm.valid) {
      this.loaderService.setLoading(true);
      // Process form data (send to backend, etc.)
      console.log(this.createUMDForm.value);
      if (type == 'Create') {
        this.store.dispatch(createUmd({ UmdData: this.createUMDForm.value }));
      } else if (type == 'Update') {
        // console.log(this.createUMDForm.value);
        this.store.dispatch(
          updateUmd({
            UmdData: this.createUMDForm.value,
            id: this.umdData.id ? this.umdData.id : '',
          })
        );
      }
      this.modalService.hide();
    }
  }

  get f() {
    return this.createUMDForm.controls;
  }

  ngOnInit(): void {
    // this.createUMDForm?.get('utilityMasterId')?.valueChanges.subscribe((value) => {
    //   console.log(value);
      
      // this.createUMDForm.get('billDate')?.clearValidators();
      // this.createUMDForm.get('billDate')?.updateValueAndValidity();

      // this.createUMDForm
      // .get('RelayCutofflimit')
      // ?.setValidators([
      //   Validators.required,
      //   Helper.numberWithDecimalsValidator(),
      // ]);
    // });
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectId = this.currentUser?.project.id;

    this.createUMDForm = this.formBuilder.group({
      projectId: this.projectId,
      utilityMasterId: [, Validators.required],
      macAddress: ['', Validators.required],
      meterSerialNo: ['', Validators.required],
      status: [, Validators.required],
      mcbMainPower: [, ],
      supplierId:[null,]
    });

    this.createUMDForm?.get('utilityMasterId')?.valueChanges.subscribe((value) => {
      console.log(value);
      this.handleUtilityChange(value);
    });

    this.waterTypeItems =  this.configData?.suppliers.filter(
      (item: any) => item.master.name === 'Water'
    );
  
    // this.getAllConfigData();
    this.patchForm();
  }

  handleUtilityChange(value: any): void {
    if (value) {
      this.requiredfield = false;
      this.requiredSlabNamefield = false;
      this.requiredMcbMainPowerfield = false;
      if (this.isDisplay(value) === UtilityType.ELECTRICITY) {
        this.requiredfield = true;
        this.requiredMcbMainPowerfield = true;
        this.createUMDForm.get('mcbMainPower')?.setValidators([Validators.required]);
      } else {
        this.createUMDForm.get('mcbMainPower')?.clearValidators();
      }

      if (this.isDisplay(value) === UtilityType.WATER) {
        this.requiredSlabNamefield = true;
        this.createUMDForm.get('supplierId')?.setValidators([Validators.required]);
      } else {
        this.createUMDForm.get('supplierId')?.clearValidators();
      }


      this.createUMDForm.get('supplierId')?.updateValueAndValidity();
      this.createUMDForm.get('mcbMainPower')?.updateValueAndValidity(); // Ensure the state is updated
    }
  }

  
  patchForm(): void {
    // this.sharedService.getAllConfigData(true).subscribe((data) => {
    //   if (
    //     data.statusCode == 200 &&
    //     data.message == 'Config data retrieved successfully.'
    //   ) {
    // this.configData = data;

    if (this.umdData?.utility?.id) {
      this.createUMDForm.patchValue({
        utilityMasterId: this.umdData?.utility?.id
          ? this.umdData?.utility?.id
          : '',
        macAddress: this.umdData?.macAddress ? this.umdData?.macAddress : '',
        meterSerialNo: this.umdData?.meterSerialNo ?? '',
        status: this.umdData?.status ? this.umdData?.status : '',
        mcbMainPower: this.umdData?.mcbMainPower ?? '',
        supplierId:this.umdData?.supplierId ?? null,
      });
      this.cdr.detectChanges();
    }
    //
    //   }
    //   console.log('Types', this.configData.data.types);
    // });
  }
}
