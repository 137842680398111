import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetersRoutingModule } from './mapping-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ComponentsModule } from '../../shared/components/components.module';
import { SharedModule } from '../../shared/shared.module';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { RpMappingComponent } from './rp-mapping.component';
import { RpAddMappingComponent } from './rp-app-Mapping/rp-app-mapping.component';
@NgModule({
  declarations: [RpMappingComponent, RpAddMappingComponent],
  imports: [
    CommonModule,
    MetersRoutingModule,
    NgSelectModule,
    BsDatepickerModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    ModalModule,
    UiSwitchModule,
    ComponentsModule,
    SharedModule,
    NgStepperModule,
    CdkStepperModule,
  ],
})
export class MappingModule {}
