import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpConsumerFaqComponent } from './rp-consumer-faq/rp-consumer-faq.component';


const routes: Routes = [

  { path: 'consumerFaq', component: RpConsumerFaqComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsumerFaqRoutingModule { }
