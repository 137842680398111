import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../store/Authentication/auth.models';
import { AppConfigData } from '../config/appConfigData';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Faq } from '../../store/Faq/faq.models';
import { Master } from '../../store/Masters/masters.models';
import { updateMeterStatus } from '../../store/meters/meters.actions';

@Injectable({ providedIn: 'root' })
export class MeterService {
  constructor(private http: HttpClient, private router: Router) { }

  getMeters(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    projectId: string,
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.getMetersUrl}?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'all'}` : ''}` +
      `${projectId ? `&projectId=${projectId}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getMasterConsumptions(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    MeterId?: string,
    FromDate?: string,
    ToDate?: string,
    filter?: string,
    orderBy?: string
  ): Observable<any> {

    let url =
      `${AppConfigData.getMetersConsumptionsDetailsUrl}?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${MeterId ? `&MeterId=${MeterId}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${FromDate ? `&FromDate=${FromDate}` : ''}` +
      `${ToDate ? `&ToDate=${ToDate}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  // createMasters(masterData: Master): Observable<any> {
  //   let request = {
  //     typeId: masterData.type,
  //     name: masterData.name,
  //   };
  //   return this.http.post<any>(AppConfigData.createMastersUrl, request).pipe(
  //     map((res) => {
  //       return res;
  //     }),
  //     catchError((error) => {
  //       console.error('getuserprofile failed:', error);
  //       return throwError(error); // Rethrow the error to propagate it further
  //     })
  //   );
  // }

  updateMeterStatus(requst: any): Observable<any> {
    // let url = `${AppConfigData.checkMeterStatusUrl}`;
    let url = `${AppConfigData.updateMeterStatusUrl}`;
    return this.http.post<any>(url, requst).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  checkMeterStatus(requst: any): Observable<any> {
    let url = `${AppConfigData.checkMeterStatusUrl}`;
    return this.http.post<any>(url, requst).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('checkMeterStatus failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  // deleteMaster(id: any): Observable<any> {
  //   return this.http.post<any>(AppConfigData.deleteMastersUrl + id, null).pipe(
  //     map((res) => {
  //       return res;
  //     }),
  //     catchError((error) => {
  //       console.error('deleteFaqs failed:', error);
  //       return throwError(error); // Rethrow the error to propagate it further
  //     })
  //   );
  // }



  getConsumerMeterOnOffControlsUrl(
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string,
    MeterId?: string,
    FromDate?: string,
    ToDate?: string,
  ): Observable<any> {
    console.log(MeterId)
    let url;
    url =
      `${AppConfigData.getConsumerMeterOnOffControlsUrl
      }?MeterId=${MeterId}&pageIndex=${pageIndex}&pageSize=${pageSize}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${FromDate ? `&FromDate=${FromDate}` : ''}` +
      `${ToDate ? `&ToDate=${ToDate}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('consuner Recharge History failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }










}

