import {
  checkMeterStatus,
  GetAllmeter,
  Getmeter,
  resetState,
  updateMeterStatus,
} from './../../../store/meters/meters.actions';

import { Component, ViewChild, Inject } from '@angular/core';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { metersData } from './meters-data';
import { Store, select } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';

import {
  process,
  State,
  toODataString,
  SortDescriptor,
} from '@progress/kendo-data-query';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';

import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
  checkMeterStatusResponse,
  getAllmeterConfigResponse,
  getmeterConfigResponse,
  updateMeterStatusResponse,
} from '../../../store/meters/meters-selector';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { table_pageSize } from '../../../shared/constants/templatesNames';
import { Roles, SweetAlertIcon } from '../../../shared/constants/enum';
import { Helper } from '../../../core/helpers/helper';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { LoaderService } from '../../../core/services/loader.service';
import { MeterService } from '../../../core/services/meter.services';
import { HttpErrorResponse } from '@angular/common/http';
import { Validations } from '../../../shared/constants/validations';
import { __assign } from 'tslib';
import { debug } from 'console';
@Component({
  selector: 'app-rp-meters',
  templateUrl: './rp-meters.component.html',
  styleUrl: './rp-meters.component.scss',
})
export class RpMetersComponent {
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  public selectableSettings: SelectableSettings = {
    mode: 'multiple',
    checkboxOnly: true,
  };
  breadCrumbItems: Array<{}> | undefined;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';
  selectValue!: string[];
  public meterState: any = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  currentUser: any;
  projectId: any;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public pageSize = 10;
  public skip = 0;
  totalMasterCount: number = 0;
  currentPage: number = 1;
  meterconfigList: any[] = [];
  public sort: SortDescriptor[] = [];
  public metergridData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public buttonCount = 5;
  listOfRoles = Roles;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  //public pageSizes = true;
  public previousNext = true;
  mySelectionMeter: any = [];
  orderByQuery: string = '';
  queryString: string = '';
  searchedValue: any = '';
  allMeterconfigList: any[] = [];
  userRole: string = '';
  ServiceError = Validations.ServiceError;
  meterRequest = Object.assign({});
  selectedMeter: any;

  constructor(
    private store: Store,
    private sharedService: SharedService,
    private router: Router,
    private PdfexportService: PdfexportService,
    private loaderService: LoaderService,
    private meterService: MeterService
  ) {
    if (history.state.meterRequest != undefined && history.state.meterRequest !== null) {
      this.meterRequest = history.state.meterRequest;
    }
    this.isFilter = localStorage.getItem('filter');

    // console.log(this.meterRequest)
    this.getStoreInfo();
    this.breadCrumbItems = [{ label: 'Meters' }];
  }
  private exportSubscription: Subscription = new Subscription();
  getStoreInfo() {
    this.store.dispatch(resetState());

    let MetersConfiguration$ = this.store.pipe(select(getmeterConfigResponse));
    let checkMeterStatusResponse$ = this.store.pipe(select(checkMeterStatusResponse));


    this.exportSubscription = this.store
      .pipe(select(getAllmeterConfigResponse))
      .subscribe((res: any) => {
        if (res) {
          this.allMeterconfigList = [];
          if (res?.items.length > 0) {
            this.allMeterconfigList = [...res.items];

            const transformedData = this.allMeterconfigList
              .map((item: any) => {
                const dataObj: any = {};

                // Add each field to the dataObj if it is not null or undefined
                /* if (item?.projectName !== null && item?.projectName !== undefined) {
                  dataObj['Project Name'] = item.projectName;
                } */
                if (item?.utility !== null && item?.utility !== undefined) {
                  dataObj['Utility'] = item.utility;
                }
                if (item?.deviceSrNo !== null && item?.deviceSrNo !== undefined) {
                  dataObj['Device Serial No'] = item.deviceSrNo;
                }
                if (item?.macAddress !== null && item?.macAddress !== undefined) {
                  dataObj['MAC Address'] = item.macAddress;
                }
                if (item?.meterSatus !== null && item?.meterSatus !== undefined) {
                  dataObj['Meter Status'] = item.meterSatus;
                }
                if (item?.currentReading !== null && item?.currentReading !== undefined) {
                  dataObj['Current Reading'] = item.utility == "Water" ? item.currentReading + 'L' :
                    item.utility == "Gas" ? item.currentReading + ' m³' : item.utility == "Electricity" ? item.currentReading + 'kWh' : '';
                }
                if (item?.currentReadingEB !== null && item?.currentReadingEB !== undefined) {
                  dataObj['EB Reading'] = item.utility == "Electricity" ? item.currentReadingEB + 'kWh' : '';
                }
                if (item?.currentReadingDG !== null && item?.currentReadingDG !== undefined) {
                  dataObj['DG Reading'] = item.utility == "Electricity" ? item.currentReadingDG + 'kWh' : '';
                }
                if (item?.occupant !== null && item?.occupant !== undefined) {
                  dataObj['Occupant'] = item.occupant;
                }
                if (item?.currentDate !== null && item?.currentDate !== undefined) {
                  dataObj['Last Communicated Time'] = Helper.excelformatDate(item.currentDate);
                }
                if (item?.residentialUnit !== null && item?.residentialUnit !== undefined) {
                  dataObj['Residential Unit'] = item.residentialUnit;
                }
                if (item?.onRequest !== null && item?.onRequest !== undefined) {
                  dataObj['On Request Sent'] = item.onRequest;
                }
                if (item?.offRequest !== null && item?.offRequest !== undefined) {
                  dataObj['Off Request Sent'] = item.offRequest;
                }
                if (item?.batterLevel !== null && item?.batterLevel !== undefined) {
                  dataObj['Battery Level'] = item.batterLevel;
                }
                if (item?.batteryVoltage !== null && item?.batteryVoltage !== undefined) {
                  dataObj['Battery Voltage'] = item.batteryVoltage + 'v';
                }
                if (item?.meterCommunicationStatus !== null && item?.meterCommunicationStatus !== undefined) {
                  dataObj['Communication Status'] = item.meterCommunicationStatus ? 'Yes' : 'No';
                }
                if (item?.valveOrRelayStatus !== null && item?.valveOrRelayStatus !== undefined) {
                  dataObj['Valve/Relay Status'] = item.valveOrRelayStatus ? 'ON' : 'OFF';
                }

                // Return only if there is data
                return Object.keys(dataObj).length > 0 ? dataObj : null;
              })
              .filter((item) => item !== null); // Filter out any null entries

            this.allMeterconfigList = [];

            // Check if there's data to export after filtering null values
            if (transformedData.length > 0) {
              this.PdfexportService.downloadExcel(
                transformedData,
                'Metesreport'
              );
            } else {
              this.sharedService.showMessageDialog(
                'No valid data to export',
                '',
                SweetAlertIcon.ERROR
              );
            }
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });

    let updateMeterStatusResponse$ = this.store.pipe(
      select(updateMeterStatusResponse)
    );
    MetersConfiguration$.subscribe((res: any) => {
      if (res && res.items) {
        this.meterconfigList = [...res.items];


        this.pageSize = res.pageSize;
        this.totalMasterCount = res.totalCount;
        let selectedMeter = this.sharedService.getMeterViewDetails() ? JSON.parse(this.sharedService.getMeterViewDetails()) : null;
        if (selectedMeter) {
          // need to implement check meter status response
          selectedMeter = this.meterconfigList.find((item: any) => item.id == selectedMeter.id);
          console.log(selectedMeter);
          let message = selectedMeter.utility + ' Meter Status is ' + (selectedMeter.valveOrRelayStatus ? 'On' : 'Off');
          this.sharedService.showMessageDialog(
            message,
            '',
            SweetAlertIcon.INFO
          );

          this.sharedService.setMeterViewDetails(null);
        }
      }
      this.loadMterConfig();
      console.log('MetersConfiguration', res);
    });

    checkMeterStatusResponse$.subscribe((res: any) => {
      if (res) {
        setTimeout(() => {
          this.getAllMeterConfigurations();
        }, 30000)
      }
      console.log('checkMeterStatusResponse', res);
    });

    updateMeterStatusResponse$.subscribe((res: any) => {
      if (res) {
        this.meterconfigList = this.meterconfigList.map((meter) => {
          if (meter.id === res.data.id) {
            return {
              ...meter,
              onRequest: res.data.onRequest,
              onRequestDate: res.data.onRequestDate,
              offRequest: res.data.offRequest,
              offRequestDate: res.data.offRequestDate,
            };
          }
          return meter;
        });
        // this.getAllMeterConfigurations();
        // this.meterconfigList = [...res.items];
        // this.pageSize = res.pageSize;
        // this.totalMasterCount = res.totalCount;
      }
      this.loadMterConfig();
      console.log('MetersConfiguration', res);
    });
  }
  isFilter: any
  ngOnInit(): void {
    this.sharedService.setMeterViewDetails(null);
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectId = this.currentUser?.project?.id;
    this.userRole = this.currentUser?.currentUserRoleDetails?.role;
    console.log(Object.keys(this.meterRequest).length > 0)
    if (this.meterRequest && typeof this.meterRequest === 'object' && Object.keys(this.meterRequest).length > 0) {
      let status = this.meterRequest.meterStatus;
      this.meterState = {
        skip: 0,
        take: 10,
        filter: {
          logic: 'and',
          filters: [
            {
              field: 'meterCommunicationStatus',
              operator: 'eq',
              value: status,
            },
          ],
        },
      };

      this.queryString = `$filter=meterCommunicationStatus eq ${status}`;
      //$filter: meterCommunicationStatus eq true
      //meterCommunicationStatus eq true
    }

    const savedFilter = this.sharedService.getFilters('MeterPage');
    if (this.isFilter == 'true' && savedFilter) {
      //this.filter = this.sharedService.getFilter();
      this.meterState.filter = savedFilter;
      console.log(this.filter)
      console.log(this.meterState)
      this.queryString = this.sharedService.createQuery(this.meterState);
    }
    /* if (this.isFilter == 'true') {
      this.filter = this.sharedService.getFilter();
      this.meterState.filter = this.filter;
      console.log(this.filter)
      console.log(this.meterState)
      this.queryString = this.sharedService.createQuery(this.meterState);
    } */


    this.getAllMeterConfigurations();
  }

  ngOnDestroy() {
    this.sharedService.setMeterViewDetails(null);
    this.exportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }
  navigateToMeterView(dataItem: any): void {
    console.log('dataItem', dataItem);

    const serializedData = JSON.stringify(dataItem);
    this.router.navigate(['/meters/metersView'], {
      state: { meterViewDetails: serializedData },
    });
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.meterState.sort = sort;
    console.log(this.meterState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.meterState.sort
    );

    this.orderByQuery = orderByQuery;

    this.getAllMeterConfigurations();
  }

  public onMeterFilterChange(filter: CompositeFilterDescriptor): void {
    this.meterState.filter = filter;
    this.skip = 0
    console.log(this.meterState);
    let isFilter = filter.filters.length > 0 ? true : false;
    localStorage.setItem('filter', JSON.stringify(isFilter));
    localStorage.setItem('page', 'Meter');

    this.sharedService.setFilters('MeterPage', filter);
    const queryString = this.sharedService.createQuery(this.meterState);
    this.queryString = this.sharedService.createQuery(this.meterState);
    this.currentPage = 1
    this.getAllMeterConfigurations();
    console.log(queryString);
  }
  public onValueChange(ev: string): void {

    if (ev.length == 0) {
      this.meterConfigPageChange({ skip: 0, take: this.pageSize })
      this.getAllMeterConfigurations()
    }
  }

  getAllMeterConfigurations(): void {
    this.store.dispatch(
      Getmeter({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        projectId: this.projectId,
      })
    );
  }

  public clearFilter(): void {
    this.searchedValue = '';
    this.queryString = '';
    this.orderByQuery = '';

    // Reset filter state
    this.meterState.filter = { logic: 'and', filters: [] }; // Correctly reset to a filter descriptor
    this.meterState.sort = []; // Reset sorting if needed
    this.skip = 0; // Reset to first page
    this.currentPage = 0

    // Clear additional filter if required
    this.filter = { logic: 'and', filters: [] }; // Reset additional filter state

    // Fetch all configurations again to refresh the grid
    this.getAllMeterConfigurations();
  }

  getTotalMeterConfigurations(): void {

    this.store.dispatch(
      GetAllmeter({
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
        projectId: this.projectId,
      })
    );
  }

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.state.filter = filter;
    const queryString = this.createQuery(this.state);
    console.log(queryString);
  }

  private createQuery(state: State): string {
    // Implement this method to create a query string from the state object
    // This should convert the state to your API's query parameters
    // Example implementation
    const queryStr = `${toODataString(state)}&$count=true`;
    console.log(queryStr);

    let query = `skip=${state.skip}&take=${state.take}`;

    if (state.filter) {
      const filters = state.filter.filters
        .map((f) => {
          const field = (f as any).field;
          const value = (f as any).value;
          return `${field}=${value}`;
        })
        .join('&');
      query += `&${filters}`;
    }

    return query;
  }

  protected meterConfigPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.mySelectionMeter = [];
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getAllMeterConfigurations();
    this.loadMterConfig();
  }

  public selectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectionMeter.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataitem?.id
      );
      if (index > -1) {
        this.mySelectionMeter.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isMasterRowSelected(row.dataItem)) {
        this.mySelectionMeter.push(row.dataItem);
      }
    });

    console.log(this.mySelectionMeter);
  }

  protected dataStateChange(state: DataStateChangeEvent): void {
    console.log("saAK", JSON.stringify(state.filter))
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    console.log('datastatechange' + JSON.stringify(this.filter))
    // alert('datastatechange'+JSON.stringify(this.filter))
    //this.loadMterConfig();
  }

  private loadMterConfig(): void {
    // const state: State = {
    //   take: this.pageSize,
    //   sort: this.sort,
    //   filter: this.filter,
    // };
    const state: State = {
      take: this.pageSize,
      sort: this.sort,
      filter: {
        logic: "and",
        filters: [
        ],
      } as CompositeFilterDescriptor,  // Providing a valid filter
    };



    const processedData = process(this.meterconfigList, state);
    // console.log("meterconfigList",JSON.stringify(this.meterconfigList));
    // console.log("processed",JSON.stringify(processedData));
    // alert("santosh"+JSON.stringify(processedData))
    this.metergridData = {
      data: processedData.data,
      total: this.totalMasterCount,
    };

    this.metergridData.data = this.metergridData?.data.map((project) => {
      // console.log(new Date(project.currentDate));
      return {
        ...project,

        currentDate: new Date(project?.currentDate), // Modify the billDate property
        communicationStatus: project.meterCommunicationStatus ? 'Yes' : 'No',
        currentReading: project.utility == 'Water' ? project.currentReading + ' L' : project.utility == 'Gas' ? project.currentReading + ' m³' :
          project.utility == 'Electricity' ? project.currentReading + '  kWh' : '',
        currentReadingEB: project.utility == 'Electricity' ? project.currentReadingEB + '  kWh' : '-',
        currentReadingDG: project.utility == 'Electricity' ? project.currentReadingDG + '  kWh' : '-'
      };
    });
  }

  public isMasterRowSelected = (rowArg: any) => {
    return this.mySelectionMeter.some((row: any) => row.id === rowArg.id);
  };

  public onMeterConfigFilter(value: any): void {
    this.meterConfigPageChange({ skip: 0, take: this.pageSize })
    //this.getAllMeterConfigurations();
  }
  exportAll() {
    this.getTotalMeterConfigurations();
  }
  public onMeterPdfExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
    /* if (this.mySelectionMeter.length == 0) {
      this.getTotalMeterConfigurations();
    } else {
      if (type === 'Excel') {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      } else if (type === 'Pdf') {
        setTimeout(() => {
          this.hiddenGrid?.saveAsPDF();
        }, 1000);
      }
    } */
  }

  turOnMeter(event: any, dataItem: any) { }

  toggleMeterStatus(event: any, dataItem: any) {
    console.log(event)
    console.log(this.projectId)
    let request = {
      meterId: dataItem?.id,
      status: !event ? 'On' : 'Off',
      macAddress: dataItem.macAddress,
      utilityName: dataItem.utility,
      IsConsumer: false,
      ProjectId: this.projectId == undefined ? dataItem.projectId : this.projectId
    };
    this.store.dispatch(updateMeterStatus({ request: request }));
  }

  checkRelayStatus(dataItem: any) {
    this.sharedService.setMeterViewDetails(
      JSON.stringify(dataItem)
    );
    let request = {
      MacAddress: dataItem?.macAddress,
      IsConsumer: false,
      ProjectId: dataItem?.projectId
    };
    this.loaderService.setLoading(true)
    // this.store.dispatch(checkMeterStatus({ request: request }));
    this.meterService.checkMeterStatus(request).subscribe({
      next: (response) => {
        // Handle the response and show a success alert
        setTimeout(() => {
          this.getAllMeterConfigurations();
        }, 30000)
      },
      error: (error: HttpErrorResponse) => {
        // Handle error and display error alert
        this.loaderService.setLoading(false);
        this.sharedService.showMessageDialog(
          error.message,
          this.ServiceError.Error,
          SweetAlertIcon.ERROR
        );
        console.error('Error occurred:', error);
      }
    });
    // }
  }

  checkBtnStatus(dataItem: any): boolean {
    if (dataItem?.onRequest === 'Yes' && !dataItem?.valveOrRelayStatus) {
      return true; // Button for turning on, so toggle off
    }
    if (dataItem?.offRequest === 'Yes' && dataItem?.valveOrRelayStatus) {
      return true; // Button for turning off, so toggle on
    }
    return dataItem?.valveOrRelayStatus; // Otherwise, keep the current status
  }
}
