<div class="container-fluid">

    <div class="row">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
    </div>
    <app-bills-view></app-bills-view>

    <!--  <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div>
                        <section class="">
                            <div class="d-flex justify-content-end mb-3">
                                <button type="button" class="btn btn-primary me-2"
                                    (click)="invalidBillsModal(invalidBillModal)"
                                    [disabled]="viewBilldeatils?.isInvalidBill==true"><i
                                        class="bx bx-file font-size-16 align-middle me-2"></i> Mark As Invalid Bill
                                </button>
                                <button type="button" class="btn btn-primary me-2"
                                    [disabled]="billsDataById?.paymentStatus=='Success'"
                                    (click)="markASPaid(paidBillModal)"><i
                                        class="bx bx-file font-size-16 align-middle me-2"></i> Mark As Paid
                                </button>
                                <button kendoButton class="btn btn-primary" (click)="pdf.saveAs('bill.pdf')">
                                    Download Bill
                                </button>

                            </div>
                            <kendo-pdf-export #pdf paperSize="A4" margin="1cm">
                                <div>
                                    <accordion [closeOthers]="false" class="accordion custom-accordian"
                                        [isAnimated]="true">
                                        <accordion-group [isOpen]="isAllOpen" heading="Bill Details"
                                            class="accordion-item mb-3 panel-open" #item1>
                                            <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                                <i class="mdi font-size-24"
                                                    [ngClass]="!item1?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                            </span>

                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <form>
                                                        <div class="row">
                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Bill Number</label>
                                                                    <p>{{viewBilldeatils.billNo
                                                                        ?viewBilldeatils.billNo:'-'}}</p>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Residential Unit </label>
                                                                    <p>{{viewBilldeatils.residentialUnit
                                                                        ?viewBilldeatils.residentialUnit:'-'}}</p>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label"> Consumer</label>
                                                                    <p>{{viewBilldeatils.consumerName
                                                                        ?viewBilldeatils.consumerName:'-'}}</p>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Utility </label>
                                                                    <p>{{viewBilldeatils.utility!=undefinde
                                                                        ?viewBilldeatils.utility:'-'}}</p>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Meter Number </label>
                                                                    <p>{{viewBilldeatils.macAddress}}</p>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label"> Bill Number </label>
                                                                    <p>{{viewBilldeatils.billNo!=undefinde
                                                                        ?viewBilldeatils.billNo:'-'}}</p>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label"> Payment Status</label>
                                                                    <p>{{viewBilldeatils.paymentStatus
                                                                        !=undefinde?viewBilldeatils.paymentStatus:'-'}}
                                                                    </p>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Transaction ID </label>
                                                                    <p>{{viewBilldeatils.transactionId
                                                                        ?viewBilldeatils.transactionId:'-'}} </p>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Optional Charges </label>
                                                                    <p>{{viewBilldeatils.optionalCharges!=undefinde?
                                                                        (viewBilldeatils.optionalCharges
                                                                        |currency:'INR':true):'0'}}</p>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Additional Charges
                                                                    </label>
                                                                    <p> {{viewBilldeatils.additionalCharges!=undefinde?(viewBilldeatils.additionalCharges
                                                                        |currency:'INR':true):'0'}}
                                                                    </p>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Total Amount </label>
                                                                    <p> {{viewBilldeatils.totalAmount!=undefinde?(viewBilldeatils.totalAmount
                                                                        |
                                                                        currency: 'INR':true):'0'}} </p>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </form>
                                                </div>
                                            </div>

                                        </accordion-group>

                                        @if(viewBilldeatils?.gas?.length>0){
                                        <accordion-group [isOpen]="isAllOpen" heading="Unit Rate (Gas)"
                                            class="accordion-item mb-3 panel-open" #item2>
                                            <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                                <i class="mdi font-size-24"
                                                    [ngClass]="!item2?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                            </span>

                                            <div class="row" *ngFor="let gas of viewBilldeatils?.gas">
                                                <div class="col-lg-12">

                                                    <div class="row">
                                                        <div class="col-lg-3  mb-3">
                                                            <div class="mb-3">
                                                                <label class="form-label">Unit Rate </label>
                                                                <p> {{gas.unitRate
                                                                    !=undefinde?gas.unitRate:'-'}} </p>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-3  mb-3">
                                                            <div class="mb-3">
                                                                <label class="form-label">Previous Meter Reading
                                                                </label>
                                                                <p> {{gas.previousMeterReading
                                                                    ?gas.previousMeterReading:'-'}} </p>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-3  mb-3">
                                                            <div class="mb-3">
                                                                <label class="form-label"> Current Meter
                                                                    Reading</label>
                                                                <p> {{gas.currentMeterReading?gas.currentMeterReading:'-'
                                                                    }} </p>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-3  mb-3">
                                                            <div class="mb-3">
                                                                <label class="form-label">Number of Units consumed
                                                                </label>
                                                                <p> {{gas.noofUnitsConsumed?gas.noofUnitsConsumed:'-'}}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                        </accordion-group>}

                                        @if(viewBilldeatils?.water.length>0){
                                        <accordion-group [isOpen]="isAllOpen" heading="Unit Rate (Water)"
                                            class="accordion-item mb-3 panel-open" #item2>
                                            <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                                <i class="mdi font-size-24"
                                                    [ngClass]="!item2?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                            </span>

                                            <div class="row" *ngFor="let water of viewBilldeatils?.water">
                                                <div class="col-lg-12">

                                                    <div class="row">
                                                        <div class="col-lg-3  mb-3">
                                                            <div class="mb-3">
                                                                <label class="form-label">Unit Rate </label>
                                                                <p> {{water.unitRate
                                                                    !=undefinde?water.unitRate:'-'}} </p>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-3  mb-3">
                                                            <div class="mb-3">
                                                                <label class="form-label">Previous Meter Reading
                                                                </label>
                                                                <p> {{water.previousMeterReading
                                                                    ?water.previousMeterReading:'-'}} </p>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-3  mb-3">
                                                            <div class="mb-3">
                                                                <label class="form-label"> Current Meter
                                                                    Reading</label>
                                                                <p> {{water.currentMeterReading?water.currentMeterReading:'-'
                                                                    }} </p>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-3  mb-3">
                                                            <div class="mb-3">
                                                                <label class="form-label">Number of Units consumed
                                                                </label>
                                                                <p> {{water.noofUnitsConsumed?water.noofUnitsConsumed:'-'}}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                        </accordion-group>}
                                        @if(viewBilldeatils?.electricity.length>0){
                                        <accordion-group [isOpen]="isAllOpen" heading="Unit Rate (Electricity)"
                                            class="accordion-item mb-3 panel-open" #item3>
                                            <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                                <i class="mdi font-size-24"
                                                    [ngClass]="!item3?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                            </span>

                                            <div class="row" *ngFor="let electricity of viewBilldeatils?.electricity">
                                                <div class="col-lg-12">
                                                    <form>
                                                        <div class="row">
                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Unit Rate EB</label>
                                                                    <p>{{electricity.unitRateEB !=undefinde?
                                                                        electricity.unitRateEB: '-'}}</p>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Unit Rate DG </label>
                                                                    <p>{{electricity.unitRateDG!=undefinde ?
                                                                        electricity.unitRateDG: '-'}} </p>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label"> Previous Meter Reading
                                                                        EB</label>
                                                                    <p>{{electricity.electricityPreviousMeterReadingEB!=undefinde
                                                                        ?electricity.electricityPreviousMeterReadingEB
                                                                        :
                                                                        '-'}} </p>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Previous Meter Reading DG
                                                                    </label>
                                                                    <p>{{electricity.electricityPreviousMeterReadingDG
                                                                        ?electricity.electricityPreviousMeterReadingDG
                                                                        :'-'}}
                                                                    </p>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Current Meter Reading EB
                                                                    </label>
                                                                    <p>{{electricity.electricityCurrentMeterReadingEB
                                                                        ?electricity.electricityCurrentMeterReadingEB
                                                                        :'-'}}
                                                                    </p>

                                                                </div>
                                                            </div>


                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Current meter reading
                                                                        DG</label>
                                                                    <p>
                                                                        {{electricity.electricityCurrentMeterReadingDG?
                                                                        electricity.electricityCurrentMeterReadingDG
                                                                        :'-'}}
                                                                    </p>

                                                                </div>
                                                            </div>


                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label"> No.of units consumed EB
                                                                    </label>
                                                                    <p>{{electricity.noofElectricityUnitsConsumedEB?
                                                                        electricity.noofElectricityUnitsConsumedEB
                                                                        :'-'}}
                                                                    </p>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label"> No.of units consumed DG
                                                                    </label>
                                                                    <p>{{electricity.noofElectricityUnitsConsumedDG
                                                                        ?
                                                                        electricity.noofElectricityUnitsConsumedDG:'-'}}
                                                                    </p>

                                                                </div>
                                                            </div>


                                                        </div>

                                                    </form>
                                                </div>
                                            </div>

                                        </accordion-group>}
                                        @if(viewBilldeatils?.generalCharges.length>0){
                                        <accordion-group [isOpen]="isAllOpen" heading="General Charges"
                                            class="accordion-item mb-3 panel-open" #item4>
                                            <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                                <i class="fas font-size-24"
                                                    [ngClass]="!item4?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                            </span>

                                            <div class="row">
                                                <div class="mb-3 col-lg-4"
                                                    *ngFor="let col of viewBilldeatils.generalCharges">
                                                    <label for="">{{col.name}}</label>
                                                    <p>{{col.total}}</p>
                                                </div>
                                            </div>


                                        </accordion-group>}

                                        <accordion-group [isOpen]="isAllOpen" heading="Bill Generate"
                                            class="accordion-item mb-3 panel-open" #item5>
                                            <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                                <i class="fas font-size-24"
                                                    [ngClass]="!item5?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                            </span>

                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <form>
                                                        <div class="row">
                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Last Month Bill Generated
                                                                        Date</label>
                                                                    <p>{{viewBilldeatils.lastBillGeneratedDate!=null?
                                                                        (viewBilldeatils.lastBillGeneratedDate |
                                                                        date:'dd MMM yyyy hh:mm a'
                                                                        )
                                                                        :'' }}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Bill Generation Date
                                                                    </label>
                                                                    <p>{{viewBilldeatils.lastBillGeneratedDate!=null?
                                                                        (viewBilldeatils.lastBillGeneratedDate | date:
                                                                        'dd MMM yyyy hh:mm a'
                                                                        ):''}}
                                                                    </p>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Billed Days</label>
                                                                    <p>{{viewBilldeatils.billDays
                                                                        ?viewBilldeatils.billDays:'-'}}</p>

                                                                </div>
                                                            </div>



                                                        </div>

                                                    </form>
                                                </div>
                                            </div>


                                        </accordion-group>

                                        <accordion-group [isOpen]="isAllOpen" heading="Payment Information"
                                            class="accordion-item mb-3 panel-open" #item6>
                                            <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                                <i class="fas font-size-24"
                                                    [ngClass]="!item6?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                            </span>

                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <form>
                                                        <div class="row">
                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Mode of Payment</label>
                                                                    <p>{{viewBilldeatils.modeOfPayment
                                                                        ?viewBilldeatils.modeOfPayment:'-'}}</p>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Mobile App / Manual
                                                                    </label>
                                                                    <p>{{viewBilldeatils.paymentType?viewBilldeatils.paymentType:'-'}}
                                                                    </p>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Payment Link </label>
                                                                    <p><a href="{{viewBilldeatils.paymentLink}}">
                                                                        </a></p>
                                                                 

                                                                </div>
                                                            </div>


                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Payment ID </label>
                                                                    <p>{{viewBilldeatils.paymentId?viewBilldeatils.paymentId:'-'
                                                                        }} </p>

                                                                </div>
                                                            </div>


                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Order ID</label>
                                                                    <p>{{viewBilldeatils.orderId?viewBilldeatils.orderId:'-'
                                                                        }} </p>

                                                                </div>
                                                            </div>


                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Payment Date</label>
                                                                    <p>{{viewBilldeatils.paymentDate!=null?
                                                                        (viewBilldeatils.paymentDate
                                                                        | date: 'dd MMM yyyy hh:mm a' ):''}}</p>


                                                                </div>
                                                            </div>


                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Settlement ID </label>
                                                                    <p> {{viewBilldeatils.settlementId ?
                                                                        viewBilldeatils.settlementId:'-'}} </p>

                                                                </div>
                                                            </div>


                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Settlement Date</label>
                                                                    <p>{{viewBilldeatils.settlementDate !=null?
                                                                        (viewBilldeatils.ettlementDate |
                                                                        date: 'dd MMMyyyy hh:mm a' ):'-'}}</p>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3  mb-3">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Total Amount Paid</label>
                                                                    <p>{{ viewBilldeatils.paidAmount !=undefinde
                                                                        ?(viewBilldeatils.paidAmount |currency:
                                                                        'INR':true)
                                                                        : '0' }}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </form>
                                                </div>
                                            </div>


                                        </accordion-group>

                                    </accordion>

                                </div>
                            </kendo-pdf-export>

                        </section>
                    </div>

                </div>
               
            </div>
           
        </div>
       
    </div>
 -->

</div>

<!-- Invalid Bill modal -->
<ng-template #invalidBillModal>

    <div class="">
        <div class="">
            <div class="modal-header ">
                <h5 class="modal-title">Invalid Bill</h5>
                <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
                    (click)="modalRefInvoice?.hide()"></button>
            </div>
            <div class="modal-body">

                <div class="mb-3">
                    <label> Reason for invalid bill</label>
                    <textarea [placeholder]="'Enter your reason here'" [(ngModel)]="reason" class="form-control"
                        rows="5"></textarea>
                    <span *ngIf="!reason"></span>
                </div>

            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
                <button type="button" class="btn btn-primary waves-effect waves-light" (click)="saveInvlaidBills()"
                    [disabled]="!reason">Submit</button>
            </div>
        </div><!-- /.modal -->
    </div>
</ng-template>
<!-- Invalid Bill modal End  -->



<!-- mark As Paid -->
<ng-template #paidBillModal>

    <div class="modal-header ">
        <h5 class="modal-title">Bill Payment </h5>
        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
            (click)="billapidmodal?.hide()"></button>
    </div>
    <div class="modal-body">
        <form class="row" [formGroup]="paybycashform">
            <div class="col-lg-6">
                <div class="mb-3">
                    <label>Mode of Payment </label>
                    <input class="form-control" value="cash" type="text" readonly>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label>Amount </label>
                    <input class="form-control" formControlName="amount" [value]="viewBilldeatils.totalAmount"
                        type="text" readonly>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label>Reason </label>
                    <textarea [placeholder]="'Enter your reason here'" formControlName="reason" class="form-control"
                        rows="5"></textarea>
                    <!--  <input class="form-control" formControlName="reason" [value]="viewBilldeatils.totalAmount"
                        type="text"> -->
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label>Bill Payment date </label>
                    <input class="form-control" (change)="billPaymentDateChange($event)" type="date"
                        [attr.min]="minDate" [attr.max]="today"
                        [value]="paybycashform.get('amount')?.value | date:'dd/MM/yyyy'"
                        formControlName="billPaymentDate">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
        <button type="button" class="btn btn-primary waves-effect waves-light" (click)="payByCash()"
            [disabled]="!paybycashform.valid">Submit</button>
    </div>


</ng-template>