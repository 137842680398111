export const rechargeData = [
    { ProjectCode: 'RJ1920212120024', Amount:'₹ 2343', Balance: '₹ 1244', Number: 'GA26973985701980', Utility:'Water', BillDate:'16-04-1960', ProjectType:'John Doe', Status:'Active',},
    { ProjectCode: 'RJ1920212120023', Amount:'₹ 2343',Balance: '₹ 3232', Number: 'MN05528979762569', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', Status:'Active', },
    { ProjectCode: 'RJ1920212120022',Amount:'₹ 2343', Balance: '₹ 8565', Number: 'CH99047547473200', Utility:'Water', BillDate:'16-04-1960', ProjectType:'John Doe', Status:'Active', },
    { ProjectCode: 'RJ1920212120021',Amount:'₹ 2343', Balance: '₹ 7656', Number: 'SK28777524993185', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', Status:'Active', },
    { ProjectCode: 'RJ1920212120020', Amount:'₹ 2343',Balance: '₹ 4623', Number: 'JK63078844163797', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', Status:'Inactive', },
    { ProjectCode: 'RJ1920212120019', Amount:'₹ 2343',Balance: '₹ 3232', Number: 'TR42752187738585', Utility:'Water', BillDate:'16-04-1960', ProjectType:'John Doe', Status:'Inactive', },
    { ProjectCode: 'RJ1920212120018', Amount:'₹ 2343',Balance: '₹ 4344', Number: 'MN64768690324574', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', Status:'Active', },
    { ProjectCode: 'RJ1920212120017', Amount:'₹ 2343',Balance: '₹ 3232', Number: 'PY51023927499600', Utility:'Electricity', BillDate:'16-04-1960', ProjectType:'John Doe', Status:'Active', },
    { ProjectCode: 'RJ1920212120016', Amount:'₹ 2343',Balance: '₹ 4543', Number: 'PY51023927499500', Utility:'Electricity', BillDate:'16-04-1960', ProjectType:'John Doe', Status:'Inactive', },
    { ProjectCode: 'RJ1920212120015', Amount:'₹ 2343',Balance: '₹ 3232', Number: 'PY51023927499100', Utility:'Electricity', BillDate:'16-04-1960', ProjectType:'John Doe', Status:'Active', },
    { ProjectCode: 'RJ1920212120014', Amount:'₹ 2343',Balance: '₹ 6545', Number: 'CH99047547473200', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', Status:'Active', },
    { ProjectCode: 'RJ1920212120013', Amount:'₹ 2343',Balance: '₹ 2343', Number: 'PY51023927499300', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', Status:'Inactive', },
    
];