<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>

        </div>
    </div>

    <div class="mt-3">
        <div class="d-flex justify-content-end mb-3">
            <button type="button" class="btn btn-primary" [routerLink]="'/billInvoice'">
                <i class="bx bx-download font-size-16 align-middle me-2"></i> Download Bill </button>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <app-bills-view></app-bills-view>
                    <!-- <div>
                        <accordion [closeOthers]="false" class="accordion custom-accordian" [isAnimated]="true">
                            <accordion-group [isOpen]="isAllOpen" heading="Bill Details" class=" accordion-item mb-3
                                panel-open" #item1>
                                <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                    <i class="mdi font-size-24"
                                        [ngClass]="!item1?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                </span>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Bill Number</label>
                                                        <p>{{billHostorydeatils?.billNo?billHostorydeatils.billNo:'-'}}
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Residential Unit </label>
                                                        <p>{{billHostorydeatils?.residentialUnit?billHostorydeatils.residentialUnit:'-'}}
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label"> Consumer</label>
                                                        <p>{{billHostorydeatils?.consumerName?billHostorydeatils.consumerName:'-'}}
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Utility </label>
                                                        <p>{{billHostorydeatils?.utilities?billHostorydeatils.utilities:'-'}}
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Meter Number </label>
                                                        <p>{{billHostorydeatils?.macAddress?billHostorydeatils.macAddress:'-'}}
                                                        </p>


                                                    </div>
                                                </div>


                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Bill Amount</label>
                                                        <p> {{billHostorydeatils?.billAmount!=undefinde?(billHostorydeatils.billAmount
                                                            |currency:'INR':true):'0'}}
                                                        </p>


                                                    </div>
                                                </div>
                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label"> Mode of Payment </label>
                                                        <p>{{billHostorydeatils?.paymentStatus?billHostorydeatils.paymentStatus:'-'}}
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Transaction ID </label>
                                                        <p>{{billHostorydeatils?.transactionId?billHostorydeatils.transactionId:'-'}}
                                                        </p>


                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Optional Charges </label>
                                                        <p>{{billHostorydeatils?.optionalCharges
                                                            ?billHostorydeatils.optionalCharges
                                                            :'-'}}
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Additional Charges </label>
                                                        <p> {{billHostorydeatils?.additionalCharges!=undefinde?(billHostorydeatils.additionalCharges
                                                            |currency:'INR':true):'0'}}
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Total Amount </label>
                                                        <p> {{billHostorydeatils?.totalAmount!=undefinde?(billHostorydeatils.totalAmount
                                                            |currency:'INR':true):'0'}}
                                                        </p>

                                                    </div>
                                                </div>

                                            </div>

                                        </form>
                                    </div>
                                </div>

                            </accordion-group>
                            
                            @if(billHostorydeatils?.gas.length>0){
                            <accordion-group [isOpen]="isAllOpen" heading="Unit Rate (Gas)"
                                class="accordion-item mb-3 panel-open" #item2>
                                <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                    <i class="mdi font-size-24"
                                        [ngClass]="!item2?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                </span>

                                <div class="row">
                                    <div class="col-lg-12">

                                        <div class="row" *ngFor="let gas of billHostorydeatils?.gas">
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Unit Rate EB</label>
                                                    <p>{{gas?.unitRateEB?gas.unitRateEB:'-'}}</p>

                                                </div>
                                            </div>

                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Previous Meter Reading </label>
                                                    <p>{{gas?.previousMeterReading?gas.previousMeterReading:'-'}}
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label"> Current Meter Reading</label>
                                                    <p>{{gas?.currentMeterReading?gas.currentMeterReading:'-'}}
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Number of Units consumed </label>
                                                    <p>{{gas?.noofUnitsConsumed?gas.noofUnitsConsumed:'-'}}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                            </accordion-group>}
                            @if(billHostorydeatils?.water.length>0){
                            <accordion-group [isOpen]="isAllOpen" heading="Unit Rate (Water)"
                                class="accordion-item mb-3 panel-open" #item2>
                                <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                    <i class="mdi font-size-24"
                                        [ngClass]="!item2?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                </span>

                                <div class="row" *ngFor="let water of billHostorydeatils?.water">
                                    <div class="col-lg-12">
                                        <form>
                                            <div class="row" *ngFor="let ele of billHostorydeatils?.water">
                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Unit Rate EB</label>
                                                        <p>{{water?.unitRateEB?water.unitRateEB:'-'}}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Previous Meter Reading </label>
                                                        <p>{{water?.previousMeterReading?water.previousMeterReading:'-'}}
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label"> Current Meter Reading</label>
                                                        <p>{{water?.currentMeterReading?water.currentMeterReading:'-'}}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Number of Units consumed </label>
                                                        <p>{{water?.noofUnitsConsumed?water.noofUnitsConsumed:'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>

                            </accordion-group>
                            }
                            @if(billHostorydeatils?.electricity.length>0){
                            <accordion-group [isOpen]="isAllOpen" heading="Unit Rate (Electricity)"
                                class="accordion-item mb-3 panel-open" #item3>
                                <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                    <i class="mdi font-size-24"
                                        [ngClass]="!item3?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                </span>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row" *ngFor="let ele of billHostorydeatils?.electricity">
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Unit Rate EB</label>
                                                    <p>{{ele.unitRateEB?ele.unitRateEB:'-'}}
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Unit Rate DG </label>
                                                    <p>{{ele.unitRateDG?ele.unitRateDG:'-'}}
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label"> Previous Meter Reading EB</label>
                                                    <p>{{ele.electricityPreviousMeterReadingEB?ele.electricityPreviousMeterReadingEB:'-'}}
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Previous Meter Reading DG </label>
                                                    <p>{{ele.electricityPreviousMeterReadingDG?ele.electricityPreviousMeterReadingDG:'-'}}
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Current Meter Reading EB </label>
                                                    <p>{{ele.electricityCurrentMeterReadingEB?ele.electricityCurrentMeterReadingEB:'-'}}
                                                    </p>

                                                </div>
                                            </div>


                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Current meter reading DG</label>
                                                    <p>{{ele.electricityCurrentMeterReadingDG?ele.electricityCurrentMeterReadingDG:'-'}}
                                                    </p>

                                                </div>
                                            </div>


                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label"> No.of units consumed EB </label>
                                                    <p>{{ele.noofElectricityUnitsConsumedEB?ele.noofElectricityUnitsConsumedEB:'-'}}
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label"> No.of units consumed DG </label>
                                                    <p>{{ele.noofElectricityUnitsConsumedDG?ele.noofElectricityUnitsConsumedDG:'-'}}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </accordion-group>}


                            <accordion-group [isOpen]="isAllOpen" heading="General Charges"
                                class="accordion-item mb-3 panel-open" #item4>
                                <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                    <i class="fas font-size-24"
                                        [ngClass]="!item4?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                </span>
                                <div class="row">
                                    <div class="mb-3 col-lg-4" *ngFor="let col of billHostorydeatils?.generalCharges">
                                        <label for="">{{col.name}}</label>
                                        <p>{{col.total}}</p>
                                    </div>
                                </div>



                            </accordion-group>

                            <accordion-group [isOpen]="isAllOpen" heading="Bill Generate"
                                class="accordion-item mb-3 panel-open" #item5>
                                <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                                    <i class="fas font-size-24"
                                        [ngClass]="!item5?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                </span>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Last Month Bill Generated Date</label>
                                                        <p>{{billHostorydeatils?.previousBillDate!=null?
                                                            (billHostorydeatils.previousBillDate |date)
                                                            :'' }}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Bill Generation Date </label>
                                                        <p>{{billHostorydeatils?.billGeneratedDate!=null?
                                                            (billHostorydeatils.billGeneratedDate |date)
                                                            :'' }}
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Billed Dates</label>
                                                        <p>{{billHostorydeatils?.billedDays?billHostorydeatils.billedDays:'-'}}
                                                        </p>
                                                    </div>
                                                </div>



                                            </div>

                                        </form>
                                    </div>
                                </div>


                            </accordion-group>

                            <accordion-group [isOpen]="isAllOpen" heading="Payment Information"
                                class="accordion-item mb-3 panel-open" #item6>
                                <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                    <i class="fas font-size-24"
                                        [ngClass]="!item6?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                </span>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Mode of Payment</label>
                                                        <p>{{billHostorydeatils?.modeOfPayment?billHostorydeatils.modeOfPayment:'-'}}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Mobile App / Manual </label>
                                                        <p>{{billHostorydeatils?.paymentType?billHostorydeatils.paymentType:'-'}}
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Payment Link </label>
                                                        <p> <a
                                                                [href]="'https://www.domainname.com/?q='">{{billHostorydeatils?.paymentType?billHostorydeatils.paymentType:'-'}}</a>
                                                        </p>

                                                    </div>
                                                </div>


                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Payment ID </label>
                                                        <p>{{billHostorydeatils?.paymentId?billHostorydeatils.paymentId:'-'}}
                                                        </p>

                                                    </div>
                                                </div>


                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Order ID</label>
                                                        <p>{{billHostorydeatils?.orderId?billHostorydeatils.orderId:'-'}}
                                                        </p>

                                                    </div>
                                                </div>


                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Payment Date</label>
                                                        <p>{{billHostorydeatils?.paymentDate!=null?
                                                            (billHostorydeatils.paymentDate |date)
                                                            :'' }}
                                                        </p>

                                                    </div>
                                                </div>


                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Settlement ID </label>
                                                        <p>{{billHostorydeatils?.settlementId?billHostorydeatils.settlementId:'-'}}
                                                        </p>

                                                    </div>
                                                </div>


                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Settlement Date</label>
                                                        <p>{{billHostorydeatils?.settlementDate!=null?
                                                            (billHostorydeatils.settlementDate |date)
                                                            :'' }}
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Total Amount Paid</label>
                                                        <p> {{billHostorydeatils?.totalAmount!=undefinde?(billHostorydeatils.totalAmount
                                                            |currency:'INR':true):'0'}}
                                                        </p>

                                                    </div>
                                                </div>

                                            </div>

                                        </form>
                                    </div>
                                </div>


                            </accordion-group>

                        </accordion>

                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>