export const billsData = [
    { ProjectCode: 'Anuj Tatla', Transaction:'1549 4065 9391', Payment:'Master card', BillNumber:'UP2620157945263', ProjectName: 'Yojith', Region: ' 5000', Utility:'Water', BillDate:'6727', ProjectType:'John Doe', ProjectStatus:'Active', Unit:'Welding Service'},
    { ProjectCode: 'Somil Singh', Transaction:'1549 4065 9390', Payment:'Visa', BillNumber:'MH2519959641799', ProjectName: 'Bhavin', Region: ' 5000', Utility:'Water', BillDate:'6727', ProjectType:'John Doe', ProjectStatus:'Active', Unit:'Theatre Artist'},
    { ProjectCode: 'Nikita Kumar', Transaction:'7240 1571 9949', Payment:'Master card', BillNumber:'LA0120208156103', ProjectName: 'Bhavin', Region: ' 5000', Utility:'Gas', BillDate:'6727', ProjectType:'John Doe', ProjectStatus:'Active',Unit:'Masonry Worker' },
    { ProjectCode: 'Anand Kumar', Transaction:'2583 7353 6733', Payment:'Google pay', BillNumber:'MP6419918182216', ProjectName: 'Hridaan', Region: ' 2323', Utility:'Water', BillDate:'2323', ProjectType:'John Doe', ProjectStatus:'Active',Unit:'Forensic Chemist' },
    { ProjectCode: 'Akhilesh Singh', Transaction:'8849 7485 8666', Payment:'Visa', BillNumber:'PB6519874448570',  ProjectName: 'Bhavin', Region: ' 5230', Utility:'Gas', BillDate:'5232', ProjectType:'John Doe', ProjectStatus:'Inactive',Unit:'Chief Marketing Officer' },
    { ProjectCode: 'Prashant Apte', Transaction:'1549 4065 9393', Payment:'Credit Card', BillNumber:'WB3020073807862', ProjectName: 'Yojith', Region: ' 6000', Utility:'Gas', BillDate:'4435', ProjectType:'John Doe', ProjectStatus:'Inactive',Unit:'Catering manager' },
    { ProjectCode: 'Ananya Grover', Transaction:'7024 2174 2084', Payment:'Kotak Credit', BillNumber:'MP4720094952886', ProjectName: 'Bhavin', Region: ' 2000', Utility:'Water', BillDate:'5765', ProjectType:'John Doe', ProjectStatus:'Active', Unit:'Account Manager'},
    { ProjectCode: 'Ishani Ghosh', Transaction:'2465 0318 1281', Payment:' Kotak Credit', BillNumber:'MP4720094952886', ProjectName: 'Bhavin', Region: ' 3223', Utility:'Gas', BillDate:'6234', ProjectType:'John Doe', ProjectStatus:'Active',Unit:'Account Manager' },
    { ProjectCode: 'Ishani Tatla', Transaction:'0484 9923 8688', Payment:' Kotak Credit ', BillNumber:'MP4720094952886', ProjectName: 'Hridaan', Region: ' 5000', Utility:'Electricity', BillDate:'2133', ProjectType:'John Doe', ProjectStatus:'Inactive', Unit:'Catering manager'},
    { ProjectCode: 'Deshad Bhatt', Transaction:'7240 1571 9921', Payment:'Googlepay', BillNumber:'MP4720094952886', ProjectName: 'Bhavin', Region: ' 5000', Utility:'Electricity', BillDate:'3232', ProjectType:'John Doe', ProjectStatus:'Active',Unit:'Account Manager' },
    { ProjectCode: 'Deshad Bhatt1', Transaction:'7240 1571 9921', Payment:'Googlepay', BillNumber:'MP4720094952886', ProjectName: 'Bhavin', Region: ' 5000', Utility:'Electricity', BillDate:'3232', ProjectType:'John Doe', ProjectStatus:'Active',Unit:'Account Manager' },
    { ProjectCode: 'Deshad Bhatt2', Transaction:'7240 1571 9921', Payment:'Googlepay', BillNumber:'MP4720094952886', ProjectName: 'Bhavin', Region: ' 5000', Utility:'Electricity', BillDate:'3232', ProjectType:'John Doe', ProjectStatus:'Active',Unit:'Account Manager' },
    
];