import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  LegendItemClickEvent,
  CategoryAxis,
  Legend,
  Series,
  SeriesLabelsContentArgs,
  LineStyle,
} from '@progress/kendo-angular-charts';
import { DecimalPipe, formatDate } from '@angular/common';
import { SharedService } from '../../shared/shared.service';
import { BillingType, Roles, superadmin_graphColors, } from '../../shared/constants/enum';
import { Store, select } from '@ngrx/store';
import { getadmindashboardResponse, getadminFlatwiseConsmptionsdashboardResponse } from '../../store/dashboard/dashboard-selector';
import { getAdminDashboard, getAdminFlatwiseConsmptionsDashboard } from '../../store/dashboard/dashboard.actions';
import { colorsList, ConsumptioncolorsList, ConsumptionMinMax, monthsArray, utilitiesArray } from '../../shared/constants/templatesNames';
import { error } from 'console';
import { Router } from '@angular/router';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DashboardService } from '../../core/services/dashboard.service';
import {
  ChartComponent,
  ApexChart,
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexMarkers,
  ApexAnnotations,
  ApexStroke
} from "ng-apexcharts";
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  colors: any;
  toolbar: any;
};
interface XAxis {
  min: number; // Timestamp in milliseconds
  max: number; // Timestamp in milliseconds
}

interface UpdateOptionsData {
  [key: string]: {
    xaxis: XAxis;
  };
}
type UtilityType = 'Water' | 'Gas' | 'Electricity';
@Component({
  selector: 'app-prepaid-admin-dashboard',
  templateUrl: './prepaid-admin-dashboard.component.html',
  styleUrl: './prepaid-admin-dashboard.component.scss',
  providers: [DecimalPipe],
})
export class PrepaidAdminDashboardComponent {
  public seriesVisible = true;


  colors_status = superadmin_graphColors;
  dateRange: boolean = false;
  public style: LineStyle = 'normal';
  public line: LineStyle = 'smooth';
  totalBill = 0;
  datePickerConfig: Partial<BsDatepickerConfig>;
  totalRevenue: number = 0;
  totalPrice = 0;
  @ViewChild("chart", { static: false }) chart: ChartComponent | undefined = undefined;

  public chartOptions!: Partial<ChartOptions>; // 
  currentUser: any;
  currentUserData: any;
  private flatwiseadminDashboardData$: Subscription = new Subscription();
  selectedRange: any;
  private adminFlatwiseConsumptionDashboardData$: Subscription =
    new Subscription();

  totalConsumers: number = 0;
  totalMeterStatus: number = 0;
  consumerData: any[] = [];
  residentialUnitsData: any;
  billSummary: any;
  projectId: any;
  projectsName: any;
  utlityType: string = '';
  consuptuinUtility = '';
  selectedUtilityConsumption: any;
  meterUtilityType: any;
  meterStatusUtility: any;
  consuptionSelectedUtilityType: any;
  consuptionSelectedUtility: any;
  consumptionminmaxvalue: any
  selectedUtilityType: any
  totalResidentialUnits: number = 0;
  currentWalletBalance: number = 0;
  totalWalletRecharge: number = 0;
  deductedWalletBalance: number = 0;

  consumptionData: any[] = [];
  waterConsumptionData: any[] = [];
  gasConsumptionData: any[] = [];
  electricityConsumptionData: any[] = [];
  months = monthsArray

  communicationStatusGraphData: any[] = [];
  communicationStatusGraphData_temp: any = [];
  communicationMeterStatusGraphData: any[] = [];
  utilities = utilitiesArray;
  categories: any[] = [];
  consuptionCategories: any[] = [];
  consumptionUtlity: any[] = [];
  public selectedDateRange: { startDate: string; endDate: string } | null =
    null;


  watercategories: any[] = [];
  gascategories: any[] = [];
  electricitycategories: any[] = [];
  Water: any[] = [];
  Gas: any = [];
  Electricity: any = [];



  meterCommunicationStatus: boolean = false;
  meterCommunicationList: any[] = []


  consuptiongraphdata: any = [];
  colors = colorsList
  topConsumptionWater: any = [];
  topConsumptionGas: any = [];
  topConsumptionElectricity: any = [];
  topConsumptionList: any[] = [];
  topconsumptionData: any[] = [];
  topConsumptionWatercategories: any = [];
  topConsumptionGascategories: any = [];
  topConsumptionElectricitycategories: any = [];
  colorslist = ConsumptioncolorsList
  topConsumptioncategories: any = [];
  isWalletEnabled: boolean = false;
  projectName: any
  projectUtlityList: any[] = [];
  projectStartDate: any
  maxminlist = ConsumptionMinMax
  constructor(
    private router: Router,
    private store: Store,
    private decimalPipe: DecimalPipe,
    private sharedService: SharedService,
    private dashboardService: DashboardService
  ) {
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    this.projectUtlityList = this.currentUserData?.projectUtilities;
    this.isWalletEnabled = this.currentUserData?.project?.isWalletEnabled;
    this.projectStartDate = this.currentUserData?.project?.startDate
    //console.log(this.isWalletEnabled)
    const today = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(today.getMonth() - 6);
    // console.log(this.projectUtlityList)
    this.currentUserData = JSON.parse(currentUserString ?? '');
    if (this.currentUserData.project !== null) {
      this.projectId = this.currentUserData.project.id;
      this.projectName = this.currentUserData.project.name;
    }

    this.datePickerConfig = Object.assign(
      {},
      {
        dateInputFormat: 'YYYY-MM-DD', // Customize as needed
        showWeekNumbers: false,
        isAnimated: true,
        containerClass: 'theme-dark-blue',
        minDate: sixMonthsAgo, // Set minimum date to 6 months ago
        maxDate: today
      }
    );
    this.getStoreInfo();
  }


  getStoreInfo() {
    this.selectionChange('Current Month');
    this.flatwiseadminDashboardData$ = this.store
      .pipe(select(getadmindashboardResponse))
      .subscribe(
        (data: any) => {
          if (data) {
            console.log(data);
            this.currentWalletBalance =
              data.data.walletData.currentWallteBalance;
            this.totalWalletRecharge = data.data.walletData.totalWalletRecharge;
            this.deductedWalletBalance =
              data.data.walletData.deductedWalletBalance;

            this.meterCommunicationStatus = data.data.meterConfigCounts == null ? false : true;
            this.meterCommunicationList = data.data.meterConfigCounts
            setTimeout(() => {
              // this.consumptionGraph(this.projectUtlityList[0].name);

              // this.billSummaryData(data.data.billSummaryData);
              this.metreSttausData(this.meterCommunicationList, this.projectUtlityList[0].name);
              this.communicationStatusData(this.meterCommunicationList, this.projectUtlityList[0].name);
              this.getResidentialUnitData(data.data.residentialUnitData);
              this.listOfConsumers(data.data.consumerStats);
            }, 500);
          }
        },
        (error) => {
          console.log(error);
        }
      );

  }
  clearDate() {
    this.filterProjectBillDates = null; // Clear the selected date
  }
  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }
  selectDates(event: any) {
    //console.log(event);
    if (event == undefined) {
      this.billdate = [];

    }
  }
  billdate: any = []
  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }



  ngOnInit(): void {
    this.utlityType = 'Water';
    this.consuptuinUtility = 'Water';
    this.selectedRange = 'Current Month';
    this.selectedUtilityConsumption = this.projectUtlityList[0].id;
    this.consuptionSelectedUtilityType = this.projectUtlityList[0]
    this.consuptionSelectedUtility = this.projectUtlityList[0].id;
    this.meterUtilityType = this.projectUtlityList[0].id;
    this.meterStatusUtility = this.projectUtlityList[0].id;
    this.consumptionminmaxvalue = this.maxminlist[0].id;
    this.selectedUtilityType = this.projectUtlityList[0]

    this.getDashboardData();
  }

  getDashboardData() {
    this.store.dispatch(getAdminDashboard({ ProjectId: this.projectId }));
  }
  ngOnDestroy() {
    this.flatwiseadminDashboardData$.unsubscribe()
    this.adminFlatwiseConsumptionDashboardData$.unsubscribe(); // Or set it to [] if you prefer an empty array
  }
  getflatwiseConsuptiondata() {

    let request = {
      projectId: this.projectId,
      startDate: this.selectedDateRange?.startDate,
      endDate: this.selectedDateRange?.endDate,
    };
    console.log(request)
    this.dashboardService.getAdminFaltwiseConsumptionDashboardData(request).subscribe({
      next: (response) => {
        if (response && response.statusCode == 200) {
          console.log(response);
          this.topConsumptionList = response.data.consumptionDataByFlat;
          // this.consuptiongraphdata = response.data.monthlyConsumptionData;
          this.billSummaryData(response.data.billSummaryData);

          /* if (this.consuptiongraphdata && this.consuptiongraphdata.length > 0) {
            this.consumptioChartDataPrepaid(this.projectUtlityList[0].name);
          } else {
            this.consumptionData = []
          } */
          if (this.topConsumptionList.length > 0) {
            console.log(this.projectUtlityList[0].name)
            this.consuprtionUtilitiesGrahChange(this.projectUtlityList[0].name); // top 5 Consumptions
            this.consuptionSelectedUtility = this.projectUtlityList[0].id;
          } else {
            this.topconsumptionData = [];
          }
        }
        else {
          this.topconsumptionData = [];
          this.topConsumptionElectricity = [];
          this.topConsumptionWater = [];
          this.topConsumptionGas = [];

        }
      }, error: (err) => { },
    })
    this.dashboardService.getAdminConsumptionData(request).subscribe({
      next: (response) => {
        if (response && response.statusCode == 200) {
          console.log(response)
          this.consuptiongraphdata = []
          this.consuptiongraphdata = response.data;
          if (this.consuptiongraphdata && this.consuptiongraphdata.length > 0) {
            this.initChart();
            this.loadConsumptionChart(this.projectUtlityList[0].name)
            // this.consumptioChartDataPrepaid(this.projectUtlityList[0].name);
          } else {
            this.consuptiongraphdata = []
          }
        }
      },
    })



    /*  this.store.dispatch(
       getAdminFlatwiseConsmptionsDashboard({ flatwiseConsmptions: request })
     ); */
  }
  goToWallet() {
    // this.router.navigate(['/wallet'], { state: { consumerData: 'data' }, });
    this.router.navigate(['/wallet']);
  }
  goToWalletReport() {
    // this.router.navigate(['/wallet'], { state: { consumerData: 'data' }, });
    this.router.navigate(['/reports']);


    let request = {
      type: 'Total Wallet Recharge'
    }

    this.router.navigate(['/reports'], {
      state: { consumptionItem: request },
    });

  }


  onDateRangeSelected(dateRange: (Date | undefined)[] | undefined): void {
    this.flatwiseadminDashboardData$.unsubscribe();
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = dateRange[0];
      const endDate = dateRange[1];
      this.selectedDateRange = {
        startDate: formatDate(
          startDate,
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          'en-US'
        ),
        endDate: formatDate(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US'),
      };

      this.topconsumptionData = [];
      this.topConsumptionElectricity = [];
      this.topConsumptionWater = [];
      this.topConsumptionGas = [];


      this.getflatwiseConsuptiondata();
    }
  }

  public updateOptionsData = {
    "Current Month": {
      xaxis: {
        /*  min: new Date("28 Jan 2013").getTime(),
         max: new Date("27 Feb 2013").getTime(), */
        min: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime(),
        max: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime(),
      }
    },
    "Last Month": {
      xaxis: {
        min: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).getTime(),
        max: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime()
      }
    },
    "Last 3 Months": {
      xaxis: {
        min: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1).getTime(),
        max: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime(),
      }
    },
    "Last 6 Months": {
      xaxis: {
        min: new Date(new Date().getFullYear(), new Date().getMonth() - 5, 1).getTime(),
        max: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime(),
      }
    },
  };


  selectionChange(event: any) {
    console.log(event);

    if (event != undefined) {
      this.topConsumptionWatercategories = [];
      this.topConsumptionElectricitycategories = [];
      this.topConsumptionGascategories = [];

      const selectedOption = event;
      this.dateRange = false;
      const now = new Date();
      let startDate: Date;
      let endDate: Date = now;

      switch (selectedOption) {
        case 'Current Month':
          startDate = new Date(now.getFullYear(), now.getMonth(), 1);
          endDate = now; // Current date of the current month
          break;
        case 'Last Month':
          startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1); // First day of last month
          endDate = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of last month
          break;
        case 'Last 3 Months':
          startDate = new Date(now.getFullYear(), now.getMonth() - 3, 1);
          endDate = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of the previous month
          break;
        case 'Last 6 Months':
          startDate = new Date(now.getFullYear(), now.getMonth() - 6, 1);
          endDate = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of the previous month
          break;

        case 'Custom Range':
          this.dateRange = true;

          // Implement custom range logic here
          return;
        default:
          startDate = now;
          endDate = now;
      }

      // Format dates to UTC
      this.selectedDateRange = {
        startDate: formatDate(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US',),
        endDate: formatDate(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US',),
      };

      this.getflatwiseConsuptiondata();
      console.log(this.selectedDateRange);
    } else {
      this.billSummary = [];
      this.selectedDateRange = null;
      this.dateRange = false;
      this.topconsumptionData = [];
      this.consumptionData = []
    }
  }
  filterProjectBillDates: any;








  consuprtionUtilitiesGrahChange(value: any) {
    this.consuptuinUtility = value;
    //console.log('Top Consumption List Length:', this.topConsumptionList.length);
    //console.log('Top Consumption List:', this.topConsumptionList);

    // Reset the data array
    this.topconsumptionData = [];

    console.log(this.topConsumptionList.length)
    // Loop through the data to filter by utility type
    this.topConsumptionList.forEach((flat: any, index: number) => {
      flat.utilityTypes.forEach((utility: any) => {
        if (this.consuptuinUtility === utility.utilityType) {
          this.topconsumptionData.push({
            category: `${flat.flatNo} (tower: ${flat.tower})`,
            value: utility.totalConsumption,
            // colorString will be added later
          });
        }
      });
    });

    // Sort the data by value in descending order
    let sortData = this.topconsumptionData.sort((a, b) => b.value - a.value);
    //console.log('Sorted Data:', sortData);

    let top5Data: any[] = [];
    let last5Data: any[] = [];

    // Handle slicing based on the length of sorted data
    if (sortData.length > 0) {
      if (sortData.length <= 5) {
        // If 5 or fewer records, use all of them as top 5
        top5Data = sortData.slice(0, 5);
        last5Data = [...top5Data]; //
        last5Data.sort((a, b) => a.value - b.value);

      } else if (sortData.length > 5 && sortData.length <= 10) {

        // If more than 5 and up to 10, use top 5 and last 5
        top5Data = sortData.slice(0, 5);

        last5Data = sortData.slice(-5);
        last5Data.sort((a, b) => a.value - b.value);

        //console.log(top5Data)
        // console.log(last5Data)
      } else {
        // If more than 10, just pick top 5
        top5Data = sortData.slice(0, 5);
        last5Data = sortData.slice(- 5);
        last5Data.sort((a, b) => a.value - b.value);

        //console.log(top5Data)
        // console.log(last5Data)
      }
    }

    // Now assign the colors based on the selection
    if (this.consumptionminmaxvalue === 1) {
      // If we're selecting the "top 5" data
      this.colorslist.forEach((color: any, index: number) => {
        if (top5Data[index]) {
          top5Data[index].colorString = color;
        }
      });
      this.topconsumptionData = top5Data;
    } else {
      // If we're selecting the "last 5" data
      this.colorslist.forEach((color: any, index: number) => {
        if (last5Data[index]) {
          last5Data[index].colorString = color;
        }
      });
      this.topconsumptionData = last5Data;
    }

    console.log('Final Top Consumption Data:', this.topconsumptionData);
  }


  getColor(index: number): string {
    // console.log(index);
    const colors = ['#E96360', '#DB6FA0', '#AD8AC0', '#6480BC', '#44C8F5'];
    let color = colors[index % colors.length];
    console.log(color);
    return color; // Cycle through colors
  }
  getSeriesColors(): string[] {
    return this.topconsumptionData[0]?.data.map((_: any, index: number) =>
      this.getColor(index)
    );
  }

  public labelContent(e: SeriesLabelsContentArgs): string {
    return e.value;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // You can perform any action here if needed when the window resizes
    if (this.chart) {
      //console.log("resizes")
      this.chart?.updateOptions({

        chart: {
          width: '100%', // Ensure the chart width updates on window resize
        },
      });
    }
  }
  initChart(): void {
    const startDate = this.selectedDateRange?.startDate ? new Date(this.selectedDateRange.startDate) : null;

    this.chartOptions = {
      series: [
        {
          name: "", // Optional: Set a name for the series if needed
          data: this.consuptiongraphdata, // Ensure this is valid data
        },
      ],
      chart: {
        type: "area",
        height: 250,
        redrawOnParentResize: true,
        toolbar: {
          show: false, // Hide the toolbar/menu options
        },
        zoom: {
          enabled: false, // Disable zoom
        },
        events: {
          mounted: (chart: any) => {
            chart.windowResizeHandler();
          },
          dataPointMouseEnter: (event, chartContext, config) => {
            chartContext.chart.showTooltip(config.dataPointIndex);

          },
          click: (event, chartContext, config) => {
            const dataPointIndex = config.dataPointIndex; // Get the data point index from config
            if (dataPointIndex !== undefined) {
              const selectedSeries = config.seriesIndex;
              // const value = this.chartOptions.series[selectedSeries].data[dataPointIndex];
              const dataPointData = this.consuptiongraphdata[dataPointIndex]; // Get the actual data for the clicked point
              this.handleDataPointClick(selectedSeries, dataPointIndex);
            }
          },
        },
      },
      dataLabels: {
        enabled: false, // Disable data labels
      },
      markers: {
        size: 0, // Hide markers
      },
      xaxis: {
        type: "datetime",
        min: startDate ? startDate.getTime() : undefined, // Set to undefined if startDate is not valid
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    };
  }





  loadConsumptionChart(utilityType: string) {
    console.log(this.consuptiongraphdata);
    this.consumptionData = [];
    console.log(utilityType)
    // const utilityIndex = { "Gas": 0, "Water": 1, "Electricity": 2 }[utilityType];
    const utilityData = this.consuptiongraphdata.find((utility: any) => utility.utilityMasterName === utilityType);

    if (utilityData) {
      const consumptionData = utilityData.totalConsumptionByDay;
      /*  if (utilityIndex !== undefined) {
         console.log(this.consuptiongraphdata[utilityIndex]?.totalConsumptionByDay)
         const consumptionData = this.consuptiongraphdata[utilityIndex]?.totalConsumptionByDay;
    */
      for (const date in consumptionData) {
        if (consumptionData.hasOwnProperty(date)) {
          const value = consumptionData[date];
          this.consumptionData.push({ category: date, value: value });
          // console.log(`category: "${date}", value: ${value}`);
        }
      }

      const convertedData = this.consumptionData.map(item => [
        new Date(item.category).getTime(),
        item.value
      ]);

      console.log('Converted Data:', convertedData);
      console.log(this.chartOptions)
      if (this.chartOptions.series && this.chartOptions.series[0].data) {
        //this.chartOptions.series[0].data = this.chartdata; // Update chart data
        this.chartOptions.series[0].data = convertedData;
      }

      // this.updateOptions(this.selectedRange)
      this.chart?.updateOptions(this.chartOptions, false, true, true); // Refresh chart
    } else {
      console.log(`Utility type "${utilityType}" is not recognized.`);
    }
  }

  public updateOptions(option: keyof UpdateOptionsData): void {
    console.log(option)
    console.log(this.selectedDateRange)
    const optionData = this.updateOptionsData[option as keyof typeof this.updateOptionsData];
    console.log(optionData)
    if (optionData) {
      this.chart?.updateOptions(optionData, false, true, true);
    } else {
      console.log(`Option for '${option}' is undefined.`);
      // Handle undefined option case
    }
  }




  /* not useed This method */
  consumptioChartDataPrepaid(utlityType: any) {
    console.log(utlityType);
    console.log(this.consuptiongraphdata)
    this.consuptionCategories = []
    this.consumptionUtlity = [];
    this.consumptionData = [];
    if (this.consuptiongraphdata.length > 0) {
      this.consuptiongraphdata?.filter((items: any) => {
        console.log((utlityType === items.utilityType && items?.monthlyData.length > 0))
        if (utlityType == items.utilityType && items?.monthlyData.length > 0) {
          this.consumptionData = []
          items?.monthlyData.slice(-6).forEach((item: any) => {
            this.consuptionCategories.push(item.monthName);
            this.consumptionData.push(item.totalConsumption);
          });
        }

      });
      if (this.consumptionData.length > 0) {
        switch (this.selectedRange) {
          case 'Current Month':
            for (let i = 0; i <= 5; i++) {
              console.log(this.consumptionData)
              this.consumptionData.push(0)
            }
            // console.log(this.consumptionData);
            break;

          case 'Last Month':
            for (let i = 0; i <= 4; i++) {
              console.log(this.consumptionData)
              this.consumptionData.push(0)
            }
            // console.log(this.consumptionData);
            break;
          case 'Last 3 Months':
            for (let i = 0; i <= 3; i++) {
              console.log(this.consumptionData)
              this.consumptionData.push(0)
            }
            //  console.log(this.consumptionData);
            break;
          case 'Last 6 Months':
            /*  for (let i = 0; i <= 4; i++) {
               console.log(this.consumptionData)
               this.consumptionData.push(0)
             } */
            console.log(this.consumptionData);
            break;
        }
      }
    } else {
      this.consuptiongraphdata = [];
      this.consuptionCategories = []
    }

  }




  billSummaryData(data: any) {
    //console.log(data);
    this.totalPrice;
    this.billSummary = [
      {
        name: 'Paid Bills',
        revenue: data.paidBillsAmount,
        bill: data.paidBillsCount,
        totalBill: data.totalBillCount,
      },
      {
        name: 'Unpaid Bills',
        revenue: data.unpaidBillsAmount,
        bill: data.unpaidBillsCount,
        totlBill: data.totlBillAmount,
      },
      // { name: 'Total Bills', revenue: data.totalBillCount, bill: data.totlBillAmount,  },
    ];
  }
  calculateTotals() {
    this.totalBill = this.billSummary.reduce(
      (sum: any, project: { bill: any }) => sum + project.bill,
      0
    );
    this.totalRevenue = this.billSummary.reduce(
      (sum: any, project: { revenue: any }) => sum + project.revenue,
      0
    );
  }

  metreSttausData(data: any, type: any) {


    let filterdate = data?.deviceCounts.filter((item: any) => {
      return item.utilityName.toUpperCase() === type.toUpperCase()
    })

    if (filterdate.length > 0) {
      this.totalMeterStatus = filterdate[0].totalCount;
      this.communicationMeterStatusGraphData = [
        { name: 'Turn On', value: filterdate[0].activeCount, color: '#6ED972' },
        { name: 'Turned Off', value: filterdate[0].inActiveCount, color: '#F9AF58' },
      ];
    } else {
      this.communicationMeterStatusGraphData = []
    }

    /* data?.deviceCounts.forEach((item: any) => {
      if (item.utilityName.toUpperCase() === type.toUpperCase()) {
        console.log(item);
        
      }
    }) */

  }
  communicationStatusData(data: any, type: any) {
    console.log(data);
    console.log(type)
    let commCount = 0;
    let actualNonCommCount = 0;
    let nonCommInactiveCount = 0;
    let nonCommMCBOffCount = 0;

    let filterdate = data?.deviceCounts.filter((item: any) => {
      console.log(item)
      return item.utilityName.toUpperCase() === type.toUpperCase()
    })

    if (filterdate.length > 0) {
      this.communicationStatusGraphData = []
      commCount = filterdate[0].commCount,
        actualNonCommCount = filterdate[0].actualNonCommuCount,
        nonCommInactiveCount = filterdate[0].nonCommInActiveCount,
        nonCommMCBOffCount = filterdate[0].nonCommMCBOffCount

      this.communicationStatusGraphData = [
        {
          name: "Communicating",
          data: [commCount],
          color: this.colors_status[1],
          stack: "a"
        },
        {
          name: "Non Communicating",
          data: [actualNonCommCount],
          color: this.colors_status[0],
          stack: "b"
        },
        {
          name: "Inactive",
          data: [nonCommInactiveCount],
          color: this.colors_status[2],
          stack: "b"
        },
        {
          name: "MCB Off",
          data: [nonCommMCBOffCount],
          color: this.colors_status[3],
          stack: "b"
        }
      ];
      if (this.communicationStatusGraphData.length > 0) {
        this.meterCommunicationStatus = true
      }

      console.log(this.communicationStatusGraphData);
    } else {
      this.communicationStatusGraphData = []
      this.meterCommunicationStatus = false
    }

    /*  data?.deviceCounts.forEach((item: any) => {
       if (item.utilityName.toUpperCase() === type.toUpperCase()) {
        
       } else {
 
       }
     });
  */

    /*  this.communicationStatusGraphData = {
       commCount: commCount,
       nonCommCount: nonCommCount,
       nonCommInActiveCount: nonCommInActiveCount,
       nonCommMCBOffCount: nonCommMCBOffCount,
     }; */
    //console.log(this.communicationStatusGraphData);

    this.communicationStatusGraphData_temp = [
      {
        color: '#6ED972 ',
        name: `Non Communicating `,
      },
      {
        color: '#F9AF58',
        name: `Communicating`,
      },
    ];


  }
  MeterStatusUtilityChange(event: any) {
    console.log(event)
    if (event === undefined) {
      this.meterStatusUtility = '';
      this.totalMeterStatus = 0
      this.communicationMeterStatusGraphData = []
    } else {
      this.meterStatusUtility = event.id;
      this.metreSttausData(this.meterCommunicationList, event.name)
    }




  }

  utilityMeterChange(event: any) {
    this.meterUtilityType = event.id
    console.log(event)
    if (event !== undefined) {
      this.communicationStatusData(this.meterCommunicationList, event.name)
    } else {
      this.communicationStatusGraphData = []
      this.meterCommunicationStatus = false
    }


  }
  getResidentialUnitData(data: any) {
    this.totalResidentialUnits = data.totalUnits;
    this.residentialUnitsData = [
      { name: 'Occupied units', value: data.occupiedUnits, color: '#6ED972' },
      {
        name: 'Unoccupied units',
        value: data.unoccupiedUnits,
        color: '#F9AF58',
      },
    ];

  }

  listOfConsumers(data: any) {
    console.log(data);
    this.totalConsumers = data.totalConsumers;
    this.consumerData = [
      { name: 'Active', value: data.activeConsumers, color: '#6ED972' },
      { name: 'Inactive', value: data.inactiveConsumers, color: '#F9AF58' },
    ];
  }


  getClassBasedOnValue(): boolean {
    if (this.currentUserData?.currentUserRoleDetails?.role === Roles.ADMIN) {
      if (typeof this.currentUserData.project.billingType === 'object') {
        return this.currentUserData.project.billingType.name === BillingType.PREPAID;
      } else if (typeof this.currentUserData.project.billingType === 'string') {
        return this.currentUserData.project.billingType === BillingType.PREPAID;
      }
      // console.log(this.currentUserData.project.billingType === BillingType.PREPAID)
      //return this.currentUserData.project.billingType === BillingType.PREPAID;
    }

    return false; // Return false if the role is not ADMIN or no match
  }



  getFormattedNumber(value: number): any {
    return this.decimalPipe.transform(value, '1.0-0');
  }


  utilitiesChange(value: any) {
    console.log(value);
    this.selectedUtilityConsumption = value.id;
    this.consuptionSelectedUtilityType = value;
    this.consumptioChartDataPrepaid(value.name);
  }

  onResidentialUnitsClick(event: any) {

    this.router.navigate(['/residentialUnit'],)
    const dataItem = event.dataItem; // Get the clicked data item
    const category = dataItem.name;
    const value = dataItem.value;
    console.log(dataItem)
    console.log(`Clicked on: ${category} with value: ${value}`);
    let residentialUnit = dataItem.name
    let request = {
      residentialUnitStatus: residentialUnit,
      type: 'dashboard'
    }
    this.router.navigate(['/residentialUnit'], {
      state: { residentialUnitRequest: request },
    });


  }
  onConsumersClick(event: any) {

    const dataItem = event.dataItem; // Get the clicked data item
    const category = dataItem.name;
    const value = dataItem.value;
    console.log(dataItem)
    console.log(`Clicked on: ${category} with value: ${value}`);
    let consumerStatus = dataItem.name == 'Active' ? true : false;
    let request = {
      consumerStatus: consumerStatus,
      type: 'dashboard'
    }
    this.router.navigate(['/consumers'], {
      state: { consumerRequest: request },
    });


  }

  top5ConsumptionClick(event: any): void {
    // Check if the event contains a data point
    if (event && event.dataItem) {
      const clickedData = event.dataItem;
      const regex = /^(\d+)\s*\(tower:\s*(.*?)\)$/;
      const match = clickedData.category.match(regex);
      let FlatNo;
      if (match) {
        // match[1] is the number and match[2] is the tower part, trimming any extra spaces
        FlatNo = `${match[1]} ${match[2].trim()}`;
      }
      //console.log('Clicked Data Point:', clickedData, FlatNo);

      let request = {
        value: clickedData.value,
        UtilityType: this.selectedUtilityType?.name,
        flatNo: FlatNo,
        date: this.selectedDateRange,
        flag: false,
        type: 'top5Consumption'
      }

      this.router.navigate(['/reports'], {
        state: { consumptionItem: request },
      });
      // You can now use clickedData as needed
      // For example, update a property or call another method
    } else {
      console.warn('No data point clicked.');
    }
  }

  onMeterStatusClick(event: any) {
    console.log(event)
    const seriesName = event.series.name
    console.log(event.series)


    const dataItem = event.dataItem; // Get the clicked data item
    const category = dataItem.name;
    const value = dataItem.value;
    console.log(dataItem)
    console.log(`Clicked on: ${category} with value: ${value}`);
    let meterStatus = dataItem.name == 'Turn On' ? true : false;
    let request = {
      meterStatus: meterStatus,
      type: 'dashboard'
    }
    this.router.navigate(['/meters'], {
      state: { meterRequest: request },
    });
  }

  onMeterCommunicationStatusClick(event: any) {
    // Get the index of the clicked series
    const seriesIndex = event.series.index; // or event.seriesIndex depending on your Kendo version

    // Access the complete series data using the index
    const clickedSeries = this.communicationStatusGraphData[seriesIndex];

    // Log the entire clicked series data
    console.log(clickedSeries);
    console.log('Clicked Series Name:', clickedSeries.name);
    console.log('Clicked Series Data:', clickedSeries.data);
    let request = {
      value: clickedSeries,
      type: 'Meter'

    }
    this.router.navigate(['/reports'], {
      state: { consumptionItem: request },
    });
  }


  billStatus(value: any) {
    console.log(value)
    this.router.navigate(['/bills'], {
      state: { billStatus: value },
    });

  }

  handleDataPointClick(seriesIndex: number, dataPointIndex: number): void {
    console.log('Clicked Series Index:', seriesIndex);
    console.log('Clicked Data Point Index:', dataPointIndex);

    // Check if series and data exist
    if (
      this.chartOptions.series &&
      this.chartOptions.series[seriesIndex] &&
      this.chartOptions.series[seriesIndex].data &&
      this.chartOptions.series[seriesIndex].data[dataPointIndex] !== undefined
    ) {
      // Get the selected data point
      const selectedData = this.chartOptions.series[seriesIndex].data[dataPointIndex];

      console.log('Selected Data Point:', selectedData);
      let request = {
        value: selectedData,
        UtilityType: this.selectedUtilityType?.name,
        flag: true,
        type: 'Consumption'
        // date: this.selectedDateRange
      }

      this.router.navigate(['/reports'], {
        state: { consumptionItem: request },
      });
      // Ensure selectedData is valid

    } else {
      console.log('Data point not found in series.');
    }
  }


  public onDataPointSelection(event: any): void {
    console.log(event)
    const dataPointIndex = event[0]?.dataPointIndex;
    const seriesIndex = event[0]?.seriesIndex;

    // Check if series and data exist
    if (
      this.chartOptions.series &&
      this.chartOptions.series[seriesIndex] &&
      this.chartOptions.series[seriesIndex].data &&
      this.chartOptions.series[seriesIndex].data[dataPointIndex] !== undefined
    ) {
      const selectedData = this.chartOptions.series[seriesIndex].data[dataPointIndex];
      console.log('Selected Data Point:', selectedData);

      // Perform additional actions here if needed
    } else {
      console.warn('Selected data point is undefined or does not exist.');
    }
  }



  maxMinValucChange(value: any) {
    if (value !== undefined) {
      console.log(value);
      this.consumptionminmaxvalue = value.id;
      console.log(this.consuptionSelectedUtilityType)
      this.consuprtionUtilitiesGrahChange(this.consuptionSelectedUtilityType.name);
    } else {
      this.topconsumptionData = []
    }
  }

  consuprtionUtilitiesChange(value: any) {
    console.log(value);
    this.topconsumptionData = [];
    this.consuptionSelectedUtility = value.id;
    this.selectedUtilityType = value;
    this.consuptionSelectedUtilityType = value;
    console.log(value.name)
    this.consuprtionUtilitiesGrahChange(value.name);// top 5 consumption
    this.loadConsumptionChart(value.name)
    // this.consumptioChartDataPrepaid(value.name);

    /*  switch (value.name) {
       case "Electricity":
         this.topconsumptionData = this.topConsumptionElectricity;
         break;
       case "Water":
         this.topconsumptionData = this.topConsumptionWater;
         break;
       case "Gas":
         this.topconsumptionData = this.topConsumptionGas;
 
         break;
     } */







  }


}
