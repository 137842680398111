import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UmdState } from './umd.reducer';

export const getUmdState = createFeatureSelector<UmdState>('umd');

export const getUmdResponse = createSelector(
  getUmdState,
  (state: UmdState) => state.getUmdResponse
);

export const createUmdResponse = createSelector(
  getUmdState,
  (state: UmdState) => state.createUmdResponse
);
export const deleteUmdResponse = createSelector(
  getUmdState,
  (state: UmdState) => state.deleteUmdResponse
);

export const getElectricitySuccessResponse = createSelector(
  getUmdState,
  (state: UmdState) => state.getElectricitySuccessResponse
);

export const getAllUmdResponse = createSelector(
  getUmdState,
  (state: UmdState) => state.getAllUmdResponse
);

export const GetAllElectricitySuccessResponse = createSelector(
  getUmdState,
  (state: UmdState) => state.GetAllElectricitySuccessResponse
);
