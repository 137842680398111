export const alertsData = [
    { Utility: 'Gas', MacAddress: 'UP2620157945263', MeterStatus: '6727', CurrentReading:'2321', Currentdate:'07-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'On request sent', ResidentialUnit:'Bhind', Offrequestsent:'No'},
    { Utility: 'Electricity', MacAddress: 'MH2519959641799', MeterStatus: '6727', CurrentReading:'3423', Currentdate:'01-04-2024', Occupant:'Sample Occupant1', Onrequestsent:'Off request sent', ResidentialUnit:'Varanasi', Offrequestsent:'No'},
    { Utility: 'Electricity', MacAddress: 'LA0120208156103', MeterStatus: '6727', CurrentReading:'4332', Currentdate:'16-02-2024', Occupant:'Sample Occupant1', Onrequestsent:'--', ResidentialUnit:'Shahjahanpur',Offrequestsent:'No' },
    { Utility: 'Water', MacAddress: 'MP6419918182216', MeterStatus: '6727', CurrentReading:'3242', Currentdate:'03-04-2024', Occupant:'Sample Occupant1', Onrequestsent:'On request sent', ResidentialUnit:'Bhind',Offrequestsent:'Yes' },
    { Utility: 'Water', MacAddress: 'MP6419918182214', MeterStatus: '6727', CurrentReading:'4544', Currentdate:'25-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'Off request sent', ResidentialUnit:'Bhind',Offrequestsent:'Yes' },
    { Utility: 'Water', MacAddress: 'WB3020073807862', MeterStatus: '6727', CurrentReading:'4533', Currentdate:'01-02-2024', Occupant:'Sample Occupant1', Onrequestsent:'On request sent', ResidentialUnit:'Varanasi',Offrequestsent:'No' },
    { Utility: 'Electricity', MacAddress: 'DL0520147421233', MeterStatus: '6727', CurrentReading:'6754', Currentdate:'11-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'Off request sent', ResidentialUnit:'Shahjahanpur', Offrequestsent:'No'},
    { Utility: 'Gas', MacAddress: 'WB3020073807861', MeterStatus: '6727', CurrentReading:'4534', Currentdate:'11-03-2024', Occupant:'Sample Occupant2', Onrequestsent:'Off request sent', ResidentialUnit:'Pathankot',Offrequestsent:'No' },
    { Utility: 'Water', MacAddress: 'WB3020073807863', MeterStatus: '6727', CurrentReading:'6755', Currentdate:'11-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'On request sent', ResidentialUnit:'Bhind', Offrequestsent:'Yes'},
    { Utility: 'Gas', MacAddress: 'DL0520147421211', MeterStatus: '6727', CurrentReading:'6544', Currentdate:'11-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'Off request sent', ResidentialUnit:'Varanasi',Offrequestsent:'Yes' },
    { Utility: 'Gas', MacAddress: 'DL0520147421221', MeterStatus: '6727', CurrentReading:'4534', Currentdate:'11-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'On request sent', ResidentialUnit:'Pathankot', Offrequestsent:'No' },
    { Utility: 'Electricity', MacAddress: 'DL0520147421288', MeterStatus: '6727', CurrentReading:'5434', Currentdate:'11-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'Off request sent', ResidentialUnit:'Pathankot', Offrequestsent:'No' },
    
];