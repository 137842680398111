<div class="container-fluid">
    <!--  <div class="row">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
    </div> -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    <button class="btn btn-primary d-flex align-items-center" (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>

                </span>

            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <form>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="mb-3">
                            <label class="form-label">From Date -To Date </label>
                            <input type="text" class="form-control date" [(ngModel)]="billdate"
                                (bsValueChange)="selectDates($event)" name="daterange" autocomplete="off"
                                bsDaterangepicker />

                        </div>
                    </div>
                    <div class="col-lg-2 d-flex align-items-center">
                        <button class="btn btn-primary btn-md mt-2" (click)="filterBasedOnDate(1)">Filter</button>
                        <button class="btn btn-secondary btn-md mt-2 ms-2" (click)="filterBasedOnDate(0)">Clear</button>
                    </div>
                </div>
            </form>

        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ng-template #template let-anchor>
                        <span>{{ anchor.nativeElement.innerText }}</span>
                    </ng-template>
                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                        (mouseover)="showTooltip($event)">
                        <kendo-grid [data]="billHistoryData" [reorderable]="true" filterable="menu"
                            [columnMenu]="{ filter: true }" scrollable="scrollable" [resizable]="true"
                            [pageSize]="pageSize" [pageable]="{
                              buttonCount: buttonCount,
                              info: info,
                              type: type,
                              pageSizes: pageSizes,
                              previousNext: previousNext
                            }" [selectable]="selectableSettings" [filter]="billHistoryState.filter" [sortable]="true"
                            [sort]="billHistoryState.sort" [skip]="skip" [sort]="billHistoryState.sort"
                            (filterChange)="onBillFilterChange($event)" (sortChange)="onSortChange($event)"
                            (selectionChange)="onSelect($event)" (pageChange)="billHistoryonPageChange($event)"
                            (dataStateChange)="billHostoryStateChange($event)">
                            <ng-template kendoGridToolbarTemplate>
                                <div class="search_icon">
                                    <kendo-textbox [style.width.px]="250" [clearButton]="true"
                                        (valueChange)="onValueChange($event)" [style.height.px]="35"
                                        [(ngModel)]="searchedValue" placeholder="Search in all columns...">
                                    </kendo-textbox>

                                    <button class="btn btn-primary btn-md border-left-none" (click)="onFilter($event)">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                                <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                                    [(ngModel)]="searchedValue" (valueChange)="onFilter($event)"></kendo-textbox> -->
                                <kendo-grid-spacer></kendo-grid-spacer>

                                <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                    (click)="onExport('Excel')">
                                    <span class="fas fa-file-excel"></span> &nbsp; Export to Excel
                                </button>
                            </ng-template>

                            <!-- Hidden grid for PDF/Excel export -->
                            <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                <kendo-grid [kendoGridBinding]="
                                        selectedRowdata.length > 0
                                            ? selectedRowdata
                                            : exportData.length > 0
                                            ? exportData
                                            : billHistoryData.data
                                    " #pdfGrid>
                                    <kendo-grid-column field="billNo" title="Bill Number"></kendo-grid-column>
                                    <kendo-grid-column field="residentialUnit"
                                        title="Residential Unit"></kendo-grid-column>
                                    <kendo-grid-column field="consumerName" title="Consumer"></kendo-grid-column>
                                    <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                                    <kendo-grid-column field="macAddress" title="Meter No"></kendo-grid-column>
                                    <kendo-grid-column field="billAmount" title="Bill Amount"></kendo-grid-column>
                                    <kendo-grid-column field="modeOfPayment"
                                        title="Mode of Payment"></kendo-grid-column>
                                    <kendo-grid-column field="transactionId" title="Transaction Id"></kendo-grid-column>
                                    <kendo-grid-column field="paymentStatus" title="Payment Status"></kendo-grid-column>
                                    <kendo-grid-excel fileName="billHistory.xlsx"></kendo-grid-excel>
                                    <kendo-grid-pdf fileName="billHistory.pdf" [allPages]="true" paperSize="A4"
                                        [landscape]="true"></kendo-grid-pdf>
                                </kendo-grid>
                            </div>

                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                                class="no-tooltip"></kendo-grid-checkbox-column>
                            <kendo-grid-column field="billNo" title="Bill Number"></kendo-grid-column>
                            <kendo-grid-column field="transactionId" title="Transaction Id"></kendo-grid-column>
                            <kendo-grid-column field="residentialUnit" title="Residential Unit"></kendo-grid-column>
                            <kendo-grid-column field="billDate" title="Bill Date" filter="date">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.billDate | date: 'dd MMM yyyy hh:mm a'}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                            <kendo-grid-column field="macAddress" title="Meter No" [hidden]="true"> </kendo-grid-column>
                            <kendo-grid-column field="billAmount" title="Bill Amount" filter="numeric">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ dataItem.billAmount | currency: 'INR':'symbol':'1.2-2' }}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="modeOfPayment" title="Mode of Payment"
                                [hidden]="true"></kendo-grid-column>
                            <!--  <kendo-grid-column field="transactionId" title="Transaction Id"></kendo-grid-column> -->
                            <kendo-grid-column field="paymentStatus" title="Payment Status">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span *ngIf="dataItem.paymentStatus  === 'Paid'" class="text-success"> {{
                                        dataItem.paymentStatus }}</span>
                                    <span *ngIf="dataItem.paymentStatus === 'Success'" class="text-success">{{
                                        dataItem.paymentStatus
                                        }}</span>
                                    <span *ngIf="dataItem.paymentStatus === 'captured'" class="text-danger">{{
                                        dataItem.paymentStatus
                                        }}</span>
                                    <span *ngIf="dataItem.paymentStatus === 'UnPaid'" class="text-danger">{{
                                        dataItem.paymentStatus
                                        }}</span>



                                    <!--  <span *ngIf="dataItem.paymentStatus" [ngClass]="{
                                'text-success': dataItem.paymentStatus === 'Paid',
                                'text-danger': dataItem.paymentStatus === 'UnPaid'
                              }">
                                                        {{ dataItem.paymentStatus }}
                                                    </span> -->
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-command-column title="" [columnMenu]="false" [width]="70">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <!-- Icon for viewing Bill Details -->
                                    <span kendoTooltip title="View Bill Details" (click)="onViewClick(dataItem)"
                                        style="cursor: pointer; font-size: 20px;">
                                        <i class="fas fa-info-circle text-success"></i>
                                    </span>
                                </ng-template>
                            </kendo-grid-command-column>

                        </kendo-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>