import { CommonModule } from '@angular/common';
import { RechargeHistoryRoutingModule } from './recharge-history-routing.module';
import { RpRechargeHistoryComponent } from './rp-recharge-history/rp-recharge-history.component';
import { GridModule, PDFModule, ExcelModule, } from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { LabelModule } from '@progress/kendo-angular-label';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { ComponentsModule } from '../../shared/components/components.module';
import { SharedModule } from '../../shared/shared.module';
//import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
@NgModule({
  declarations: [
    RpRechargeHistoryComponent,
  ],
  imports: [
    CommonModule,
    RechargeHistoryRoutingModule,
    GridModule, SharedModule,
    PDFModule, BsDatepickerModule,
    ExcelModule, SharedModule,
    TextBoxModule, SortableModule, LabelModule, ComponentsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class RechargeHistoryModule { }
