import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpRechargeHistoryComponent } from './rp-recharge-history/rp-recharge-history.component';

const routes: Routes = [

  { path: 'rechargeHistory', component: RpRechargeHistoryComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RechargeHistoryRoutingModule { }
