import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpBillsComponent } from './rp-bills/rp-bills.component';
import { RpBillsViewComponent } from './rp-bills-view/rp-bills-view.component';
import { RpCreateBillComponent } from './rp-create-bill/rp-create-bill.component';
import { BillSettingsComponent } from './bill-settings/bill-settings.component';
import { BillInvoicesComponent } from './bill-invoices/bill-invoices.component';
import { RpBillHistoryComponent } from './rp-bill-history/rp-bill-history.component';
import { RpBillHistoryViewComponent } from './rp-bill-history-view/rp-bill-history-view.component';
import { RpBillsInvoiceViewComponent } from './rp-bills-invoice-view/rp-bills-invoice-view.component';
import { RpPaymentsComponent } from './rp-payments/rp-payments.component';

const routes: Routes = [

  { path: 'bills', component: RpBillsComponent },
  { path: 'bills/billsDetails', component: RpBillsViewComponent },
  { path: 'createBill', component: RpCreateBillComponent },
  { path: 'billSettings', component: BillSettingsComponent },
  { path: 'billInvoices', component: BillInvoicesComponent },

  { path: 'billHistory', component: RpBillHistoryComponent },
  { path: 'billDetail', component: RpBillHistoryViewComponent },
  { path: 'billInvoices/view-invoice', component: RpBillsInvoiceViewComponent },
  { path: 'billHistory/billDetail', component: RpBillHistoryViewComponent },
  { path: 'payments', component: RpPaymentsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillsRoutingModule { }
