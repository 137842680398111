import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpFeedbackHistoryComponent } from './rp-feedback-history/rp-feedback-history.component';


const routes: Routes = [

  { path: 'feedbackHistory', component: RpFeedbackHistoryComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeedbackHistoryRoutingModule { }
