import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsRoutingModule } from './alerts-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { GridModule, PDFModule,  ExcelModule, } from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SortableModule } from "@progress/kendo-angular-sortable";
import { UploadsModule } from '@progress/kendo-angular-upload';

import { RpAlertsComponent } from './rp-alerts/rp-alerts.component';

@NgModule({
  declarations: [ 
    RpAlertsComponent,
  ],
  imports: [
    CommonModule,
    AlertsRoutingModule,
    NgSelectModule,
    BsDatepickerModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    ModalModule,
    UiSwitchModule,
    SortableModule,
    UploadsModule,
  ],
 
})
export class AlertsModule { }
