import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MappingState } from './mapping.reducer';

export const getmappingState = createFeatureSelector<MappingState>('mapping');

export const getmappingConfigResponse = createSelector(
  getmappingState,
  (state: MappingState) => state.getmappingConfigResponse
);

export const getAllmappingConfigResponse = createSelector(
  getmappingState,
  (state: MappingState) => state.getAllmappingConfigResponse
);

export const unMappingUmdSuccessResponse = createSelector(
  getmappingState,
  (state: MappingState) => state.unMappingUmdSuccessResponse
);

export const mappingUmdSuccessResponse = createSelector(
  getmappingState,
  (state: MappingState) => state.mappingUmdSuccessResponse
);

export const getListOfUnMappingUmdResponse = createSelector(
  getmappingState,
  (state: MappingState) => state.getListOfUnMappingUmdResponse
);
