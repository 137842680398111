export const walletData = [
    { ProjectCode: 'RJ1920212120024', Balance:'₹ 1750', Deducted:'₹ 250', Consumption:'3423', ProjectName: 'UP2620157945263', Rate: '2000', Utility:'Water', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active',},
    { ProjectCode: 'RJ1920212120023', Balance:'₹ 750', Deducted:'₹ 250', Consumption:'5132', ProjectName: 'MH2519959641799', Rate: '1000', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120021', Balance:'₹ 750', Deducted:'₹ 250', Consumption:'3436',  ProjectName: 'MP6419918182216', Rate: '1000', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120020', Balance:'₹ 750', Deducted:'₹ 250', Consumption:'4564',  ProjectName: 'PB6519874448570', Rate: '1000', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    { ProjectCode: 'RJ1920212120019', Balance:'₹ 1750', Deducted:'₹ 250', Consumption:'2341',  ProjectName: 'WB3020073807862', Rate: '2000', Utility:'Water', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    { ProjectCode: 'RJ1920212120018', Balance:'₹ 750', Deducted:'₹ 250', Consumption:'6543',  ProjectName: 'MP4720094952886', Rate: '1000', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120017', Balance:'₹ 250', Deducted:'₹ 250', Consumption:'9939',  ProjectName: 'AP0920035955651', Rate: '500', Utility:'Electricity', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120016', Balance:'₹ 250', Deducted:'₹ 250', Consumption:'4312',  ProjectName: 'DL0520147421233', Rate: '500', Utility:'Electricity', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    { ProjectCode: 'RJ1920212120015', Balance:'₹ 250', Deducted:'₹ 250', Consumption:'9399',  ProjectName: 'PB2020197910921', Rate: '500', Utility:'Electricity', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120014', Balance:'₹ 750', Deducted:'₹ 250', Consumption:'3242',  ProjectName: 'PB2020197910922', Rate: '1000', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120013', Balance:'₹ 750', Deducted:'₹ 250', Consumption:'3234',  ProjectName: 'PB2020197910927', Rate: '1000', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    
];