export const MeterData = [
    { ProjectCode: 'Anuj Tatla', Utility:'Gas', ProjectName: 'LGTOP3300', Region: '₹ 5000',  BillDate:'6727', ProjectType:'John Doe', ProjectStatus:'Active', Unit:'Welding Service'},
    { ProjectCode: 'Somil Singh', Utility:'Water', ProjectName: 'IUOQG8409', Region: '₹ 5000',  BillDate:'6727', ProjectType:'John Doe', ProjectStatus:'Active', Unit:'Theatre Artist'},
    { ProjectCode: 'Nikita Kumar', Utility:'Gas', ProjectName: 'QOKXI9230', Region: '₹ 5000', BillDate:'6727', ProjectType:'John Doe', ProjectStatus:'Active',Unit:'Masonry Worker' },
    { ProjectCode: 'Anand Kumar', Utility:'Water', ProjectName: 'TCEDW3265', Region: '₹ 2323',  BillDate:'2323', ProjectType:'John Doe', ProjectStatus:'Active',Unit:'Forensic Chemist' },
    { ProjectCode: 'Akhilesh Singh', Utility:'Water', ProjectName: 'GWKKU7755', Region: '₹ 5230',  BillDate:'5232', ProjectType:'John Doe', ProjectStatus:'Inactive',Unit:'Chief Marketing Officer' },
    { ProjectCode: 'Prashant Apte', Utility:'Gas', ProjectName: 'IMMBK4267', Region: '₹ 6000',  BillDate:'4435', ProjectType:'John Doe', ProjectStatus:'Inactive',Unit:'Catering manager' },
    { ProjectCode: 'Ananya Grover', Utility:'Gas', ProjectName: 'QNNKH8949', Region: '₹ 2000',  BillDate:'5765', ProjectType:'John Doe', ProjectStatus:'Active', Unit:'Account Manager'},
    { ProjectCode: 'Ishani Ghosh', Utility:'Water', ProjectName: 'FXIWR9217', Region: '₹ 3223',  BillDate:'6234', ProjectType:'John Doe', ProjectStatus:'Active',Unit:'Account Manager' },
    { ProjectCode: 'Ishani Tatla', Utility:'Water', ProjectName: 'TOLFS1805', Region: '₹ 5000',  BillDate:'2133', ProjectType:'John Doe', ProjectStatus:'Inactive', Unit:'Catering manager'},
    { ProjectCode: 'Deshad Bhatt',  Utility:'Water', ProjectName: 'TOLFS1806', Region: '₹ 5000', BillDate:'3232', ProjectType:'John Doe', ProjectStatus:'Active',Unit:'Account Manager' },
    
];