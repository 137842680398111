<div class="container-fluid">

    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-0 font-size-18">Alerts</h4>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">

                    <kendo-grid [kendoGridBinding]="gridData" [reorderable]="true" filterable="menu"
                        [columnMenu]="{ filter: true }" [resizable]="true" [pageable]="true" [pageSize]="10"
                        [sortable]="true" [selectable]="true" kendoGridSelectBy="id" [resizable]="true">

                        <kendo-grid-pdf fileName="GridExport.pdf">
                        </kendo-grid-pdf>
                        <kendo-grid-excel fileName="GridExport.xlsx">
                        </kendo-grid-excel>

                        <ng-template kendoGridToolbarTemplate>
                            <kendo-textbox [style.width.px]="250" [style.height.px]="35"
                                placeholder="Search in all columns..." (valueChange)="onFilter($event)"></kendo-textbox>
                            <kendo-grid-spacer></kendo-grid-spacer>
                            <button kendoGridPDFCommand class="btn btn-light">
                                <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button>
                            <button kendoGridExcelCommand class="btn btn-light">
                                <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                            </button>

                        </ng-template>


                        <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                            [width]="40"></kendo-grid-checkbox-column>
                        <kendo-grid-column field="MeterStatus" title="Meter"></kendo-grid-column>
                        <kendo-grid-column field="Utility" title="Gateway"></kendo-grid-column>
                        <kendo-grid-column field="MacAddress" title="Last sent"></kendo-grid-column>

                    </kendo-grid>


                </div>
            </div>
        </div>
    </div>
</div>