import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { User } from '../../store/Authentication/auth.models';
import { AppConfigData } from '../config/appConfigData';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  configUrl = 'http://localhost:5000/';
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) { }
  /*  payUBuy() {
  return this.http.get<any>(`${this.configUrl}payu-payment`);
  } */
  createOrder(orderData: any) {
    return this.http.post(`${this.configUrl}/createOrder`, orderData);
  }

  createpOrderId(request: any,): Observable<any> {
    // let url = `${AppConfigData.createOrder}?amount=${amount}`;
    let url = `${AppConfigData.createOrder}`
    return this.http.post<any>(url, request).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  getPaymentDetails(payment: any): Observable<any> {
    return this.http.post<any>(`${AppConfigData.paymentDetails}`, payment,).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  payBill(payment: any): Observable<any> {
    return this.http.post<any>(`${AppConfigData.paybill}`, payment,).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  createpOrderIdForpostpaid(request: any,): Observable<any> {
    // let url = `${AppConfigData.createOrder}?amount=${amount}`;
    let url = `${AppConfigData.createOrderIdForPoastpaidUser}`
    return this.http.post<any>(url, request).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  paymentWalletFailure(orderId: any,): Observable<any> {
    // let url = `${AppConfigData.createOrder}?amount=${amount}`;
    let url = `${AppConfigData.paymentWalletFailure + orderId.OrderId}`
    return this.http.post<any>(url, orderId.OrderId).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  paymentBillingFailure(orderId: any,): Observable<any> {
    // let url = `${AppConfigData.createOrder}?amount=${amount}`;
    let url = `${AppConfigData.paymentBillingFailure + orderId.OrderId}`
    return this.http.post<any>(url, orderId.OrderId).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

}
