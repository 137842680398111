import { LoaderService } from '../../../core/services/loader.service';
import { Component, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/shared.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Helper } from '../../../core/helpers/helper';
import {
  MappingUmd,
  getListOfUnMappingUmd,
} from '../../../store/mapping/mapping.actions';
import { Umd } from '../../../shared/modals/common';
import { Validations } from '../../../shared/constants/validations';
import { getListOfUnMappingUmdResponse } from '../../../store/mapping/mapping-selector';

@Component({
  selector: 'app-rp-app-mapping',
  templateUrl: './rp-app-mapping.component.html',
  styleUrl: './rp-app-mapping.component.scss',
})
export class RpAddMappingComponent {
  @Input() monitoringData!: any;
  configData: any;
  createMappingForm!: UntypedFormGroup;
  submitted: boolean = false;
  Mapping_Validations = Validations.Mapping;
  title: string = 'Create New Mapping';
  projectId: any;
  unmappedUmdList: any = [];

  constructor(
    private router: Router,
    private store: Store,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.getStoreInfo();
  }

  getStoreInfo() {
    // this.store.dispatch(resetState());

    let getListOfUnMappingUmdResponse$ = this.store.pipe(
      select(getListOfUnMappingUmdResponse)
    );
    getListOfUnMappingUmdResponse$.subscribe((res: any) => {
      if (res) {
        this.unmappedUmdList = [...res];
      }
    });
  }
  dismissModal() {
    this.modalService.hide();
  }

  CreateMapping(type: string) {
    this.submitted = true;
    // let projectId = '8d278d1e-7629-4790-cff6-08dc798b288a';
    if (this.createMappingForm.valid) {
      this.store.dispatch(
        MappingUmd({
          id: this.monitoringData.id,
          Umdid: this.createMappingForm.get('utilityId')?.value,
        })
      );
      this.modalService.hide();
    }
  }

  ngOnInit(): void {
    this.createMappingForm = this.formBuilder.group({
      utilityId: [null, Validators.required],
    });
  }
  ngAfterViewInit() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectId = currentUser?.project.id;
    this.getAllUnMappingUmds();
  }

  getAllUnMappingUmds(): void {
    this.store.dispatch(getListOfUnMappingUmd({ id: this.projectId }));
  }
}
