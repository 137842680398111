import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillsRoutingModule } from './bills-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RpBillsComponent } from './rp-bills/rp-bills.component';
import { RpBillsViewComponent } from './rp-bills-view/rp-bills-view.component';
import { RpCreateBillComponent } from './rp-create-bill/rp-create-bill.component';
import { BillSettingsComponent } from './bill-settings/bill-settings.component';
import { ComponentsModule } from '../../shared/components/components.module';
import { SharedModule } from '../../shared/shared.module';
import { BillInvoicesComponent } from './bill-invoices/bill-invoices.component';
import { RpBillHistoryComponent } from './rp-bill-history/rp-bill-history.component';
import { RpBillHistoryViewComponent } from './rp-bill-history-view/rp-bill-history-view.component';
import { RpBillsInvoiceViewComponent } from './rp-bills-invoice-view/rp-bills-invoice-view.component';
import { RpPaymentsComponent } from './rp-payments/rp-payments.component';
@NgModule({
  declarations: [
    RpBillsComponent,
    RpBillsViewComponent,
    RpCreateBillComponent,
    BillSettingsComponent,
    BillInvoicesComponent,
    RpBillHistoryComponent,
    RpBillHistoryViewComponent,
    RpBillsInvoiceViewComponent,
    RpPaymentsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BillsRoutingModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    ModalModule,
    UiSwitchModule,
    SortableModule,
    UploadsModule,
    NgStepperModule,
    CdkStepperModule,
    AccordionModule,
    PDFExportModule,
    ExcelExportModule,
    ComponentsModule,
    SharedModule,
  ],
})
export class BillsModule { }
