import { createAction, props } from '@ngrx/store';
// import { mapping } from './mapping.models';

// mapping creation
export const Getmapping = createAction(
  '[mapping] getmapping',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    id: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getmappingSuccess = createAction(
  '[mapping] Getmapping Success',
  props<{ getmappingConfigResponse: any }>()
);
export const getmappingFailure = createAction(
  '[mapping] Getmapping Failure',
  props<{ getmappingError: any }>()
);

//all master data
export const GetAllmapping = createAction(
  '[mapping] getAllmapping',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    id: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllmappingSuccess = createAction(
  '[mapping] GetAllmapping Success',
  props<{ getAllmappingConfigResponse: any }>()
);
export const getAllmappingFailure = createAction(
  '[mapping] GetAllmapping Failure',
  props<{ getAllmappingError: any }>()
);

// mapping creation
export const unMappingUmd = createAction(
  '[mapping] unMappingUmd',
  props<{
    id: string;
    Umdid: string;
  }>()
);

export const unMappingUmdSuccess = createAction(
  '[mapping] unMappingUmdSuccess Success',
  props<{ unMappingUmdSuccessResponse: any }>()
);
export const unMappingUmdFailure = createAction(
  '[mapping] unMappingUmdFailure Failure',
  props<{ unMappingUmdSuccessError: any }>()
);

export const MappingUmd = createAction(
  '[mapping] MappingUmd',
  props<{
    id: string;
    Umdid: [];
  }>()
);
export const mappingUmdSuccess = createAction(
  '[mapping] mappingUmdSuccess Success',
  props<{ mappingUmdSuccessResponse: any }>()
);
export const mappingUmdFailure = createAction(
  '[mapping] mappingUmd Failure',
  props<{ mappingUmdSuccessError: any }>()
);

export const getListOfUnMappingUmd = createAction(
  '[mapping] getListOfUnMappingUmd',
  props<{
    id: string;
  }>()
);
export const getListOfUnMappingUmdSuccess = createAction(
  '[mapping] getListOfUnMappingUmd Success',
  props<{ getListOfUnMappingUmdResponse: any }>()
);
export const getListOfUnMappingUmdFailure = createAction(
  '[mapping] getListOfUnMappingUmd Failure',
  props<{ getListOfUnMappingUmdError: any }>()
);

export const resetmappingDetailsState = createAction(
  '[resetState ] resetState'
);

//store reset
export const resetState = createAction('[App] Reset State');
