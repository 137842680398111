<div class="container-fluid">

    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>

                <span class="breadcrumb m-0">
                    <button class="btn btn-primary d-flex align-items-center" (click)="walletsExportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;All Export to Excel
                    </button>
                </span>
            </div>
        </div>



        <app-wallet-history (countChange)="onCountChange($event)"></app-wallet-history>
    </div>