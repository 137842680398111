<div class="container-fluid">

  <div class="row">
    <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
    <!-- <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-0 font-size-18">Create Bill</h4>
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item active" [routerLink]="'/bills'"><a href="javascript: void(0);">Bill</a>
            </li>

            <li class="breadcrumb-item">Bills Details
            </li>
End date
          </ol>
        </div>
      </div>
    </div> -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <form class="row" [formGroup]="billsForm">
            <div class="row">
              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Residential Unit <span class="required">*</span></label>
                  <ng-select [items]="residentialUnitList" formControlName="ResidentialUnit"
                    (change)="residentialUnitChange($event)" [multiple]="false" bindLabel="residentialUnit"
                    bindValue="residentialUnitId" [placeholder]="'Select...'"></ng-select>
                  <!--  @if(submitted && billsControls['ResidentialUnit'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['ResidentialUnit'].errors['required']){
                    <div>{{prifileValidations.bill.residentialUnit}}</div>}

                  </div>} -->
                </div>
              </div>

              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Consumer Name <span class="required">*</span></label>
                  <input type="text" class="form-control" formControlName="consumer" placeholder="Consumer Name">
                  @if(submitted && billsControls['consumer'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['consumer'].errors['required']){
                    <div>{{prifileValidations.bill.consumer}}</div>}

                  </div>}
                </div>
              </div>

              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Utility <span class="required">*</span></label>
                  <ng-select [items]="utilityTypeList" formControlName="utilityType" id="ocupantName" name="ocupantName"
                    (clear)="resetCalculations($event)" [(ngModel)]="selectedUtilityType"
                    (change)="utilityChange($event)" [multiple]="ismultiDropDown" bindLabel="utilityName"
                    bindValue="utilityId" [placeholder]="'Select...'"></ng-select>
                  @if(submitted && billsControls['utilityType'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['utilityType'].errors['required']){
                    <div>{{prifileValidations.bill.utility}}</div>}

                  </div>}
                </div>
              </div>

              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Meter Number <span class="required">*</span></label>
                  <ng-select [items]="utilityMetersList" formControlName="MeterNumber" id="MeterNumber"
                    (change)="meterChange($event)" [(ngModel)]="selectedMeterNos" name="MeterNumber"
                    [multiple]="ismultiDropDown" bindLabel="macAddress" bindValue="umdId"
                    [placeholder]="'Select...'"></ng-select>
                  @if(submitted && billsControls['MeterNumber'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['MeterNumber'].errors['required']){
                    <div>{{prifileValidations.bill.mterNumber}}</div>}

                  </div>}
                </div>
              </div>

              @if(this.billsData.billType == 'manual'){
              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Select Bill For Month <span class="required">*</span></label>
                  <ng-select [items]="billformonthList" formControlName="selectBillForMonth" id="MeterNumber"
                    name="MeterNumber" (change)="monthChange($event)" bindLabel="name" bindValue="id" [multiple]="false"
                    [placeholder]="'Select...'"></ng-select>

                  @if(submitted && billsControls['selectBillForMonth'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['selectBillForMonth'].errors['required']){
                    <div>{{prifileValidations.bill.selectBillForMonth}}</div>}

                  </div>}
                </div>
              </div>
              }
              <!-- <input class="form-control" type="date" formControlName="selectBillForMonth"> -->
              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Total Amount</label>
                  <input type="text" class="form-control" formControlName="totalAmount" placeholder="">
                  @if(submitted && billsControls['totalAmount'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['totalAmount'].errors['required']){
                    <div>{{prifileValidations.bill.totalAmount}}</div>}

                  </div>}
                </div>
              </div>
              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Bill Status</label>
                  <input type="text" class="form-control" formControlName="billStatus" placeholder="" value="Unpaid">
                  @if(submitted && billsControls['billStatus'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['billStatus'].errors['required']){
                    <div>{{prifileValidations.bill.billStatus}}</div>}

                  </div>}
                </div>
              </div>
              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">previous Month Bill generated Date </label>
                  <input class="form-control" type="date" format="'dd/MM/yyyy'"
                    formControlName="LastMonthBillGeneratedDate">
                </div>
              </div>

              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Bill Generated Date </label>
                  <input class="form-control" type="date" placeholder="dd/MM/yyyy" format="'dd/MM/yyyy'"
                    formControlName="billGeneratedDate">

                </div>
              </div>

              @if(billsData.billType=='adhoc'){
              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Start date </label>
                  <input class="form-control" type="date" formControlName="startdate">
                  <!--  <input type="text" class="form-control date" (bsValueChange)="Selectdates($event)"
                    formControlName="SelectDatesForBillGaneration" [bsConfig]="datePickerConfig"
                    (click)="hidden=!hidden" autocomplete="off" bsDaterangepicker />
                  
                  @if(submitted && billsControls['SelectDatesForBillGaneration'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['SelectDatesForBillGaneration'].errors['required']){
                    <div>{{prifileValidations.bill.SelectDatesForBillGaneration}}</div>}

                  </div>} -->
                </div>
              </div>
              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">End date </label>
                  <input type="text" class="form-control date" formControlName="endDate" placeholder="Select Date"
                    name="singleDate" autocomplete="off" (bsValueChange)="Selectdates($event)" bsDatepicker [bsConfig]="{
                    isAnimated: true, 
                    dateInputFormat: 'DD/MM/YYYY',
                    maxDate:today,
                         showWeekNumbers: false,
            }" />
                  <!-- <input class="form-control" type="date" format="'dd/MM/yyyy'" [attr.min]="getMinDate()"
                    (change)="Selectdates($event)" [attr.max]="getcurentdateDisabled()" formControlName="endDate"> -->
                </div>

              </div>}

              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Billed days – Dates diff </label>
                  <input type="text" class="form-control" formControlName="billedDays" placeholder="">
                  @if(submitted && billsControls['billedDays'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['billedDays'].errors['required']){
                    <div>{{prifileValidations.bill.billDays}}</div>}

                  </div>}
                </div>
              </div>
              <!-- 
              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">General Charges </label>
                  <input type="text" class="form-control" placeholder="" formControlName="generalCharge">
                  @if(submitted && billsControls['generalCharge'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['generalCharge'].errors['required']){
                    <div>{{prifileValidations.bill.generalCharge}}</div>}

                  </div>}
                </div>
              </div>  -->
              <!-- accordion Start -->
              <div>
                @if(gas_array.length>0){ <accordion [closeOthers]="false" class="accordion custom-accordian"
                  [isAnimated]="true">
                  <accordion-group [isOpen]="isGasOpen" [heading]="lengthOfgas" class="accordion-item mb-3 panel-open"
                    #group>
                    <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                      <i class="mdi font-size-24"
                        [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                    </span>
                    <div class="" formArrayName="gas_array">
                      <div *ngFor="let gas of gas_array.controls; let empIndex=index">
                        <div [formGroupName]="empIndex">
                          <div class="row">
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Meter No</label>
                                <input type="text" class="form-control" name="macAddress" id="macAddress"
                                  formControlName='macAddress'>

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Type</label>
                                <input type="text" class="form-control" formControlName='type'>

                              </div>
                            </div>


                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Unit Rate</label>
                                <input type="text" class="form-control" name="UnitRateGas" id="UnitRateGas"
                                  formControlName='UnitRateGas' [disabled]="true">
                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Previous meter Readings</label>
                                <input class="form-control" type="text" name="previousMeterReadingGas"
                                  id="previousMeterReadingGas" formControlName='previousMeterReadingGas'>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Current Meter Readings </label>
                                <input type="text" class="form-control" name="componenurrentMeterReadingGas"
                                  id="componenurrentMeterReadingGas" formControlName='componenurrentMeterReadingGas'>
                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Number of units consumed </label>

                                <input type="text" class="form-control" name="noOfUnitsConsumedGas"
                                  id="noOfUnitsConsumedGas" formControlName='noOfUnitsConsumedGas'>

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Bill Amount</label>
                                <input type="text" class="form-control" name="billAmount" id="billAmount"
                                  formControlName='billAmount'>

                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Total Amount</label>
                                <input type="text" class="form-control" name="totalAmount" id="totalAmount"
                                  formControlName='totalAmount'>

                              </div>
                            </div>

                          </div>
                          <hr>

                          <!-- <p *ngIf="gas_otherCgarges && gas_otherCgarges.length>0"><b>Other Charges</b>

                          <div class="row">
                            <div class="mb-3 col-lg-4" *ngFor="let col of gas_otherCgarges">
                              <label for="">{{col.name}}</label>
                              <p>{{col.total}}</p>
                            </div>
                          </div> -->
                        </div>
                        <hr>
                      </div>
                    </div>


                  </accordion-group>

                </accordion>}

                @if(water_array.length>0){ <accordion [closeOthers]="false" class="accordion custom-accordian"
                  [isAnimated]="true">
                  <accordion-group [isOpen]="isWaterOpen" [heading]="lengthOFwater"
                    class="accordion-item mb-3 panel-open" #group>
                    <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                      <i class="mdi font-size-24"
                        [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                    </span>
                    <div class="" formArrayName="water_array">
                      <div *ngFor="let water of water_array.controls; let empIndex=index">
                        <div [formGroupName]="empIndex">

                          <div class="row">
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Meter No</label>
                                <input type="text" class="form-control" name="macAddress" id="macAddress"
                                  formControlName='macAddress' [disabled]="true">

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Unit Rate</label>
                                <input type="text" class="form-control" name="unitRateWater" id="unitRateWater"
                                  formControlName='unitRateWater' [disabled]="true">

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Previous meter Readings</label>
                                <input class="form-control" type="text" name="previousMeterReadingWater"
                                  id="previousMeterReadingWater" formControlName='previousMeterReadingWater'>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Current Meter Readings </label>
                                <input type="text" class="form-control" name="currentMeterReadinWater"
                                  id="currentMeterReadinWater" formControlName='currentMeterReadinWater'>
                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Number of units consumed </label>

                                <input type="text" class="form-control" name="noOfUnitsConsumedWater"
                                  id="noOfUnitsConsumedWater" formControlName='noOfUnitsConsumedWater'>

                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Bill Amount</label>
                                <input type="text" class="form-control" name="billAmount" id="billAmount"
                                  formControlName='billAmount'>

                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Total Amount</label>
                                <input type="text" class="form-control" name="totalAmount" id="totalAmount"
                                  formControlName='totalAmount'>

                              </div>
                            </div>
                            <hr>

                            <!-- <p *ngIf="water_otherCgarges&& water_otherCgarges.length>0"><b>Other Charges</b>
                            <div class="row">
                              <div class="mb-3 col-lg-4" *ngFor="let col of water_otherCgarges">
                                <label for="">{{col.name}}</label>
                                <p>{{col.total}}</p>
                              </div>


                            </div> -->

                          </div>
                        </div>
                        <hr>
                      </div>
                    </div>
                  </accordion-group>

                </accordion>}

                @if(electricity_array.length>0){<accordion [closeOthers]="false" class="accordion custom-accordian"
                  [isAnimated]="true">
                  <accordion-group [isOpen]="isEleOpen" [heading]="lengthOfelectricity"
                    class="accordion-item mb-3 panel-open" #group>
                    <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                      <i class="mdi font-size-24"
                        [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                    </span>
                    <div class="" formArrayName="electricity_array">
                      <div *ngFor="let row of electricity_array.controls; let empIndex=index">
                        <div [formGroupName]="empIndex">



                          <div class="row">
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Meter No</label>
                                <input type="text" class="form-control" name="MeterNo" id="MeterNo"
                                  formControlName='MeterNo' [disabled]="true">

                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Type</label>
                                <input type="text" class="form-control" name="type" id="type" formControlName='type'>

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Unit Rate EB</label>
                                <input type="text" class="form-control" name="unitRateEB" id="unitRateEB"
                                  formControlName='unitRateEB' [disabled]="true">

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Unit Rate DG</label>
                                <input class="form-control" type="text" name="unitRateDG" id="unitRateDG"
                                  formControlName='unitRateDG'>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Previous Meter Reading EB </label>
                                <input type="text" class="form-control" name="previousMeterReadingEB"
                                  id="previousMeterReadingEB" formControlName='previousMeterReadingEB'>
                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Current Meter Reading EB </label>
                                <input type="text" class="form-control" name="currentMeterReadingEB"
                                  id="currentMeterReadingEB" formControlName='currentMeterReadingEB'>

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Number of Units Consumed EB </label>
                                <input type="text" class="form-control" name="noOfUnitsConsumedEB"
                                  id="noOfUnitsConsumedEB" formControlName='noOfUnitsConsumedEB'>

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Previous Meter Reading DG </label>
                                <input type="text" class="form-control" name="previousMeterReadingDG"
                                  id="previousMeterReadingDG" formControlName='previousMeterReadingDG'>

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Current Meter Reading DG</label>
                                <input type="text" class="form-control" name="currentMeterReadingDG"
                                  id="currentMeterReadingDG" formControlName='currentMeterReadingDG'>

                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Number Of units Consumed DG </label>
                                <input type="text" class="form-control" name="noOfUnitsConsumedDG"
                                  id="noOfUnitsConsumedDG" formControlName='noOfUnitsConsumedDG'>

                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Bill Amount</label>
                                <input type="text" class="form-control" name="billAmount" id="billAmount"
                                  formControlName='billAmount'>

                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Total Amount</label>
                                <input type="text" class="form-control" name="totalAmount" id="totalAmount"
                                  formControlName='totalAmount'>
                              </div>
                            </div>

                          </div>
                          <hr>
                          <!-- <p *ngIf="ele_otherCgarges.length>0"><b>Other Charges</b>

                          <div class="row">
                            <div class="mb-3 col-lg-4" *ngFor="let col of ele_otherCgarges">
                              <label for="">{{col.name}}</label>
                              <p>{{col.total}}</p>
                            </div>
                          </div> -->

                        </div>
                        <hr>
                      </div>
                    </div>
                  </accordion-group>

                </accordion>}

                <!-- other Charges -->
                @if(otherCharge.length>0){ <accordion [closeOthers]="false" class="accordion custom-accordian"
                  [isAnimated]="true">
                  <accordion-group [isOpen]="isEleOpen" heading="Other Charges" class="accordion-item mb-3 panel-open"
                    #group>
                    <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                      <i class="mdi font-size-24"
                        [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                    </span>

                    <div class="row">
                      <div class="mb-3 col-lg-4" *ngFor="let col of updateBillsbyResidentilauit.commonCharges">
                        <label for="">{{col.name}}</label>
                        <p>{{col.total}}</p>
                      </div>
                    </div>
                  </accordion-group>
                </accordion>}
              </div>
            </div>

          </form>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary" [routerLink]="'/bills'">
              Cancel
            </button>&nbsp;
            <button class="btn btn-primary" (click)="saveBills()" [disabled]="isdisabled">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>