export const invoiceData = [
    { ProjectCode: '#SK0001', ProjectName: '6727', Region: 'Valikar', Utility:'yojith.valikar@gmail.com', BillDate:'01/02/2024', ProjectType:'John Doe', ProjectStatus:'Active',},
    { ProjectCode: '#SK0002', ProjectName: '6727', Region: 'Rattan', Utility:'bhavinRattan@gmail.com', BillDate:'04/02/2024', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: '#SK0003', ProjectName: '6727', Region: 'Sharma', Utility:'bhavin.sharma@gmail.com', BillDate:'04/04/2024', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: '#SK0004', ProjectName: '6727', Region: 'Rattan', Utility:'hridaan_rattan@gmail.com', BillDate:'21/03/2024', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: '#SK0005', ProjectName: '6727', Region: 'Rattan', Utility:'bhavinRattan@gmail.com', BillDate:'21/03/2024', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    { ProjectCode: '#SK0006', ProjectName: '6727', Region: 'Kotichintala', Utility:'yojith_kotichintala@gmail.com', BillDate:'14/03/2024', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    { ProjectCode: '#SK0007', ProjectName: '6727', Region: 'Goda', Utility:'bhavinGoda@gmail.com', BillDate:'10/03/2024', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: '#SK0008', ProjectName: '6727', Region: 'Kotichintala', Utility:'bhavinKotichintala@gmail.com', BillDate:'26/01/2024', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: '#SK0009', ProjectName: '6727', Region: 'Goda', Utility:'GodaHridaan@gmail.com', BillDate:'05/04/2024', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    { ProjectCode: '#SK0010', ProjectName: '6727', Region: 'Kotichintala', Utility:'bhavinKotichintala@gmail.com', BillDate:'05/04/2024', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: '#SK0011', ProjectName: '6727', Region: 'Goda', Utility:'HridaanGoda@gmail.com', BillDate:'05/04/2024', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: '#SK0012', ProjectName: '6727', Region: 'Goda', Utility:'YojithGoda@gmail.com', BillDate:'05/04/2024', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    
];