export const billData = [
    { Utility: 'Gas', MacAddress: '1000', MeterStatus: 'Active', CurrentReading:'2321', Currentdate:'07-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'Yes', ResidentialUnit:'Bhind', Offrequestsent:'No'},
    { Utility: 'Gas', MacAddress: '1000', MeterStatus: 'Inactive', CurrentReading:'3423', Currentdate:'01-04-2024', Occupant:'Sample Occupant1', Onrequestsent:'Yes', ResidentialUnit:'Varanasi', Offrequestsent:'No'},
    { Utility: 'Water', MacAddress: '1000', MeterStatus: 'Active', CurrentReading:'4332', Currentdate:'16-02-2024', Occupant:'Sample Occupant1', Onrequestsent:'No', ResidentialUnit:'Shahjahanpur',Offrequestsent:'No' },
    { Utility: 'Water', MacAddress: '1000', MeterStatus: 'Inactive', CurrentReading:'3242', Currentdate:'03-04-2024', Occupant:'Sample Occupant1', Onrequestsent:'Yes', ResidentialUnit:'Bhind',Offrequestsent:'Yes' },
    { Utility: 'Gas', MacAddress: '1000', MeterStatus: 'Inactive', CurrentReading:'4544', Currentdate:'25-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'No', ResidentialUnit:'Bhind',Offrequestsent:'Yes' },
    { Utility: 'Water', MacAddress: '2000', MeterStatus: 'Active', CurrentReading:'4533', Currentdate:'01-02-2024', Occupant:'Sample Occupant1', Onrequestsent:'Yes', ResidentialUnit:'Varanasi',Offrequestsent:'No' },
    { Utility: 'Eletricity', MacAddress: '1000', MeterStatus: 'Inactive', CurrentReading:'6754', Currentdate:'11-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'No', ResidentialUnit:'Shahjahanpur', Offrequestsent:'No'},
    { Utility: 'Gas', MacAddress: '3000', MeterStatus: 'Inactive', CurrentReading:'4534', Currentdate:'11-03-2024', Occupant:'Sample Occupant2', Onrequestsent:'No', ResidentialUnit:'Pathankot',Offrequestsent:'No' },
    { Utility: 'Eletricity', MacAddress: '1000', MeterStatus: 'Active', CurrentReading:'6755', Currentdate:'11-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'Yes', ResidentialUnit:'Bhind', Offrequestsent:'Yes'},
    { Utility: 'Water', MacAddress: '1000', MeterStatus: 'Active', CurrentReading:'6544', Currentdate:'11-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'No', ResidentialUnit:'Varanasi',Offrequestsent:'Yes' },
    { Utility: 'Eletricity', MacAddress: '4000', MeterStatus: 'Active', CurrentReading:'4534', Currentdate:'11-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'Yes', ResidentialUnit:'Pathankot', Offrequestsent:'No' },
    { Utility: 'Eletricity', MacAddress: '1000', MeterStatus: 'Inactive', CurrentReading:'5434', Currentdate:'11-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'No', ResidentialUnit:'Pathankot', Offrequestsent:'No' },
    
];