import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { GridModule, PDFModule,  ExcelModule, } from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SortableModule } from "@progress/kendo-angular-sortable";
import { UploadsModule } from '@progress/kendo-angular-upload';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';

import { MeterControlRoutingModule} from './meter-control-routing.module';
import { RpMeterControlComponent } from './rp-meter-control/rp-meter-control.component';

@NgModule({
  declarations: [ 
    RpMeterControlComponent,
  ],
  imports: [
    CommonModule,
    MeterControlRoutingModule,
    NgSelectModule,
    BsDatepickerModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    ModalModule,
    UiSwitchModule,
    SortableModule,
    UploadsModule,
    NgStepperModule,
    CdkStepperModule,
    AccordionModule,
    PDFExportModule,
    ExcelExportModule
  ],
 
})
export class MeterControlModule { }
