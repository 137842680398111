import { Component, ViewChild } from '@angular/core';


import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import { LoaderService } from '../../../core/services/loader.service';
import { getmeterDeatailsResponse, updateConsumerMeterDetailssuccessResponse } from '../../../store/customer/consumer-selector';
import { getmeterDeatails, resetupdateConsumerMeterDetails, updateConsumerMeterDetails } from '../../../store/customer/consumer.action';
import { DragOverEvent, NavigateEvent, SortableComponent } from '@progress/kendo-angular-sortable';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-rp-meter-details',
  templateUrl: './rp-meter-details.component.html',
  styleUrl: './rp-meter-details.component.scss'
})
export class RpMeterDetailsComponent {
  @ViewChild('sortable') public sortable!: SortableComponent;
  isAllOpen: boolean = true;
  meterDetails: any;
  water: string = '';
  gas: string = '';
  currentUser: any;
  residentialUnitId: any
  consumerId: any
  eletracity: string = '';
  sortBy: any[] = []
  breadCrumbItems: Array<{}> | undefined;

  private updatemerterdetailssubscription: Subscription = new Subscription();

  public inputFields = [
    { label: 'Flat/House#', name: 'Flat_House', value: '' },
    { label: 'Floor', name: 'Floor', value: '' },
    { label: 'Tower', name: 'Tower', value: '' },
    { label: 'Block', name: 'Block', value: '' },
  ];
  constructor(private router: Router,
    private loaderService: LoaderService, private store: Store,
    private sharedService: SharedService
  ) {
    this.breadCrumbItems = [{ label: 'Meter Details' }];
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.consumerId = this.currentUser?.currentUserRoleDetails?.userId;

    this.getBillsStore()
  }

  ngOnInit(): void {
    this.store.dispatch(getmeterDeatails());
  }

  public onDragOver(event: DragOverEvent): void {
    event.preventDefault();
    this.sortable.moveItem(event.oldIndex, event.index);
    this.isButtonEnabled = true
  }

  getBillsStore() {
    this.store.dispatch(resetupdateConsumerMeterDetails());

    let getmeterDeatailsResponse$ = this.store.pipe(select(getmeterDeatailsResponse));

    this.updatemerterdetailssubscription = this.store.pipe(select(updateConsumerMeterDetailssuccessResponse)).subscribe({
      next: (res: any) => {
        console.log('res', res);
        if (res) {

          this.inputFields.forEach((field) => {
            if (field.name == 'Flat_House') {
              field.value = res.data.flatNo;
            }
            if (field.name == 'Floor') {
              field.value = res.data.floor;
            }
            if (field.name == 'Tower') {
              field.value = res.data.tower;
            }
            if (field.name == 'Block') {
              field.value = res.data.block;
            }
          });
          console.log(this.inputFields)
          if (res && res.sortOrder) {

            const sortOrderString = res?.sortOrder.split(',');
            console.log('res.sortBy', sortOrderString);
            this.inputFields.sort((a, b) => {
              return (
                sortOrderString.indexOf(a.name) - sortOrderString.indexOf(b.name)
              );
            });
          }

          this.sharedService.showMessageDialog(
            'Success',
            res.message,
            SweetAlertIcon.SUCCESS
          );
        }
      }
    })


    getmeterDeatailsResponse$.subscribe((res: any) => {
      if (res) {
        this.meterDetails = res;
        this.residentialUnitId = res.id;
        this.water = this.meterDetails.water.length > 0 ? `Water ( ${this.meterDetails.water.length})` : ''
        this.gas = this.meterDetails.water.length > 0 ? `Gas ( ${this.meterDetails.gas.length})` : ''
        this.eletracity = this.meterDetails.water.length > 0 ? `Electricity ( ${this.meterDetails.electricity.length})` : ''
        console.log('res', res);
        this.inputFields.forEach((field) => {
          if (field.name == 'Flat_House') {
            field.value = res.flatNo;
          }
          if (field.name == 'Floor') {
            field.value = res.floor;
          }
          if (field.name == 'Tower') {
            field.value = res.tower;
          }
          if (field.name == 'Block') {
            field.value = res.block;
          }
        });

        if (res && res.sortOrder) {
          const sortOrderString = res?.sortOrder.split(',');
          console.log('res.sortBy', sortOrderString);

          this.inputFields.sort((a, b) => {
            // Sort by the order specified in sortOrderString
            return (
              sortOrderString.indexOf(a.name) - sortOrderString.indexOf(b.name)
            );
          });
        }
      }
    })
  }


  ngOnDestroy() {
    this.updatemerterdetailssubscription.unsubscribe();
  }


  public onNavigate(event: NavigateEvent): void {
    this.sortable.moveItem(event.oldIndex, event.index);
  }
  public isButtonEnabled: boolean = false;

  updateMeters() {
    this.sortBy = []
    this.inputFields.forEach((field: any) => {
      this.sortBy.push(field.name);
    })
    console.log(this.sortBy);
    let request = {
      consumerId: this.consumerId,
      sortOrder: this.sortBy.toString(),
      ResidentialUnitId: this.meterDetails.id

    }
    this.store.dispatch(updateConsumerMeterDetails({ updaedMeterData: request }));

  }



}
