import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConsumerWalletRoutingModule } from './consumer-wallet-routing.module';
import { RpWalletComponent } from './rp-wallet/rp-wallet.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GridModule, PDFModule, ExcelModule, } from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { ComponentsModule } from '../../shared/components/components.module';
import { RpWalletHistoryComponent } from './rp-wallet-history/rp-wallet-history.component';


@NgModule({
  declarations: [
    RpWalletComponent,
    RpWalletHistoryComponent

  ],
  imports: [
    CommonModule,
    ConsumerWalletRoutingModule,
    GridModule, FormsModule, ReactiveFormsModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    ModalModule,
    UiSwitchModule,
    NgStepperModule,
    CdkStepperModule, ComponentsModule
  ],

})
export class ConsumerWalletModule { }
