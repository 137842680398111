import { drawing } from '@progress/kendo-drawing';
import { deepExtend, addClass, setDefaultOptions } from '../common';
import { calculateSankey } from './calculation';
import { Node, resolveNodeOptions } from './node';
import { Link, resolveLinkOptions } from './link';
import { Label, resolveLabelOptions } from './label';
import { Title } from './title';
import { BOTTOM, LEFT, RIGHT, TOP } from '../common/constants';
import Box from '../core/box';
import rectToBox from '../core/utils/rect-to-box';
import { Observable } from '../common/observable';
import { Legend } from './legend';
const LINK = 'link';
const NODE = 'node';
export class Sankey extends Observable {
  constructor(element, options, theme) {
    super();
    this._initElement(element);
    this._initTheme(theme);
    this._setOptions(options);
    this._initSurface();
    if (options && options.data) {
      this._redraw();
    }
    this._initResizeObserver();
  }
  destroy() {
    this.unbind();
    this._destroySurface();
    this._destroyResizeObserver();
  }
  _initElement(element) {
    this.element = element;
    addClass(this.element, ["k-chart", "k-sankey"]);
  }
  _initSurface() {
    if (!this.surface) {
      this._destroySurface();
      this._initSurfaceElement();
      this.surface = this._createSurface();
    }
  }
  _initResizeObserver() {
    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        const {
          width,
          height
        } = entry.contentRect;
        if (entry.target !== this.element || this.size.width === width && this.size.height === height) {
          return;
        }
        this.size = {
          width,
          height
        };
        this.surface.setSize(this.size);
        this._redraw();
      });
    });
    this._resizeObserver = observer;
    observer.observe(this.element);
  }
  _createSurface() {
    return drawing.Surface.create(this.surfaceElement, {
      mouseenter: this._mouseenter.bind(this),
      mouseleave: this._mouseleave.bind(this)
    });
  }
  _initTheme(theme) {
    let currentTheme = theme || this.theme || {};
    this.theme = currentTheme;
    this.options = deepExtend({}, currentTheme, this.options);
  }
  setLinksOpacity(opacity) {
    this.linksVisuals.forEach(link => {
      this.setOpacity(link, opacity);
    });
  }
  setOpacity(link, opacity) {
    link.options.set('stroke', Object.assign({}, link.options.stroke, {
      opacity
    }));
  }
  trigger(name, ev) {
    ev.type = name;
    return super.trigger(name, ev);
  }
  _mouseenter(ev) {
    const element = ev.element;
    const isLink = element.type === LINK;
    const isNode = element.type === NODE;
    const isLegendItem = Boolean(element.chartElement && element.chartElement.options.node);
    if (isLink && this.trigger('linkEnter', ev) || isNode && this.trigger('nodeEnter', ev)) {
      return;
    }
    const {
      highlight
    } = this.options.links;
    if (isLink) {
      this.setLinksOpacity(highlight.inactiveOpacity);
      this.setOpacity(element, highlight.opacity);
    } else if (isNode) {
      this.highlightLinks(element, highlight);
    } else if (isLegendItem) {
      const nodeVisual = this.nodesVisuals.get(element.chartElement.options.node.id);
      this.highlightLinks(nodeVisual, highlight);
    }
  }
  _mouseleave(ev) {
    const element = ev.element;
    const isLink = element.type === LINK;
    const isNode = element.type === NODE;
    const isLegendItem = Boolean(element.chartElement && element.chartElement.options.node);
    const target = ev.originalEvent.relatedTarget;
    if (isLink && target && target.nodeName === 'text') {
      return;
    }
    if (isLink && this.trigger('linkLeave', ev) || isNode && this.trigger('nodeLeave', ev)) {
      return;
    }
    if (isLink || isNode || isLegendItem) {
      this.setLinksOpacity(this.options.links.opacity);
    }
  }
  highlightLinks(node, highlight) {
    if (node) {
      this.setLinksOpacity(highlight.inactiveOpacity);
      node.links.forEach(link => {
        this.setOpacity(link, highlight.opacity);
      });
    }
  }
  _destroySurface() {
    if (this.surface) {
      this.surface.destroy();
      this.surface = null;
      this._destroySurfaceElement();
    }
  }
  _destroyResizeObserver() {
    if (this._resizeObserver) {
      this._resizeObserver.disconnect();
      this._resizeObserver = null;
    }
  }
  _initSurfaceElement() {
    if (!this.surfaceElement) {
      this.surfaceElement = document.createElement('div');
      this.element.appendChild(this.surfaceElement);
    }
  }
  _destroySurfaceElement() {
    if (this.surfaceElement && this.surfaceElement.parentNode) {
      this.surfaceElement.parentNode.removeChild(this.surfaceElement);
      this.surfaceElement = null;
    }
  }
  setOptions(options, theme) {
    this._setOptions(options);
    this._initTheme(theme);
    this._initSurface();
    this._redraw();
  }
  _redraw() {
    this.surface.clear();
    const {
      width,
      height
    } = this._getSize();
    this.size = {
      width,
      height
    };
    this.surface.setSize(this.size);
    this.createVisual();
    this.surface.draw(this.visual);
  }
  _getSize() {
    return this.element.getBoundingClientRect();
  }
  createVisual() {
    this.visual = this._render();
  }
  titleBox(title, drawingRect) {
    if (!title || title.visible === false || !title.text) {
      return null;
    }
    const titleElement = new Title(Object.assign({}, {
      drawingRect
    }, title));
    const titleVisual = titleElement.exportVisual();
    return titleVisual.chartElement.box;
  }
  legendBox(options, nodes, drawingRect) {
    if (!options || options.visible === false) {
      return null;
    }
    const legend = new Legend(Object.assign({}, {
      nodes
    }, options, {
      drawingRect
    }));
    const legendVisual = legend.exportVisual();
    return legendVisual.chartElement.box;
  }
  calculateSankey(options) {
    const {
      title,
      legend,
      data
    } = this.options;
    const {
      nodes,
      labels,
      nodesColors
    } = this.options;
    const sankeyBox = new Box(0, 0, options.width, options.height);
    const titleBox = this.titleBox(title, sankeyBox);
    let legendArea = sankeyBox.clone();
    if (titleBox) {
      const titleHeight = titleBox.height();
      if (title.position === TOP) {
        sankeyBox.unpad({
          top: titleHeight
        });
        legendArea = new Box(0, titleHeight, options.width, options.height);
      } else {
        sankeyBox.shrink(0, titleHeight);
        legendArea = new Box(0, 0, options.width, options.height - titleHeight);
      }
    }
    const legendBox = this.legendBox(legend, data.nodes, legendArea);
    if (legendBox) {
      if (legend.position === LEFT) {
        sankeyBox.unpad({
          left: legendBox.width()
        });
      }
      if (legend.position === RIGHT) {
        sankeyBox.shrink(legendBox.width(), 0);
      }
      if (legend.position === TOP) {
        sankeyBox.unpad({
          top: legendBox.height()
        });
      }
      if (legend.position === BOTTOM) {
        sankeyBox.shrink(0, legendBox.height());
      }
    }
    const calculatedNodes = calculateSankey(Object.assign({}, options, {
      offsetX: sankeyBox.x1,
      offsetY: sankeyBox.y1,
      width: sankeyBox.x2,
      height: sankeyBox.y2
    })).nodes;
    const box = new Box();
    calculatedNodes.forEach((nodeEl, i) => {
      const nodeOps = resolveNodeOptions(nodeEl, nodes, nodesColors, i);
      const nodeInstance = new Node(nodeOps);
      box.wrap(rectToBox(nodeInstance.exportVisual().rawBBox()));
      const labelInstance = new Label(deepExtend({
        node: nodeEl,
        totalWidth: options.width
      }, labels));
      const labelVisual = labelInstance.exportVisual();
      if (labelVisual) {
        box.wrap(rectToBox(labelVisual.rawBBox()));
      }
    });
    let offsetX = (box.x1 < 0 ? -box.x1 : 0) + sankeyBox.x1;
    let offsetY = (box.y1 < 0 ? -box.y1 : 0) + sankeyBox.y1;
    let width = box.width() > sankeyBox.x2 ? offsetX + sankeyBox.x2 - (box.width() - sankeyBox.x2) : sankeyBox.x2;
    let height = box.height() > sankeyBox.y2 ? offsetY + sankeyBox.y2 - (box.height() - sankeyBox.y2) : sankeyBox.y2;
    return {
      sankey: calculateSankey(Object.assign({}, options, {
        offsetX,
        offsetY,
        width,
        height
      })),
      legendBox,
      titleBox
    };
  }
  _render() {
    const {
      data,
      labels: labelOptions,
      nodes: nodesOptions,
      links: linkOptions,
      nodesColors,
      title,
      legend
    } = this.options;
    const {
      width,
      height
    } = this.size;
    const calcOptions = Object.assign({}, data, {
      width,
      height,
      nodesOptions,
      title,
      legend
    });
    const {
      sankey,
      titleBox,
      legendBox
    } = this.calculateSankey(calcOptions);
    const {
      nodes,
      links
    } = sankey;
    const visual = new drawing.Group();
    if (titleBox) {
      const titleElement = new Title(Object.assign({}, title, {
        drawingRect: titleBox
      }));
      const titleVisual = titleElement.exportVisual();
      visual.append(titleVisual);
    }
    if (legendBox) {
      const legendElement = new Legend(Object.assign({}, legend, {
        drawingRect: legendBox,
        nodes,
        colors: nodesColors
      }));
      const legendVisual = legendElement.exportVisual();
      visual.append(legendVisual);
    }
    const visualNodes = new Map();
    nodes.forEach((node, i) => {
      const nodeOps = resolveNodeOptions(node, nodesOptions, nodesColors, i);
      const nodeInstance = new Node(nodeOps);
      const nodeVisual = nodeInstance.exportVisual();
      nodeVisual.links = [];
      nodeVisual.type = NODE;
      visualNodes.set(node.id, nodeVisual);
      visual.append(nodeVisual);
    });
    const sortedLinks = links.slice().sort((a, b) => b.value - a.value);
    const linksVisuals = [];
    sortedLinks.forEach(link => {
      const {
        source,
        target
      } = link;
      const sourceNode = visualNodes.get(source.id);
      const targetNode = visualNodes.get(target.id);
      const linkOps = resolveLinkOptions(link, linkOptions, sourceNode, targetNode);
      const linkInstance = new Link(linkOps);
      const linkVisual = linkInstance.exportVisual();
      linkVisual.type = LINK;
      linksVisuals.push(linkVisual);
      sourceNode.links.push(linkVisual);
      targetNode.links.push(linkVisual);
      visual.append(linkVisual);
    });
    this.linksVisuals = linksVisuals;
    this.nodesVisuals = visualNodes;
    nodes.forEach(node => {
      const textOps = resolveLabelOptions(node, labelOptions, width);
      const labelInstance = new Label(textOps);
      const labelVisual = labelInstance.exportVisual();
      if (labelVisual) {
        visual.append(labelVisual);
      }
    });
    return visual;
  }
  exportVisual() {
    return this._render();
  }
  _setOptions(options) {
    this.options = deepExtend({}, this.options, options);
  }
}
setDefaultOptions(Sankey, {
  labels: {
    visible: true,
    margin: {
      left: 8,
      right: 8
    },
    padding: 0,
    border: {
      width: 0
    },
    align: LEFT,
    opacity: 1,
    offset: {
      left: 0,
      top: 0
    }
  },
  nodes: {
    width: 24,
    padding: 16,
    opacity: 1,
    offset: {
      left: 0,
      top: 0
    }
  },
  links: {
    colorType: 'static',
    // 'source', 'target', 'static'
    opacity: 0.4,
    highlight: {
      opacity: 0.8,
      inactiveOpacity: 0.2
    }
  }
});